/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import BasicSnackBar from "../../../../../app/components/Alerts/BasicSnackBar";
import axios from "axios";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import TextField from "@material-ui/core/TextField";
import { BarcodePrint } from "../../../../../app/components/BarcodePrint";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function BarcodeDropdown() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [textboxAsin, setTextboxAsin] = useState(null);
  const [isError, setIsError] = useState(false);
  const barcodeRef = useRef(null);
  const [selectedASIN, setSelectedASIN] = useState(null);
  const bgImage =
    "https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/misc/bg-1.jpg";

  //function for closing the snackbar alert
  function closeAlert() {
    setMessage(null);
    setIsError(false);
  }

  function openBarcode(e) {
    e.preventDefault();
    setSelectedASIN(textboxAsin);
  }

  return (
    <>
      <BasicSnackBar
        open={message !== null}
        variant={isError ? "error" : "success"}
        message={message}
        handleClose={closeAlert}
      />

      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-my-cart-toggle"
        >
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip id="mycart-panel-tooltip">Barcode Generator</Tooltip>
            }
          >
            <div className="btn btn-icon btn-clean btn-lg mr-1">
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M13,5 L15,5 L15,20 L13,20 L13,5 Z M5,5 L5,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,6 C2,5.44771525 2.44771525,5 3,5 L5,5 Z M16,5 L18,5 L18,20 L16,20 L16,5 Z M20,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,19 C22,19.5522847 21.5522847,20 21,20 L20,20 L20,5 Z"
                      fill="#000000"
                    />
                    <polygon
                      fill="#000000"
                      opacity="0.3"
                      points="9 5 9 20 7 20 7 5"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
          <div
            className="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top"
            style={{ backgroundImage: `url(${bgImage})` }}
          >
            <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
              <i className="flaticon2-pen text-success"></i>
            </span>
            <h4 className="text-white m-0 flex-grow-1 mr-3">
              Code 128 Barcode
            </h4>
          </div>
          {loading ? (
            <div className="w-100 d-flex flex-column flex-center p-5 ">
              <h5>Generating...</h5>
              <div>
                <CircularProgress color="secondary" />
              </div>
            </div>
          ) : (
            <div>
              <div className="w-100 d-flex flex-column flex-center p-5 ">
                <form
                  noValidate
                  autoComplete="off"
                  onKeyPress={(e) => {
                    // e.key === "Enter" && e.preventDefault();
                    e.key === "Enter" && openBarcode(e);
                  }}
                >
                  <TextField
                    id="textboxAsin"
                    label="ASIN"
                    // className={classes.textField}
                    // value={textboxAsin}
                    // onChange={handleChange("name")}
                    onChange={(e) => setTextboxAsin(e.target.value)}
                    margin="normal"
                    variant="outlined"
                  />{" "}
                  <button
                    type="button"
                    className="btn btn-primary text-weight-bold"
                    disabled={loading || !textboxAsin}
                    onClick={openBarcode}
                  >
                    Generate
                  </button>
                </form>
              </div>
              <div>
                {selectedASIN !== null ? (
                  <BarcodePrint ref={barcodeRef} asin={selectedASIN} />
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
