import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import { FormControl, MenuItem, Select, CircularProgress, Tooltip, IconButton, makeStyles} from '@material-ui/core';
import { AddTableData, DeleteTableData, UpdateTable, doesItemExist} from '../../components/Tables/ProductTable/TableData';
import BasicSnackBar from '../../components/Alerts/BasicSnackBar';
import BasicModal from '../../components/Modals/BasicModal';
import { PDFExport } from '@progress/kendo-react-pdf';
import InputTable from '../../components/Tables/InputTable';
import {useLocation} from 'react-router-dom'
import DisplayTable from '../../components/Tables/DisplayTable'
import InvoiceModal from '../../components/Modals/InvoiceModal'
import ProductTable from '../../components/Tables/ProductTable/ProductTable';


export default function PurchaseOrders() {
    const invoice = useRef(null)
    const base_url = process.env.REACT_APP_API_URL
    const url = base_url + 'inventory-loc-inbound/';
    const location = useLocation()
    const excludedSkus = ['Freight', 'Discount', 'Packing', 'Misc.', 'Misc']

    //state variables
    const [tableData, setTableData] = useState(null)
    const [purchaseOrders, setPurchaseOrders] = useState(null)

    const [orderDetails, setOrderDetails] = useState(null)
    const [vendorDetails, setVendorDetails] = useState(null)
    const [inboundData,  setInboundData] = useState(null)
    const [poKeywords, setPOKeywords] = useState([])
    const [vendorKeywords, setVendorKeywords] = useState([])
    const [statusKeywords, setStatusKeywords] = useState([])
    const [selectedKeys, setSelectedKeys] = useState([])

    const [message, setMessage] = useState(null)
    const [isError, setIsError] = useState(false)
    const [showBills, setShowBills] = useState(false)
    const [showReceive, setShowRecieve] = useState(false)
    const [loading, setLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [vendorSkus, setVendorSkus] = useState([])
    const [paymentTerms, setPaymentTerms] = useState(null)
    const [showInputTable, setShowInputTable] = useState(false)

    //state variables for the form
    const [submitted, setSubmitted] = useState(false)
    const [topValues, setTopValues] = useState({
        order_date: null,
        po_number: null,
        vendor_name: null,
    })

    //values for the 3 dropdowns in the first table
    const [selectedPO, setSelectedPO] = useState(null)
    const [selectedVendor, setSelectedVendor] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState(null)


    //********************EFFECTS*************** */
    //effect for getting and setting the purchaseOrders being displayed on the left column
    //also gets the paymentTerms and vendorDetails keywords for the selects
    useEffect(() => {

        //get all inbound table data
        axios.get(url).then(({ data }) => {
            setInboundData(data)
        }).catch(function (error) {
            setInboundData([])
            console.log(error)
        })
        
        //get list of all POs for the search bar
        axios.get(url + '?type=list' ).then(({ data }) => {
            setPurchaseOrders(data)
            setTableData(data)
        }).catch(function (error) {
            setPurchaseOrders([])
            console.log(error)
        })

        //set the keywords for the vendor selects
        axios.get(url + '?type=vendors').then(({data}) => {
            let vendors = []
            data.map(vendor => {
                vendors.push({label: vendor, value: vendor})
            })
            setVendorKeywords(vendors)
        }).catch(function(error){
            console.log(error)
            setVendorKeywords([])
        })

        //get the list of payment terms
        axios.get(base_url + 'financials-payment-terms/').then(({data}) => {
            setPaymentTerms(data)
        }).catch(function(error){
            setPaymentTerms([])
            console.log(error)
        })

    }, []);

    //effect to check the location state which is set when coming from a different page
    //waits for purchase order to be set first
    useEffect(() => {
        if(purchaseOrders){
            if(location.state){
                searchPO(location.state)
            }
        }
    }, [purchaseOrders]);

    //effect for updating the po_number keywords in the table dropwdown
    //gets called everytime tableData changes
    useEffect(() => {
        if(tableData){
            let POs = [], vendors = [], statuses = [], dupVendors=[], dupStatuses=[]
            tableData.map(item => {
                POs.push({value: item.po_number, label: item.po_number})

                //check for duplicates, dont add them
                if(!dupVendors.includes(item.vendor_name)){
                    vendors.push({value: item.vendor_name, label: item.vendor_name})
                    dupVendors.push(item.vendor_name)
                }

                if(!dupStatuses.includes(item.status)){
                    statuses.push({value: item.status, label: item.status})
                    dupStatuses.push(item.status)
                }
            })
            //sort the values
            POs.sort(function(a,b){
                if(a.value > b.value){
                    return 1
                }else{
                    return -1
                }
            })
            vendors.sort(function(a,b){
                if(a.value > b.value){
                    return 1
                }else{
                    return -1
                }
            })

            if(!selectedKeys.includes('vendor_name')){
                setVendorKeywords(vendors)
            }
            if(!selectedKeys.includes('po_number')){
                setPOKeywords(POs)
            }
            if(!selectedKeys.includes('status')){
                setStatusKeywords(statuses)
            }
            
            
        }
    }, [selectedKeys]);

    //********************METHODS*************** */
    //function for handling the table refresh button
    //just resets the tableData to purchase orders
    function handleTableRefresh(){
        setTableData(purchaseOrders)
        resetData()
    }

    //function for handling the vendor name dropdown in the top bar
    //takes the vendor name as the input then calls an endpoint to get/set the list of POs for that vendor
    function getVendorPOs(keyword){
        setSelectedVendor(keyword)
        setTopValues({...topValues, ['vendor_name']: keyword.value})
        axios.get(base_url + 'inventory-loc-inbound/?vendor_pos=' + keyword.value)
        .then(({data}) => {
            //loop through the data and set the poKeyword object
            let newPOs = []
            data.map(po => {
                newPOs.push({label: po, value: po})
            })
            setPOKeywords(newPOs)
            
        }).catch(function(error){
            console.log(error)
            setPOKeywords([])
        })
    }

    //function for handling the click of a PO number from the side nav bar
    //passes the Po Number in a get call and sets the orderDetails as a respons
    function handlePOSelect(poNum) {
        setSelectedPO(poNum)
        setLoading(true)
        if(selectedVendor){
            getVendorDetails(selectedVendor.value)
            //get the skus for the vendor
            getVendorSkus(selectedVendor.value)
        }
        
        axios.get(url + '?po=' + poNum.value).then(({ data }) => {
            setOrderDetails(data)
            //set the values for the top row
            let topRow = data[0], newValues = {}
            newValues['order_date'] = topRow['order_date']
            newValues['po_number'] = topRow['po_number']
            newValues['vendor_name'] = topRow['vendor_name']
            setTopValues(newValues)
            setLoading(false)
        }).catch(function (error) {
            setSelectedPO(null)
            setOrderDetails(null)
            console.log(error)
            setLoading(false)
        })
    }

    //function for adding a new PO 
    function addPO() {
        let newEntry = {
            "order_date": "",
            "po_number": "",
            "vendor_name": '',
            "payment_terms": '',
            "ship_method": "",
            "base_sku": null,
            "unit_price": 0,
            "total_price": 0,
            "qty_ordered": 0,
            "qty_received": 0,
            "qty_cancelled": 0,
            "received_date": null,
            "intended_channel": "",
        }

        let topRow = {
            "order_date": "",
            "po_number": "",
            "vendor_name": "",
        }
        //add blank top row
        setTopValues(topRow)

        //set message
        setMessage('Empty purchase order created.')

        //save as order details 
        setOrderDetails([newEntry])

        //show the input table btn for new POs
        setShowInputTable(true)
        //get rid of errors
        setSubmitted(false)
        //reset the vendor details
        setVendorDetails(null)
    }
    
    //function for handling changes of the form inputs on the top row
    //changes will be applied to each row in the bottom
    const handleTopChange = name => event => {
        setTopValues({ ...topValues, [name]: event.target.value })
        setButtonDisabled(false) // enable the save changes button
    };


    //function for handling changes of the vendor when adding a new PO
    //stores the vendor selected
    //changes will be applied to each row in the bottom
    const handleVendorChange = event => {
        setTopValues({ ...topValues, ['vendor_name']: event.target.value })
        setButtonDisabled(false) // enable the save changes button

        //store the selected vendor name
        getVendorPOs({label: event.target.value, value: event.target.value})

        //get the vendor details for the vendor
        getVendorDetails(event.target.value)

        //get the skus for the vendor
        getVendorSkus(event.target.value)
    };

    //refreshes the main table
    function refresh(){
        if(selectedPO){
            handlePOSelect(selectedPO)
        }else{
            handlePOSelect({value: topValues.po_number, label: topValues.po_number})
        }
        
        setSubmitted(false)
    }

    //callback function for the inputTable
    //sets submitted to true so that the form renders the errors if any
    function handleSubmit(result, newErrors){
        //check if there were any errors and set message accordingly
        if(!result){
            setIsError(true)
            setMessage('Error! Cannot update items')
        }
        else{
        
            setMessage(result + " items updated successfully with " + newErrors.failed.length  + " errors!")
            closeModals()
            
        }
        setSubmitted(true)

    }

    //callback function if there are errors while submitting,
    //specifically not filling out the required fields
    function handleErrors(){
        setSubmitted(true)//this will display all errors on empty required fields
    }

    //takes the input value and loops through the purchase orders to find match
    //sets the vendor name and po details
    function searchPO(po){
        if(purchaseOrders){
            let found = false
            purchaseOrders.map(order => {
                //check each po for a match (ignore case)
                if(order.po_number.toLowerCase().indexOf(po.toLowerCase()) > -1){
                    found = true
                    setSelectedVendor({value: order.vendor_name , label: order.vendor_name})
                    getVendorDetails(order.vendor_name)
                    getVendorSkus(order.vendor_name)
                    handlePOSelect({value: order.po_number, label: order.po_number})
            
                }
            })

            //show the input table if found
            if(found){
                setShowInputTable(true)
            }else{
                setIsError(true)
                setMessage("PO number does not exist.")
            }
            
        }
    }

    //calls the vendor details enpoint with a vendor as a parameter
    //sets it to vendordetails in state
    function getVendorDetails(vendor){
        axios.get(base_url + 'catalog-vendor-details/?vendor=' + vendor).then(({data}) => {
            setVendorDetails(data[0])
        }).catch(function(error){
            setVendorDetails({})
            console.log(error)
        })
    }

    //function for setting the vendorSkus  everytime the selected vendor changes
    function getVendorSkus(vendor){
        axios.get(base_url + 'catalog-base-sku/?brand=' + vendor).then(({data}) => {
            data.sort(function(a, b) {
                if(a < b){
                    return -1
                }
                return 1
              });
            setVendorSkus(data)
        }).catch(function(error){
            setVendorSkus([])
            console.log(error)
        })
    }

    //function for closing the snackbar alert
    function closeAlert() {
        setMessage(null)
        setIsError(false)
    }

    //function for showing the frieght rate modal
    function openReceiveModal() {
        setShowRecieve(true)
    }

    //function for showing the Vendor Bills modal
    function openBillsModal() {
        setShowBills(true)
    }

    //function for closing all modals
    function closeModals() {
        setShowRecieve(false)
        setShowBills(false)
        
        if(!showInputTable){
            resetData()
        }
    }

    //function for converting document to pdf
    function printDocument() {
        invoice.current.save()
    }

    //function called when the "bulk Upload" button is clicked
    //loops throught the csv data and then passes the product asins to get the product key
    async function handleImport(csvData, mode){
        const headers = csvData[0].meta.fields;
        const columns = [], newArray = [];

        //get the loop throught the header row and get all columns present
         for(let k = 0; k < headers.length; k++)
         {
             if(headers[k] && headers[k] !== ''){
                columns.push(headers[k])
             }
         }

         //loop through the csvData and put each row object in the same array
         csvData.map(row => {
            newArray.push(row.data)
        })

        //make the errors object
        let newErrors = {empty: 0, failed: []}

        //call the table function and await the results
        let result = await AddTableData(url, 0, newErrors, newArray.slice(0,20), newArray, false)

        //set success/error message base off of the result returned from the item
        if(result > 0){
            setMessage(result + " Items added successfully with " + newErrors.failed.length + " errors!")

            if(selectedPO){
                handlePOSelect(selectedPO)
            }
        }
        else{
            setIsError(true)
            setMessage('Error! Unable to add items.')
        }
    }

    //function for handling csv file upload error
    // just sets the error message accordingly
    function handleOnError(){
        setMessage('Error! Unable to load file.')
        setIsError(true)
    }

    //helper function for handleKey
    function filterTable(data, keyword , id){
        //loop through all of the purchase order data and get all of the matches
        let newData =[]
        data.map(po => {
            //if the keyword matches then add it to the array
            if(po[id] === keyword.value){
                newData.push(po)
            }
        })

        return newData
    }

    //function for handling the vendor, status, and po keyword dropdowns
    function handleKey(keyword, id){

        let newData = []
        //set the data to loop by, if theres already a keyword then go with the table data
        if(selectedKeys.length === 0 || selectedKeys[0] === id){
            newData = filterTable(purchaseOrders, keyword, id)
        }
        else if( !selectedKeys.includes(id) || selectedKeys[2] === id){
            newData = filterTable(tableData, keyword, id)
        }else{
            let firstKey = selectedVendor
            //set the first selected keyword
            if(selectedKeys[0] === 'po_number'){
                firstKey = selectedPO
            }else if(selectedKeys[0] === 'status'){
                firstKey = selectedStatus
            }

            //filter from the first keyword first 
            newData = filterTable(purchaseOrders, firstKey, selectedKeys[0])
            //then filter by the newly selected
            newData = filterTable(newData, keyword, id)
        }  
        
        

        //set the table data
        setTableData(newData)

        //add to the selected keywords
        if(!selectedKeys.includes(id)){
            setSelectedKeys([...selectedKeys, id])
        }else{
            let newKeys = [...selectedKeys]
            newKeys.length = newKeys.indexOf(id) + 1
            setSelectedKeys(newKeys)
        }

        //set the keyword value for the dropdown
        if(id === 'vendor_name'){
            setSelectedVendor(keyword)
        }else if(id === 'status'){
            setSelectedStatus(keyword)
        }else{
            setSelectedPO(keyword)
        }

        
    }

    //function to handle the button clicks in the initial table
    function handleTableButton(row, name){
        //set the relevant information from the row
        setSelectedVendor({value: row.vendor_name , label: row.vendor_name})
        getVendorDetails(row.vendor_name)
        getVendorSkus(row.vendor_name)
        handlePOSelect({value: row.po_number, label: row.po_number})
        //depending on the name of the button clicked, show different things
        if(name === 'Receive'){
            setShowRecieve(true)
        }else if(name === 'Invoice'){
            setShowBills(true)
        }else{       
            setShowInputTable(true)
        }
        
    }

    //handles the back button for the input table
    //resets all the data and sets the inputtable to false
    function handleBackButton(){
        setShowInputTable(false)
        resetData()
    }

    //function for resetting all of the data in the state variables
    function resetData(){
        setSelectedPO(null)
        setSelectedVendor(null)
        setSelectedStatus(null)
        setSelectedKeys([])
        setVendorSkus([])
        setOrderDetails(null)
        setVendorDetails(null)
        setTopValues({
            order_date: null,
            po_number: null,
            vendor_name: null,
        })
    }

    //calculate qty outstanding for each row
    function calcOutstanding(row){
        if(row['qty_received'] !== null){
            return(row['qty_ordered'] - (row['qty_received'] + row['qty_cancelled'] ))
        }else
        {
            return 0
        }
    }

  
    return (
        <div>
            {/* Receive modal that opens on main PO page as well as individual PO  */}
            <BasicModal
                title="Receive"
                size='xl'
                body={
                    <div style={{height: 'calc(75vh - 165px)'}}>
                        {/* Just the normal table with input as cells */}
                        <InputTable
                            data={orderDetails}
                            url={url}
                            noAdd
                            noDelete
                            disabled={true}
                            handleSubmit={handleSubmit}
                            refresh={refresh}
                            columns={[
                                {
                                    id:'base_sku',
                                    type: 'select',
                                    header: 'SKU',
                                    options: vendorSkus
                                },
                                {
                                    id:'qty_ordered',
                                    type: 'numeric',
                                    header: 'Qty. Ordered',
                                    decimals: 0
                                },
                                {
                                    id:'qty_received',
                                    type: 'numeric',
                                    header: 'Qty. Received',
                                    decimals: 0
                                },
                                {
                                    id:'qty_cancelled',
                                    type: 'numeric',
                                    header: 'Qty. Cancelled',
                                    decimals: 0
                                },
                                {
                                    id:'received_date',
                                    type: 'date',
                                    header: 'Date Received',
                                },
                                {
                                    id:'qty_outstanding',
                                    type: 'numeric',
                                    header: 'Qty. Outstanding',
                                    decimals: 0,
                                    calculation: calcOutstanding

                                },
                            ]}
                        />
                    </div>
                }
                show={showReceive}
                handleCancel={closeModals}
                noConfirm
            />
            <InvoiceModal
                show={showBills}
                po_number={topValues.po_number}
                order_date={topValues.order_date}
                vendor_name={topValues.vendor_name}
                orderDetails={orderDetails}
                vendorDetails={vendorDetails}
                handleCancel={closeModals}
                handleSubmit={handleSubmit}
                handleErrors={handleErrors}
                vendorSkus={vendorSkus}
            />
            
            <BasicSnackBar
                open={message !== null}
                variant={isError ? 'error' : 'success'}
                message={message}
                handleClose={closeAlert}
            />
            {/* This is where the table on Purchase Order page starts */}
            <Grid container spacing={2} className>
                {/*COLUMN TWO start */}
                <Grid item xs={12}>
                    <div className="card card-custom card-stretch gutter-b" >
                        {/* showInputTable is where it switches between PO list table and individual PO form */}
                        {
                        !showInputTable?
                            <ProductTable
                                title="Purchase Orders"
                                data={tableData}
                                rows={25}
                                handleForm={addPO}
                                handleImport={handleImport}
                                handleRefresh={handleTableRefresh}
                                handleKey={handleKey}
                                bulkAdd
                                handleOnError={handleOnError}
                                requiredHeaders={
                                    [
                                        'order_date',
                                        'po_number',
                                        'vendor_name',
                                        'base_sku',
                                        'qty_ordered',
                                        'payment_terms'
                                    ]
                                }
                                keywords={(vendorKeywords && poKeywords) && [{placeholder: 'Select Vendor', data: vendorKeywords, id: 'vendor_name', value:selectedVendor}, 
                                                                            {placeholder: 'Select PO', data: poKeywords, id: 'po_number', value:selectedPO},
                                                                            {placeholder: 'Select Status', data: statusKeywords, id: 'status', value:selectedStatus}]}
                                columns={[
                                    {
                                        id:'order_date',
                                        type: 'date',
                                        format: 'MM/DD/YYYY',
                                        header: 'PO Date',
                                        onClick: handleTableButton
                                    },
                                    {
                                        id:'po_number',
                                        type: 'text',
                                        header: 'PO Number',
                                        onClick: handleTableButton
                                    },
                                    {
                                        id:'vendor_name',
                                        type: 'text',
                                        header: 'Vendor Name',
                                        onClick: handleTableButton
                                    },
                                    {
                                        id:'status',
                                        type: 'text',
                                        header: 'Status',
                                        onClick: handleTableButton
                                    },
                                    {
                                        id:'total_units',
                                        type: 'numeric',
                                        header: 'Total Units',
                                        decimals: 0,
                                        onClick: handleTableButton
                                    },
                                    {
                                        id:'total_amount',
                                        type: 'numeric',
                                        header: 'PO Amount',
                                        decimals: 2,
                                        onClick: handleTableButton
                                    },
                                    {
                                        type: 'button',
                                        header: 'Receive',
                                        function: handleTableButton,
                                        className: "btn btn-icon btn-light btn-hover-info btn-sm",
                                        label: <span class="svg-icon svg-icon-info svg-icon-2x">
                                                    <svg  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"/>
                                                            <path d="M22,17 L22,21 C22,22.1045695 21.1045695,23 20,23 L4,23 C2.8954305,23 2,22.1045695 2,21 L2,17 L6.27924078,17 L6.82339262,18.6324555 C7.09562072,19.4491398 7.8598984,20 8.72075922,20 L15.381966,20 C16.1395101,20 16.8320364,19.5719952 17.1708204,18.8944272 L18.118034,17 L22,17 Z" fill="#000000"/>
                                                            <path d="M2.5625,15 L5.92654389,9.01947752 C6.2807805,8.38972356 6.94714834,8 7.66969497,8 L16.330305,8 C17.0528517,8 17.7192195,8.38972356 18.0734561,9.01947752 L21.4375,15 L18.118034,15 C17.3604899,15 16.6679636,15.4280048 16.3291796,16.1055728 L15.381966,18 L8.72075922,18 L8.17660738,16.3675445 C7.90437928,15.5508602 7.1401016,15 6.27924078,15 L2.5625,15 Z" fill="#000000" opacity="0.3"/>
                                                            <path d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.959697, 3.661508) rotate(-270.000000) translate(-11.959697, -3.661508) "/>
                                                        </g>
                                                    </svg>
                                                </span>

                                    },
                                    {
                                        type: 'button',
                                        header: 'Invoice',
                                        function: handleTableButton,
                                        className: "btn btn-icon btn-light btn-hover-success btn-sm ",
                                        label: <span class="svg-icon svg-icon-success svg-icon-2x">
                                                    <svg  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24"/>
                                                            <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                                            <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1"/>
                                                            <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1"/>
                                                        </g>
                                                    </svg>
                                                </span>

                                    },
     
                                ]}

                            
                            />
                           
                        :
                        // This is where individual PO form starts
                        loading ?
                            <div className="w-100 d-flex flex-column flex-center " style={{ height: "40vh", position: "relative" }}>
                                <h5>Loading Data...</h5>
                                <div><CircularProgress color="secondary" /></div>
                            </div>
                            :

                            <div class="card-body rounded p-0"  style={{ backgroundColor: '#ffffff' }} >
                                <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                    <div class="col-md-10">
                                        <div className='w-100 d-flex justify-content-between'>
                                            <h1 class="display-4 font-weight-boldest mb-10">Purchase Order</h1>
                                            <div className='d-flex flex-center' >
                                                <button type="button" class="btn btn-danger font-weight-bold mr-2" onClick={handleBackButton}>Back</button>
                                                <button type="button" class="btn btn-primary font-weight-bold" onClick={printDocument}>PDF</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center py-4 px-8 px-md-0">
                                    <div class="col-md-10">
                                        <div class="d-flex justify-content-between pb-10 flex-column flex-md-row">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={8}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6} md={3}>
                                                            <TextField
                                                                type={'date'}
                                                                value={topValues['order_date']}
                                                                onChange={handleTopChange('order_date')}
                                                                label={<div className="font-weight-bolder font-size-lg">Order Date*</div>}
                                                                InputLabelProps={{shrink:true}}
                                                                error={submitted && !topValues['order_date']}
                                                                className='w-100'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={3}>
                                                            <TextField
                                                                value={topValues['po_number']}
                                                                onChange={handleTopChange('po_number')}
                                                                error={submitted && !topValues['po_number']}
                                                                label={<div className="font-weight-bolder font-size-lg">Order Number*</div>}
                                                                className='w-100'
                                                            />
                                                        </Grid>
                                                        {vendorDetails && 
                                                            <Grid item xs={12}>
                                                                <div className='d-flex flex-row justify-content-between w-100'>
                                                                    <div class="d-flex flex-column flex-root w-100">          
                                                                        <span class="font-weight-bolder mb-2">Ordering From:</span>
                                                                        {vendorDetails.order_from}
                                                                    </div>
                                                                    <div class="d-flex flex-column flex-root w-100">
                                                                        <span class="font-weight-bolder mb-2">Ordering Method:</span>
                                                                        {vendorDetails.ordering_method}
                                                                    </div>
                                                                    <div class="d-flex flex-column flex-root">
                                                                        <span class="font-weight-bolder mb-2">Payment Terms:</span>
                                                                        {vendorDetails.payment_terms}
                                                                    </div>
                                                                    <div class="d-flex flex-column flex-root">
                                                                        <span class="font-weight-bolder mb-2">Prepaid Freight:</span>
                                                                        {vendorDetails.min_prepaid_freight}
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div class="d-flex flex-column align-items-md-end px-0">
                                                        <div className="h3 font-weight-bolder d-flex flex-column align-items-md-end">
                                                            {orderDetails[0].vendor_name ?
                                                                orderDetails[0].vendor_name
                                                                :
                                                                <>
                                                                    <FormControl>
                                                                        <Select
                                                                            name='select'
                                                                            value={topValues['vendor_name']}
                                                                            onChange={handleVendorChange}
                                                                            error={submitted && !topValues['vendor_name']}
                                                                            className="w-100"
                                                                            displayEmpty
                                                                        >
                                                                            <MenuItem key={-1} value={''} disabled >
                                                                                Vendor Name
                                                                            </MenuItem>
                                                                            {vendorKeywords.map((key, index) => {
                                                                                return (
                                                                                    <MenuItem key={index} value={key.value}>
                                                                                        {key.label}
                                                                                    </MenuItem>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                    </FormControl>
                                                                </>
                                                            }
                                                        </div>
                                                        <span class="d-flex flex-row align-items-md-end font-weight-bold font-size-lg">
                                                            Status:<span className='text-primary font-size-small ml-2'>{orderDetails[0].status} </span>
                                                        </span>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            
                                            
                                        </div>
                                        <div class="border-bottom w-100"></div>


                                    </div>
                                </div>
                                <div className="row justify-content-center pb-16 px-8 px-md-0">  
                                    <div className='col-md-10' style={{height: '40vh'}}>
                                        {/* This is the actual editable table in the individual PO form                     */}
                                        <InputTable
                                            data={orderDetails}
                                            url = {url}
                                            refresh={refresh}
                                            handleSubmit={handleSubmit}
                                            handleErrors={handleErrors}
                                            appendedValues={topValues}
                                            disabled={buttonDisabled}
                                            buttons={
                                                <>
                                                <button type="button" class="btn btn-light-info font-weight-bold mr-3" onClick={openReceiveModal}>Receive</button>
                                                <button type="button" class="btn btn-light-primary font-weight-bold mr-3" onClick={openBillsModal}>Invoice</button>
                                                </>
                                            }
                                            columns={[
                                                {
                                                    id:'base_sku',
                                                    type: 'select',
                                                    header: 'Base SKU',
                                                    required: true,
                                                    style: {width: 300, minHeight: 41},
                                                    options: vendorSkus
                                                },
                                                {
                                                    id:'qty_ordered',
                                                    type: 'numeric',
                                                    header: 'Qty. Ordered',
                                                    decimals: 2,
                                                    required: true,
                                               
                                                },
                                                {
                                                    id:'unit_price',
                                                    type: 'numeric',
                                                    header: 'Unit Price',
                                                    decimals: 2,
                                                    
                                                },
                                                {
                                                    id:'total_price',
                                                    type: 'display-num',
                                                    header: 'Total Price',
                                                    decimals: 2,
                                                    disabled: true
                                                },
                                                {
                                                    id:'intended_channel',
                                                    type: 'text',
                                                    header: 'Channel',
                                                 
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>              
                            </div>
                        }
                    </div>
                </Grid>
                {/*COLUMN TWO end */}
            </Grid>
        {/* This is where PDF Export stuff starts */}
        {topValues && orderDetails && vendorDetails && selectedPO &&
           <div style={{
                position: "absolute",
                left: "-1000px",
                top: 0,
                
            }}>
                
                <PDFExport 
                    paperSize="A4" margin="1cm" ref={invoice} scale={.75}
                    fileName={selectedVendor.value + " - " + selectedPO.value}
                    repeatHeaders={true}
                >
                    <div class="card-body rounded p-0"  style={{ backgroundColor: '#ffffff' }} >
                                <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                    <div class="col-md-10">
                                        <div className='w-100 d-flex justify-content-between'>
                                            <h1 class="display-4 font-weight-boldest mb-10">Purchase Order</h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center py-4 px-8 px-md-0">
                                    <div class="col-md-10">
                                        <div class="d-flex justify-content-between pb-10 flex-column flex-md-row">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={8}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6} md={3}>
                                                            <div class="d-flex flex-column flex-root w-100">  
                                                                <span class="font-weight-bolder mb-2">Order Date:</span>
                                                                <span className='text-primary'>{topValues['order_date']}</span>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={6} md={3}>
                                                            <div class="d-flex flex-column flex-root w-100">  
                                                                <span class="font-weight-bolder mb-2">PO Number:</span>
                                                                <span className='text-primary'>{topValues['po_number']}</span>
                                                            </div>
                                                        </Grid>
                                                        {vendorDetails && 
                                                            <Grid item xs={12}>
                                                                <div className='d-flex flex-row justify-content-between w-100'>
                                                                    <div class="d-flex flex-column flex-root w-100">          
                                                                        <span class="font-weight-bolder mb-2">Ordering From:</span>
                                                                        <span className='text-primary'>{vendorDetails.order_from}</span>
                                                                    </div>
                                                                    <div class="d-flex flex-column flex-root w-100">
                                                                        <span class="font-weight-bolder mb-2">Ordering Method:</span>
                                                                        <span className='text-primary'>{vendorDetails.ordering_method}</span>
                                                                    </div>
                                                                    <div class="d-flex flex-column flex-root">
                                                                        <span class="font-weight-bolder mb-2">Payment Terms:</span>
                                                                        <span className='text-primary'>{vendorDetails.payment_terms}</span>
                                                                    </div>
                                                                    <div class="d-flex flex-column flex-root">
                                                                        <span class="font-weight-bolder mb-2">Prepaid Freight:</span>
                                                                        <span className='text-primary'>{vendorDetails.min_prepaid_freight}</span>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div class="d-flex flex-column align-items-md-end px-0">
                                                        <div className="h3 font-weight-bolder d-flex flex-column align-items-md-end">
                                                            {orderDetails[0].vendor_name &&
                                                                orderDetails[0].vendor_name
                                                            }
                                                        </div>
                                                        <span class="d-flex flex-row align-items-md-end font-weight-bold font-size-lg">
                                                            Status:<span className='text-primary font-size-small ml-2'>{orderDetails[0].status} </span>
                                                        </span>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div class="border-bottom w-100"></div>
                                    </div>
                                </div>
                                <div className="row justify-content-center pb-16 px-8 px-md-0">  
                                    <div className='col-md-10'>
                                        <DisplayTable
                                            data={orderDetails}
                                            columns={
                                                    [{
                                                        id:'base_sku',
                                                        type: 'text',
                                                        header: 'Base SKU',
                                                        style: {width: 300}
                                                    },
                                                    {
                                                        id:'qty_ordered',
                                                        type: 'numeric',
                                                        header: 'Qty. Ordered',
                                                    },
                                                    {
                                                        id:'unit_price',
                                                        type: 'numeric',
                                                        header: 'Unit Price',
                                                    },
                                                    {
                                                        id:'total_price',
                                                        type: 'numeric',
                                                        header: 'Total Price',
                                                    },
                                                    {
                                                        id:'intended_channel',
                                                        type: 'text',
                                                        header: 'Channel',
                                                    },
                                                ]
                                            }
                                        />  
                                    </div>
                                </div>              
                            </div>
                </PDFExport>

           </div>}
        </div>
    )
}