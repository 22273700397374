import React, { useState, useEffect } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import clsx from 'clsx';
import { fade, lighten, makeStyles } from '@material-ui/core/styles';
import DownloadCSVBtn from '../../Buttons/DownloadCSVBtn';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import { TextField, Badge, Select, FormControl, MenuItem} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import KeywordSelect from '../../Buttons/KeywordSelect';
import UplaodCSVBtn from '../../Buttons/UploadCSVBtn';
import BasicSnackBar from '../../Alerts/BasicSnackBar';
import BulkEdits from './BulkEdits/BulkEdits';
import BasicDropdown from '../../Dropdowns/BasicDropdown';
import { Grid, Chip} from '@material-ui/core';
import ColumnSelector from './ColumnSelector';

const useToolbarStyles = makeStyles(theme => ({
    textField: {
      margin: theme.spacing(1),
 
      width: 136,
      
    },
    buttonActive: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    picker: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      width: 140,
      marginTop: 3.5,
      fontSize: '14px !important'
    },
    wrap: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      maxWidth: 475
    },
    chip: {
      margin: theme.spacing(1),
    },
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    spacer: {
      flex: '1 1 auto',
    },
    actions: {
      color: theme.palette.text.secondary,
      display: "flex",
      flexDirection: 'row',
      alignItems: "center",
      justifyContent: 'center',
     
    },
    title: {
      flex: '0 0 auto',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.black, 0.05),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.black, 0.15),
      },
      marginLeft: 0,
      width: '100%',
      maxHeight: 32,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'black',
    },
    formControl: {
      margin: theme.spacing(1),
      
      minWidth: 120,
      height: '100%'
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 80,
        '&:focus': {
          width: 200,
        },
      },
    },
  }));
  
  export default function ProductTableToolbar(props){
    const classes = useToolbarStyles();
    const [message, setMessage] = useState(null);
    const {numSelected} = props;
    const {bulkEditValues} = props
    const [value, setValue] = useState(null);
    const [dropdownItems, setItems] = useState([
      {
        label: 'Filter',
        onClick: props.toggleFilter
      },
      {
        label: 'Refresh',
        onClick: props.toggleFilter
      },

    ])

    //*************EFFECTS************** */
    useEffect(() => {
      let newItems = dropdownItems
      if(props.delete){
        newItems.push(
          {
            label: 'Delete Item(s)',
            onClick: doDelete
          },
        )
      }

      if(props.handleForm){
        newItems.push(
          {
            label: 'Add Item',
            onClick: props.handleForm
          },
        )
      }

      setItems(newItems)
    }, []);

    useEffect(() => {

      
    }, [props.keywords, props.filters]);


    //************METHODS*************** */


    function handlePicker(event){
      props.handlePicker(event.target.value)
    }

    function doDelete() {
      props.handleDelete();
    }

    function handleDropDown(value,id){
      props.handleDropDown(value, id);
    }

    function handleRefresh(){
      setValue("");

      props.handleRefresh();
    }

    function handleSearch(event)
    {
      if(event.key === 'Enter' )
      {
        props.searchAsin(value);
      }    
    }

    const handleChange = event => {
      setValue(   event.target.value );
    };

    function handleClose(){
      setMessage(null)
    }

    if(!props.data){
      return(
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle"> {props.title}</Typography>   
          </div>
        </Toolbar>   
      )
    }

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        
        <BasicSnackBar 
          variant={'error'}
          message={message}
          handleClose={handleClose}
          open={message}
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className='d-flex flex-row justify-content-between align-items-center pt-1'>
              {numSelected > 0? (
                <div className="d-flex flex-row flex-center">
                  <Typography color="inherit" variant="subtitle1">
                    {numSelected} selected
                  </Typography>
                
                  {props.bulkEdits &&  
                    <BulkEdits
                      bulkEditValues={bulkEditValues}
                      bulkEdits={props.bulkEdits}
                      handleBulkEditChange={props.handleBulkEditChange}
                      selectedRows={props.selectedRows}
                      handleMultipleEdits={props.handleMultipleEdits}
                      showSaveModal={props.showSaveModal}
                    />
                  }
                </div>

              ) : (   
                props.small? 
                <div style={{fontSize:14}}>
                    {props.title}
                </div>
                :
                <Typography variant="h6" id="tableTitle"> {props.title} {props.batch && <Chip label={`Batch: ${props.batch}`} className='ml-2'/>}</Typography>   

              )}
          


              <div className={classes.actions + " flex-wrap d-md-flex d-none"}>
              
                {(props.handleImport) && 
                    <UplaodCSVBtn 
                      requiredHeaders={props.requiredHeaders}
                      handleImport={props.handleImport}
                      hanldeOnError={props.hanldeOnError}
                      bulkAdd={props.bulkAdd}
                      bulkEdit={props.bulkEdit}
                      data={props.data}
                      title={props.title}
                    />
                  }
                  
                  {!props.small &&
                  <div className={classes.search}>
                    <IconButton  className={classes.searchIcon} >
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      placeholder="Search..."
                      value={value}
                      onKeyDown={handleSearch}
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'Search' }}
                      onChange={handleChange}
                    />       
                  </div>
                }

                  <DownloadCSVBtn disabled={props.disableDownload} name={props.title} data={props.data}/>
                  {props.columnSelect && 
                    <ColumnSelector columns={props.columns} handleColumnSelect={props.handleColumnSelect} checkedColumns={props.checkedColumns}/>
                  }
                  <Tooltip title="Filter Columns" className="ml-1">
                    <Badge badgeContent={props.filterCount} color="secondary">
                      <IconButton id={props.title + 'Filter'} aria-label="Filter" onClick={props.toggleFilter}>
                        <FilterListIcon/>
                        <span className="pulse-ring"></span>
                      </IconButton>
                    </Badge>
                  </Tooltip>              

                  <Tooltip title="Refresh Table" className="ml-1">
                    <IconButton aria-label="Refresh" onClick={handleRefresh}>
                      <RefreshIcon/>
                    </IconButton>
                  </Tooltip>

                  {props.handleForm && 
                    <Tooltip title="Add Item" color="secondary">
                      <IconButton aria-label="Add Item" onClick={props.handleForm}>
                        <Add/>
                      </IconButton>
                    </Tooltip>
                  }

                  {props.delete && 
                    <Tooltip title="Delete">
                      <div className="text-danger">
                        <IconButton color="inherit" aria-label="Delete" onClick={doDelete}>
                          <DeleteIcon/>
                        </IconButton>
                      </div>
                    </Tooltip>  
                  }
                </div>
                <div className='d-flex d-md-none'>
                  <BasicDropdown
                    items={dropdownItems}
                  />

                </div>
            </div> 
          </Grid>
          <Grid item xs={12}>
            <div className='d-flex flex-column flex-md-row w-100 justify-content-end pb-2'>
              <span className='d-md-none my-2'>
                {!props.small &&
                    <div className={classes.search}>
                      <IconButton  className={classes.searchIcon} >
                        <SearchIcon />
                      </IconButton>
                      <InputBase
                        placeholder="Search..."
                        value={value}
                        onKeyDown={handleSearch}
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'Search' }}
                        onChange={handleChange}
                      />       
                    </div>
                  }
              </span>
            
              {props.datePicker && 
                  <form className={classes.container} noValidate>
                    <TextField
                      type="date"
                      className={classes.textField}
                      onChange={handlePicker}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>       
                }
                
                {props.keywords && 
                  props.keywords.map((list,index) => {
                    let value = list.value
                    return(
                      <div className=' mb-2'>
                        <KeywordSelect 
                          id={list.id} 
                          placeholder={list.placeholder} 
                          keywords={list.data} 
                          value={value}
                          handleDropDown={handleDropDown}/>
                      </div>
                    )
                  })  
                }
              </div>
          </Grid>

        </Grid>
        


      </Toolbar>
    );
  };