import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import axios from 'axios';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '75%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export default function BasicForm(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({});
  const [error, setError] = React.useState(false);
  const [required, setRequired] = useState([])
  const [disabled, setDisabled] = useState(false)
  

  //effect for getting setting the required fields
  useEffect(() => {
    let newData = []
    props.inputs.map((input) => {
      
      if(input.required){
        newData.push(input.id)
      }
    })

    if(newData.length > 0){
      setDisabled(true)
    }
    setRequired(newData)
  }, []);

 

 
  const handleChange = name => event => {
    let newVal = values
    if(event.target.value === ""){
      newVal[name] = null
    }else{
      newVal[name] = event.target.value
    }
    
    setValues(newVal)

    if(required){
      let newDisabled = false;
      required.map((field) => {

        if(newVal[field] === null || typeof newVal[field] === 'undefined'){
          newDisabled= true;
        }
      })

      setDisabled(newDisabled)
    }
  };

  
  const clearForm = () => {
    let form = document.getElementById('frm');
    setValues({})
    setError(false);
    form.reset();
    
  }

  //function for handling the click of the 'Submit' button
  //Uses a POST call to the 'product_queue/add/' endpoint
  function handleSubmit(event)
  {
    event.preventDefault();
    props.addItem(values);
    clearForm(); 
    
  }

  return (
    <div >
      <div className="d-flex flex-row border-0 mt-2 justify-content-between px-5" style={{maxHeight:55}}>
          <div className="mt-3 d-flex flex-end flex-column" style={{fontSize: 14, color: '#000000DE'}}>{props.title}</div>
      </div>
      <div className="mt-1 d-flex flex-column rounded bg-grey" style={{fontSize: 14, color: '#000000DE'}}>* Required</div>
      <div className=' d-flex flex-column flex-center py-0'>
        <form id='frm' onSubmit={handleSubmit} className={classes.container} noValidate autoComplete="off">
          {props.inputs.map((input,index) => {
            let type, ilp
            switch(input.type){
              case 'select':
              case 'bool':
                return <FormControl className='w-100 mt-8' >     
                            <Select
                            key={index}
                            name='select'
                            className={classes.textField}
                            defaultValue=''
                            onChange={handleChange(input.id)}
                            label={input.label}
                            displayEmpty               
                            >
                            <MenuItem key={-1} value='' disabled >                                                  
                                    <div style={{color: 'rgba(0, 0, 0, 0.54)'}}>{input.label}</div>
                            </MenuItem>
                            { input.type === 'bool' ? 
                              [
                                <MenuItem key={1} value={true}  > Yes</MenuItem>,
                                <MenuItem key={2} value={false}  > No</MenuItem>
                              ]
                            :
                              input.options.map((key, index) =>{
                                return(
                                <MenuItem key={index} value={key}>                                                  
                                    {key}
                                </MenuItem>
                                );
                            })}
                            </Select>
                        </FormControl>
              case 'date':
                type = 'date'
                ilp = {shrink: 'true'}
                break;
              default:
                type = ''
                break;
            }

            return(
              <TextField
                key={index}
                required={input.required}
                id={input.id}
                label={input.label}
                type={type}
                InputLabelProps={ilp}
                className={classes.textField}
                inputProps={input.ip}
                onChange={handleChange(input.id)}
                margin="normal"
                helperText={input.helperText}
              />
            )
          })}

          <Button disabled={disabled} variant="contained" color="secondary" className={classes.button} type='submit'>
          <Icon>add</Icon>
                      Add
          </Button>
        </form>
      </div>
    </div>
  );
}
