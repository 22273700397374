import React, { useState, useEffect } from "react";
import ProductTable from "../../components/Tables/ProductTable/ProductTable";
import { getTableData } from "../../components/Tables/ProductTable/TableData";

export default function FBAReorder() {
  const [tableData, setTableData] = useState([{}]);

  //********EFFECTS********* */

  //effect for calling the endpoint to get the correct data for the table
  //stores the response data in state as 'tableData'
  useEffect(() => {
    getTableData("WOCAnalysis")
      .then(({ data }) => {
        setTableData(data);
      })
      .catch(function(error) {
        setTableData([]);
        console.log(error);
      });
  }, []);

  return (
    <>
      <div>
        <ProductTable
          title="FBA Reorder"
          data={tableData}
          rows={25}
          cols={17}
          columns={[
            {
              id: "image_links",
              header: "",
              type: "image",
            },
            {
              anchor: "https://www.amazon.com/dp/",
              tag: "asin",
              id: "asin",
              header: "ASIN",
              type: "anchor",
            },
            {
              id: "brand",
              header: "Brand",
              type: "text",
            },
            {
              id: "sku",
              header: "SKU",
              type: "text",
            },
            {
              id: "action",
              header: "Action",
              type: "text",
            },
            {
              id: "reorder_qty",
              header: "Reorder Quantity",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "t90d_units_sold",
              header: "T90D Units Sold",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "t90d_instock_days",
              header: "T90D Instock Days",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "t90d_units_per_day",
              header: "T90D Units Per Day",
              type: "numeric",
              decimals: 2,
            },
            {
              id: "t90d_days_with_a_sale",
              header: "T90D Days With a Sale",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "total_current_inv",
              header: "Current Inventory",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "current_inbound",
              header: "Current Inbound",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "current_available",
              header: "Current Available",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "current_fc_transfer",
              header: "Current FC Transfer",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "current_woc",
              header: "Current WOC",
              type: "numeric",
              decimals: 2,
            },
            {
              id: "t90d_asp",
              header: "T90D ASP",
              type: "numeric",
              decimals: 2,
            },
            {
              id: "landed_pcogs",
              header: "Landed Product Cost",
              type: "numeric",
              decimals: 2,
            },
            {
              id: "fl_pcogs",
              header: "FL Product Cost",
              type: "numeric",
              decimals: 2,
            },
            {
              id: "t90d_unit_profit",
              header: "T90D Unit Profit",
              type: "numeric",
              decimals: 2,
            },
          ]}
        />
      </div>
    </>
  );
}
