import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import axios from 'axios';
import { CatalogSnapshotTile } from '../../components/Tiles/CatalogSnapshotTile';
import { CatalogActionsTile } from '../../components/Tiles/CatalogActions';

export default function CatalogOverview() {
    
    return(
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <CatalogSnapshotTile/>
            </Grid>
            <Grid item xs={12} lg={6}>
                <CatalogActionsTile className='card-stretch'/>
            </Grid>
        </Grid>
    )
}