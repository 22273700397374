import React from "react";
import {Redirect, Switch} from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import DistCandidates from "../modules/Opportunites/DistCandidates";
import ExistingBrands from "../modules/Opportunites/ExistingBrands";
import Keywords from "../modules/Opportunites/Keywords";
import Sellers from "../modules/Opportunites/Sellers";


export default function OpportunitesPage() {
    return (
        <Switch>
          <Redirect
            exact={true}
            from="/opportunities"
            to="/opportunities/existing-brands"
          />
          <ContentRoute
            path="/opportunities/dist-candidates" 
            component={DistCandidates} 
          />
          <ContentRoute
            path="/opportunities/existing-brands" 
            component={ExistingBrands} 
          />
          <ContentRoute
            path="/opportunities/sellers" 
            component={Sellers} 
          />
          <ContentRoute
            path="/opportunities/keywords" 
            component={Keywords} 
          />
        </Switch>
    );
}