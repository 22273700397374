import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { Toolbar } from '@material-ui/core';
import DownloadCSVBtn from '../Buttons/DownloadCSVBtn';
import UplaodCSVBtn from '../Buttons/UploadCSVBtn';
import { CircularProgress } from '@material-ui/core';
import BasicModal from '../Modals/BasicModal';
import KeywordSelect from '../Buttons/KeywordSelect';

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      height: '100%'
    },
    paper: {
      width: '100%',
      height: '100%',
      overflowX: 'hidden'
    },
    table: {
      height: '64%'
    },
    title: {
      flex: '0 0 auto',
      fontSize: 14,
    },
    toolbar:{
      minHeight: '37px !important',
      paddingTop: 5,
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft:16,
      paddingRight: 8,
    },
    spacer: {
      flex: '1 2 100%',
    },
  }));

export default function BasicTable(props) {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(props.rows);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
      setPage(0)
    }, [props.data]);

    function handleChangePage(event, newPage) {
      setPage(newPage);
    }
  
    function handleDelete(event, id){
        event.preventDefault();
        setDeleteModal(true);
        setDeleteId(id);
    }

    function closeDeleteModal(){
      setDeleteId(null);
      setDeleteModal(false);
    }

    function confirmDelete(){
      props.doDelete(deleteId);
      closeDeleteModal();
    }

    function handleChangeRowsPerPage(event) {
      setRowsPerPage(+event.target.value);
    }


    
    if(!props.data){
      return(
        <Paper style={{height: 360, width: '100%'}}>
          <Toolbar className={classes.toolbar}>
              <div className={classes.title}>
                  {props.title}
              </div>
          </Toolbar>
          <Table>
            <TableBody>
              <TableRow colSpan={3} align='center'><div className="w-100 d-flex flex-column flex-center"><h5>Loading Data...</h5><div><CircularProgress color="secondary"/></div></div></TableRow>
            </TableBody>
          </Table>
        </Paper>)
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage);
    return (
        <div className={classes.root}>
          
          <BasicModal
            title="Delete Keyword Confirmation"
            show={deleteModal}
            body="Are you sure you want to delete this keyword?"
            handleCancel={closeDeleteModal}
            handleConfirm={confirmDelete}
          />
          <div className={classes.paper}>
            {props.title && <Toolbar className={classes.toolbar}>
              <div className={classes.title}>
                  {props.title}
              </div>
              <span className='d-flex justify-content-between'>
                {props.keywords && 
                  <KeywordSelect
                    id={0} 
                    placeholder={props.selectPH} 
                    keywords={props.keywords} 
                    handleDropDown={props.handleKey}
                    value={props.value}
                  />
                }
                  {props.upload && 
                    <UplaodCSVBtn 
                        links={props.links}
                        handleImport={props.handleImport}
                        handleOnError={props.handleOnError}
                        deleteTitle={props.deleteTitle}
                        addTitle={props.addTitle}
                    />}
                {props.download && <DownloadCSVBtn name={props.title} data={props.data}/>}
              </span>
            </Toolbar>}
            <Table className={classes.table} size="small">   
              <TableHead>
                <TableRow>
                    {props.columns.map((col, index) =>{

                        if(index === 0 ){
                            return(
                                <TableCell key={index}>{col.header}</TableCell>
                            )
                        }
                        return(
                                <TableCell key={index} align={col.numeric? 'right': 'left'} >{col.header}</TableCell>
                        );
                    })}

                    {props.delete && <TableCell align='right' >Delete</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data.length > 0?
                    props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => {

                    return(
                    <TableRow key={index}>
                        {props.columns.map((col, index) => {
                            if(index === 0 ){
                                return(
                                    <TableCell component="th" scope="row" >
                                        {row[col.id]}
                                    </TableCell>
                                );
                            }
                            if(col.numeric){
                              let cellChild
                              if(row[col.id]){
                                let num = parseFloat(row[col.id]).toFixed(col.decimals) //turn it into a number with decimals
                                let str = num.toString().split(".");                    //turn back to a string and split by decimal point
                                str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");  //add commas to the string 
                                cellChild =  str.join(".");                             //join the decimal to the string
                              }else{
                                cellChild = 0;
                              }   
                              return(
                                <TableCell align={'right'} >
                                    {cellChild} 
                                </TableCell>  
                            );
                            }

                            return(
                                <TableCell align={'left'} >
                                    {row[col.id]} 
                                </TableCell>  
                            );
                        })}
                        
                        
                        {props.export && <TableCell align="right" >
                          <div className="btn btn-icon btn-light btn-hover-primary btn-sm" onClick={event => props.handleExport(event, row)}>
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"/>
                                        <path d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z" fill="#000000" fill-rule="nonzero" />
                                        <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 8.000000) rotate(-180.000000) translate(-12.000000, -8.000000) " x="11" y="1" width="2" height="14" rx="1"/>
                                        <path d="M7.70710678,15.7071068 C7.31658249,16.0976311 6.68341751,16.0976311 6.29289322,15.7071068 C5.90236893,15.3165825 5.90236893,14.6834175 6.29289322,14.2928932 L11.2928932,9.29289322 C11.6689749,8.91681153 12.2736364,8.90091039 12.6689647,9.25670585 L17.6689647,13.7567059 C18.0794748,14.1261649 18.1127532,14.7584547 17.7432941,15.1689647 C17.3738351,15.5794748 16.7415453,15.6127532 16.3310353,15.2432941 L12.0362375,11.3779761 L7.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000004, 12.499999) rotate(-180.000000) translate(-12.000004, -12.499999) "/>
                                    </g>
                                  </svg>
                              </span>
                            </div>
                          
                        </TableCell>}
                        {props.delete && <TableCell align="right" >
                            <div
                                onClick={event => handleDelete(event, row.id)}
                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            >
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24"/>
                                            <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                                            <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000"/>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </TableCell>}
                    </TableRow>)
                }): <TableRow ><TableCell colSpan={props.colSpan} align="center" className="border-0">no data available</TableCell></TableRow>}
                {emptyRows > 0 && props.emptyRows && (
                <TableRow style={{ height: 29 * emptyRows }}>
                  <TableCell colSpan={props.colSpan} />
                </TableRow>
              )}
              </TableBody>
            </Table>
            {props.pagination && <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={props.data && props.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />}
          </div>
        </div>
      );
}