import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function VcCookies() {
    const url = `${process.env.REACT_APP_API_URL}sales-vc-cookies/`

    return (<>
        <PageComponent
            tableTitle="VC Cookies"
            url={url}
            addItems
            formInputs={[
                {
                    required: true,
                    id: 'curl',
                    label: 'CURL',
                    // ip: {maxLength: 100000}
                },
            ]}
            tableColumns={[
                {
                    id: 'curl',
                    header: 'CURL',
                    type: 'text'
                },
                {
                    id: 'creation_date',
                    header: 'Creation Date',
                    type: 'date',
                    format: 'MM/DD/YYYY'
                },
            ]}
        />
    </>);
}