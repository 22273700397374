// Currently being used in PO.js, Invoices, and the PO Modal
// Its a table component that uses renderes each cell as a Textfield that allows the user to input data

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, TextField, Checkbox, MenuItem, InputBase, FormControl, Select} from '@material-ui/core';
import { UpdateTable, AddTableData, DeleteTableData} from './ProductTable/TableData';
import BasicSnackBar from '../Alerts/BasicSnackBar';

const useStyles = makeStyles(theme => ({
    textfield: {
        width: '100% !important',
        height: '38px !important',
        [`& fieldset`]: {
            borderRadius: 0,
            
          },
    },

    select: {
        height:'100% !important',
        [`& input`]: {
            height: 40,
            
          },
    }

  }));

export default function InputTable(props) {
    const classes = useStyles()
    const [activeRows, setActiveRows] = useState(null)
    const [values, setValues] = useState({})
    const [count, setCount] = useState(0)
    const [changeIds, setChangeIds] = useState([])
    const [deleteIds, setDeleteIds] = useState([])
    const [addedIds, setAddedIds] = useState([])
    const [message, setMessage] = useState(null)
    const [isError, setIsError] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [requiredFields, setRequiredFields] = useState(null)
    const [selected, setSelected] = useState([])

    //******************************EFFECTS****************************** */
    //effect for setting the values variable to keep track of all the changes 
    //called everytime the input data changes
    useEffect(() => {
        //set the required fields from the input columns
        let newReqs = []
        props.columns.map(col => {
            if(col.required){
                newReqs.push(col.id)
            }
        })

        setRequiredFields(newReqs)
        if(props.data){
            initialize()
        }
    }, [props.data]);

    //effect for adding empty rows to the table
    //changes everytime the length of the table changes
    useEffect(() => {
        if(Object.keys(values).length > 0){
            if(addedIds.length %5 === 0){
                addRows(5)
            }  
        }
           
    }, [addedIds]);

 

    //function for initializing all of the data
    function initialize() {
        let newCount = 0
        let newValues = {}
        let actives=[]
        //add a rowId to keep track of each row
        props.data.map(item => {
            newValues[newCount] = item
            actives.push(newCount)
            newCount++
        })

        
        if(!props.noAdd){
            //make an empty row
            let newRow = {}
            props.columns.map(field => {
                newRow[field.id] = null
                if(field.type === 'bool'){
                    newRow[field.id] = false
                }
            })
            //add 5 empty rows to the values
            for (let index = newCount; index < newCount + 5; index++) {
                newValues[index] = {...newRow}
            }
            newCount += 5
        }
        

        setActiveRows(actives)
        setValues(newValues)
        setCount(newCount)
        setChangeIds([])
        setDeleteIds([])
        setSubmitted(false)
    }

    //******************************METHODS****************************** */
    //function for handling changes in the table
    const handleChange = (id, field) =>event =>{
        let newValues = {...values}
        let newItem = newValues[id]
        newItem[field]  =event.target.value
        newValues[id] = newItem
        setValues(newValues)

        if(newItem['id'] && !changeIds.includes(id)){
           
            setChangeIds([...changeIds, id])//store the rowindex of the change
        }else if(typeof newItem['id'] === 'undefined' && !addedIds.includes(id)){
            setAddedIds([...addedIds, id])//store the rowindex into the addedIds
            setActiveRows([...activeRows,id])
        }
    }

    //function for handling checkbox for bool values in the table
    const handleCheck = (id, field) =>event =>{
        let newValues = {...values}
        let newItem = newValues[id]

        //see if item was checked or not and toggle
        let checked = false
        if(event.target.checked){
            checked = true
        }

        newItem[field]  = checked
        newValues[id] = newItem
        setValues(newValues)
        if(newItem['id'] && !changeIds.includes(id)){
           
            setChangeIds([...changeIds, id])//store the rowindex of the change
        }else if(typeof newItem['id'] === 'undefined' && !addedIds.includes(id)){
            setAddedIds([...addedIds, id])//store the rowindex into the addedIds
            setActiveRows([...activeRows,id])
        }
    }

    //function for handling the selected checkbox values (first column checkboxes)
    const handleSelect = key => event => {
        let newSelected = [...selected]
        //if row checked then add to the 
        if(event.target.checked){
            newSelected.push(key)

        }else{
            //loop throught the selected and pick the take the id that matches the row
            newSelected.map((id, index) =>{
                if(id === key){
                    //remove item from selected array
                    newSelected.splice(index, 1)
                }
            })
        }
        setSelected(newSelected)
        if(props.handleSelect){
            props.handleSelect(newSelected)
        }
    }

    //function for deleting rows
    function deleteRow(){
        let newDeleteIds = deleteIds
        let newIds = [...changeIds]
        let newValues = {...values}
        
        //loop through the selected rows
        selected.map(key => {
            //if item already has an id(exists in the data table) then save id for deletion
            if(typeof values[key]['id'] !== 'undefined'){
                newDeleteIds.push(values[key]['id'])
            }

            //remove id from list of changes
            let index = newIds.indexOf(key)
            if(index > -1){
                newIds.splice(index,1)
            }

            //remove from the table 
            delete newValues[key]
            
        })


        setDeleteIds(newDeleteIds)
        setChangeIds(newIds)
        setValues(newValues)

        //reset selected
        setSelected([])

        setMessage('Row(s) deleted')
    }

    //function for adding rows
    function addRows(num){
        //make an empty object
        let newRow = {}
        props.columns.map(field => {
            newRow[field.id] = null
            if(field.type === 'bool'){
                newRow[field.id] = false
            }
        })

        let newVals = {...values}
        //add to values list to show in table
        for (let index = count; index < count + num; index++) {
            newVals[index] = {...newRow}
        }
        
        //update values
        setValues(newVals)

        //update count 
        let newCount = count
        newCount+=num
        setCount(newCount)
    }

    //function for handleing the submit button
    async function handleSubmit(){

        let allChanges = [],additions = [], edits = []
        let newErrors = { empty: 0, failed: [] }
        let reqs = true

        //check to see if top row values are null
        if(props.appendedValues){
            Object.keys(props.appendedValues).map(key => {
                if (props.appendedValues[key] === '' || props.appendedValues[key] === null) {
                    reqs = false
                }
            })
        }


        //loop thorugh the changeid and addedid arrays to make an array of the rows
        changeIds.map(id => {
            //also, add the topRowValues to each row
            let obj = Object.assign(values[id] , props.appendedValues)
            edits.push(obj)
        })


        //loop thorugh the changeid and addedid arrays to make an array of the rows
        addedIds.map(id => {
            //also, add the topRowValues to each row
            let obj = Object.assign(values[id] , props.appendedValues)
            additions.push(obj)
        })

        //return before sending the calls if the required fields were not filled out
        if (!reqs) {
            setSubmitted(true) // set true to show any errors
            props.handleErrors()
            setIsError(true)
            setMessage('You must fill out the required fields before submitting.')
            return
        }

        //make the calls and keep count of the successfull calls
        let result = 0
        
        if(edits.length > 0){
            result += await UpdateTable(props.url, 0,newErrors, edits.slice(0,20), edits)
        }
        if(additions.length > 0){
            result += await AddTableData(props.url, 0, newErrors, additions.slice(0, 20), additions)
        }
        if(deleteIds.length > 0){
            result += await DeleteTableData(props.url, 0, 0,  deleteIds.slice(0,20), deleteIds);
        }

        if(result){
            props.refresh()
        }
        setSubmitted(true) // set true to show any errors
        props.handleSubmit(result, newErrors)
    }

    //function for closing the alert snackbar
    function closeAlert() {
        setMessage(null)
        setIsError(false)
    }


    return(
        <>
        <BasicSnackBar
            open={message !== null}
            variant={isError ? 'error' : 'success'}
            message={message}
            handleClose={closeAlert}
        />
        {props.data && values?
        <>
        {!props.noButtons &&
            <div className='d-flex flex-row align-items-baseline'>
                {props.buttons}

                {!props.noDelete && <button disabled={selected.length === 0} className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3" onClick={deleteRow}>
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                            <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000"/>
                        </g>
                        </svg>
                    </span>
                </button>}
            </div>}
            <div class="table-responsive w-100" style={{ height: "calc(100% - 100px)", position: "relative" }}>
                <table class="table" style={{width: '99%'}}>
                    <thead>
                        <tr style={{ top: 0, left: 0, zIndex: 2, position: 'sticky', backgroundColor: '#ffffff' }}>
                            {!props.noDelete && <th ></th>}

                            {props.columns.map(col => {
                                if(col.type === 'numeric' || col.type === 'display-num'){
                                    return <th class="hidden text-center font-weight-bolder ">{col.header}</th>
                                }

                                return(
                                    <th class="hidden text-center font-weight-bolder ">{col.header}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody >
                        { Object.keys(values).length > 0 ?
                            
                            Object.keys(values).map((key, index) => {
                                
                                
                                return (
                                    <>
                                        <tr class="font-weight-bold bg-hover-light-primary" style={{height: 40}}>
                                            
                                            {!props.noDelete && <td class="p-0 align-left border border-2" style={{width: 40}}>
                                                <Checkbox
                                                    checked={selected.includes(key)}
                                                    onChange={handleSelect(key)}                     
                                                />
                                            </td>}
                                            {props.columns.map(col => {
                                                let cellChild, type, align='left', justify='start', value =values[key][col.id]
                                                
                                                switch (col.type) {
                                                    case 'select':
                                                        cellChild =
                                                            <FormControl className='w-100  px-2' style={{height: '40px'}}>
                                                                <Select
                                                                    name='select'
                                                                    value={value}
                                                                    onChange={col.onChange? col.onChange(key, col.id) :handleChange(key, col.id)}
                                                                    displayEmpty
                                                                    className={classes.select}
                                                                    input={<InputBase />}
                                                                >
                                                                    <MenuItem key={-1} value={null} disabled >
                                                                        {'Select ' + col.header}
                                                                    </MenuItem>
                                                                    {col.options && col.options.map((item, index) => {
                                                                        return (
                                                                            <MenuItem className='h-100' key={index} value={item}>
                                                                                {item}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        break;

                                                    case 'bool':
                                                        cellChild=
                                                        <div className='w-100 d-flex flex-center'>
                                                            <Checkbox
                                                                checked={values[key][col.id]}
                                                                onChange={col.onChange? col.onChange :handleCheck(key, col.id)}
                                                                inputProps={{
                                                                'aria-label': 'primary checkbox',
                                                                }}
                                                            />
                                                        </div>
                                           
                                                        break;
                                                        
                                                    
                                                    case 'date': 
                                                        type='date'
                                                    case 'numeric':
                                                        if(col.type === 'numeric'){
                                                            align='right'
                                                            justify='end'
                                                        }
                                                        if(col.calculation){
                                                            value = col.calculation(values[key])
                                                        }

                                                    case 'display-num':
                                                        if(col.type === 'display-num'){
                                                            align='right'
                                                            justify= 'end'
                                                            if(col.id === 'total_line_price'){
                                                                value = parseFloat(values[key]['unit_line_price'] * values[key]['qty']).toFixed(2)
                                                              
                                                            }else{
                                                                value = parseFloat(values[key]['qty_ordered'] * values[key]['unit_price']).toFixed(2)
                                                            }
                                                        }
                                                    case 'text':
                                                        cellChild= (
                                                            <InputBase
                                                                type={type}
                                                                value={value}
                                                                onChange={col.onChange? col.onChange(key, col.id) :handleChange(key, col.id)}
                                                                error={col.required && submitted && values[key][col.id] === null}
                                                                className={classes.textfield}                                    
                                                                inputProps={{
                                                                    style: { textAlign: align, padding: 10, height: '100%', borderRadius: 0}
                                                                }}          
                                                            />                              
                                                        )
                                                        break;
                                                
                                                    default:
                                                        break;
                                                }

                                                return(
                                                    <td class={"p-0 border border-2 "} style={col.style}>
                                                        {cellChild}
                        
                                                    </td>)
                                                
                                            })}
                                           
                                        </tr>
                                    </>
                                )
                            })
                        :
                            <tr>
                                <td class="pt-7 ">
                                    <div className='w-100 d-flex flex-center'>
                                    No data available
                                    </div>
                                    
                                </td>       
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        {!props.noButtons &&
            <div class="w-100 pt-10 d-flex justify-content-start">
                <div>
                    <button type="button" disabled={changeIds.length === 0 && deleteIds.length === 0 && addedIds.length === 0 && props.disabled} class="mr-3 btn btn-light-danger font-weight-bold" onClick={props.refresh}>Reset All</button>
                    <button type="button" disabled={changeIds.length === 0 && deleteIds.length === 0 && addedIds.length === 0 && props.disabled} class="btn btn-primary font-weight-bold" onClick={handleSubmit}>Save Changes</button>
                </div>
            </div>
        }

        </>
        
        :
            <div className="w-100 h-100 d-flex flex-column flex-center ">
                <h5>Loading Data...</h5>
                <div><CircularProgress color="secondary" /></div>
            </div>
        }

        </>
        )
        
}