import React from "react";
import {Redirect, Switch} from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import BaseSKU  from "../modules/Catalog/BaseSKU";
import ASINMargin from "../modules/Catalog/ASINMargin";
import ASINSKU from "../modules/Catalog/ASINSKU";

export default function CatalogPage() {
    return (
        <Switch>
          <Redirect
            exact={true}
            from="/catalog"
            to="/catalog/base-sku"
          />
    
          <ContentRoute
            path="/catalog/base-sku" 
            component={BaseSKU} 
          />
          <ContentRoute
            path="/catalog/asin-margin" 
            component={ASINMargin} 
          />   
          <ContentRoute
            path="/catalog/asin-sku" 
            component={ASINSKU} 
          />  
        </Switch>
    );
}