import React from "react";
import {Redirect, Switch} from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import RepricerLog from "../modules/Pricing/RepricerLog";
import RepricedItems from "../modules/Pricing/RepricedItems";
import PricingRules from "../modules/Pricing/PricingRules";


export default function PricingPage() {
    return (
        <Switch>
          <Redirect
            exact={true}
            from="/pricing"
            to="/pricing/repricer-log"
          />
    
          <ContentRoute
            path="/pricing/repricer-log" 
            component={RepricerLog} 
          />

          <ContentRoute
            path="/pricing/repriced-items" 
            component={RepricedItems} 
          />
          <ContentRoute
            path="/pricing/pricing-rules" 
            component={PricingRules} 
          />
        </Switch>
    );
}