import React, { useState, useEffect } from "react";
import PageComponent from "../../components/PageComponent";
import BasicModal from "../../components/Modals/BasicModal";
import ProductTable from "../../components/Tables/ProductTable/ProductTable";
import DownloadCSVBtn from "../../components/Buttons/DownloadCSVBtn";
import axios from "axios";

export default function VCReorder() {
  const url = `${process.env.REACT_APP_API_URL}inventory-loc-vc-reorder/`;
  const source = axios.CancelToken.source();
  const [showModal, setShowModal] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [selectedAsin, setSelectedAsin] = useState("");

  //method for showing the modal when the user clicks on the asin cell
  //also takes the input asin and sets the table data accordingly
  function openModal(asin) {
    setShowModal(true);
    setSelectedAsin(asin);
    //pass the asin into the endpoint and get/store the data
    axios
      .get(process.env.REACT_APP_API_URL + "sales-vc-po/?asin=" + asin)
      .then(({ data }) => {
        setTableData(data);
      })
      .catch(function(error) {
        if (axios.isCancel(error)) {
          console.log("REQUEST CANCELLED");
        } else {
          setTableData([]);
          console.log(error);
        }
      });
  }

  //function for closing the modal and resetting the table data
  function closeModals() {
    setShowModal(false);
    setTableData(null);
    setSelectedAsin("");
    source.cancel();
  }

  return (
    <>
      <BasicModal
        title={
          <div className="w-100 d-flex flex-row justify-content-between">
            <span>{"Open Orders - ASIN " + selectedAsin}</span>
            <span>
              <DownloadCSVBtn name={selectedAsin} data={tableData} />
            </span>
          </div>
        }
        onHide={closeModals}
        size="lg"
        body={
          <ProductTable
            small
            data={tableData}
            rows={10}
            noToolbar
            columns={[
              {
                id: "po_number",
                header: "PO Number",
                type: "modal",
              },
              {
                id: "window_end",
                header: "Window End",
                type: "date",
                format: "MM/DD/YYYY",
              },

              {
                id: "accepted_qty",
                header: "Accepted QTY",
                type: "numeric",
                decimals: 0,
              },
              {
                id: "shipped_qty",
                header: "Shipped QTY",
                type: "numeric",
                decimals: 0,
              },
              {
                id: "order_date",
                header: "Order Date",
                type: "date",
                format: "MM/DD/YYYY",
              },
              ,
            ]}
          />
        }
        show={showModal}
        handleCancel={closeModals}
        noConfirm
      />
      <PageComponent
        tableTitle="VC Open Items"
        url={url}
        tableColumns={[
          {
            id: "image_links",
            header: "",
            type: "image",
          },
          {
            id: "asin",
            header: "ASIN",
            type: "function",
            function: openModal,
          },
          {
            id: "sku",
            header: "SKU",
            type: "text",
          },
          {
            id: "brand",
            header: "Brand",
            type: "text",
          },
          {
            id: "kit",
            header: "Kit",
            type: "bool",
          },
          {
            id: "onhand_qty",
            header: "Onhand",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "onorder_qty",
            header: "Onorder",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "committed_qty",
            header: "Committed",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "new_order_qty",
            header: "New Order Quantity",
            type: "numeric",
            decimals: 0,
          },
        ]}
      />
    </>
  );
}
