// This component is the custom edit modal for VCOrders.js
// Rendered in ProductTable
// called when a column has type "vcedit"

import React, { useState, useEffect } from "react";
import BasicModal from "./BasicModal";
import { Checkbox, TextField } from "@material-ui/core";
import { Grid } from "@material-ui/core";

export default function VCOrderEditModal(props) {
  const initial = {
    id: "",
    po_number: "",
    order_date: "",
    asin: "",
    vc_sku: null,
    packing_remarks: null,
    shipment_mode: null,
    shipment_identifier: null,
    shipment_active: null,
    shipped_qty: null,
    flag_routed: null,
    flag_packed: null,
    flag_shipped: null,
    date_shipped: null,
    flag_cancelled: null,
    flag_asn: null,
    flag_invoiced: null,
    accepted_qty: null,
  };
  const headers1 = [
    {
      type: "date",
      id: "order_date",
      header: "PO Date",
    },
    {
      id: "po_number",
      header: "PO Number",
      type: "text",
    },
    {
      id: "vc_sku",
      header: "SKU",
      type: "text",
    },
    {
      id: "asin",
      header: "ASIN",
      type: "test",
    },
  ];

  const headers2 = [
    {
      id: "flag_routed",
      header: "Routed",
    },
    {
      id: "flag_packed",
      header: "Packed",
    },
    {
      id: "flag_shipped",
      header: "Shipped",
    },
    {
      id: "flag_asn",
      header: "ASN",
    },
    {
      id: "flag_invoiced",
      header: "Invoiced",
    },
    {
      id: "flag_cancelled",
      header: "Cancelled",
    },
  ];

  const headers3 = [
    {
      id: "packing_remarks",
      header: "Packing Remarks",
      type: "text",
    },
    {
      id: "shipment_mode",
      header: "Shipment Mode",
      type: "text",
    },
    {
      id: "shipment_identifier",
      header: "Shipment Identifier",
      type: "text",
    },
  ];

  const headers4 = [
    {
      id: "accepted_qty",
      header: "Accepted Qty",
      type: "numeric",
    },
    {
      id: "shipped_qty",
      header: "Shipped Qty",
      type: "numeric",
    },
    {
      type: "date",
      id: "date_shipped",
      header: "Date Shipped",
    },
  ];
  const [values, setValues] = useState(initial);

  //***************EFFECTS******************** */
  //effect for initializing the values variable based off of the inputted row
  useEffect(() => {
    if (props.row) {
      console.log(props.row, values);
      //loop through the values object and set to the inputted row value initially
      for (const key in values) {
        values[key] = props.row[key];
      }
    }
  }, [props.row, props.show]);

  //***************METHODS******************** */
  //function for closing the modal and resetting all of the data
  function closeModal() {
    setValues(initial);
    props.closeModal();
  }

  //function for handling the changes of regular textfields
  const handleChanges = (id) => (event) => {
    //handle dates correctly
    if (id.indexOf("date") > -1 && event.target.value === "") {
      setValues({ ...values, [id]: null });
    } else {
      setValues({ ...values, [id]: event.target.value });
    }
  };

  //function for handling the changes of checkboxes
  const handleChecked = (id) => (event) => {
    setValues({ ...values, [id]: event.target.checked });
  };

  //function for handling the 'save changes' button
  //resets the state data and closes the modal
  function handleConfirm() {
    props.handleConfirm(props.row, values);
    closeModal();
  }

  return (
    <BasicModal
      size={"lg"}
      show={props.show}
      title={props.row && props.row.vc_title}
      confirmTitle="Save Changes"
      handleConfirm={handleConfirm}
      handleCancel={closeModal}
      onHide={closeModal}
      body={
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="d-flex flex-column  px-5">
              {headers1.map((col) => {
                return (
                  <div className="d-flex align-items-end mb-2">
                    <span className="h6 w-100 m-0">{col.header}:</span>
                    <TextField
                      type={col.type}
                      value={values[col.id]}
                      onChange={handleChanges(col.id)}
                      className="w-100"
                    />
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={6} className=" border-bottom border-left">
            <div className="d-flex flex-center">
              <div className="d-flex flex-column">
                {headers2.map((col) => {
                  return (
                    <div className="d-flex align-items-center justify-content-between mb-2 ">
                      <span className="h6  m-0">{col.header}:</span>
                      <Checkbox
                        checked={values[col.id]}
                        onChange={handleChecked(col.id)}
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="d-flex flex-column  px-5">
              <div className="d-flex align-items-center">
                <span className="h6 m-0 mr-5">Active Shipment:</span>
                <Checkbox
                  checked={values["shipment_active"]}
                  onChange={handleChecked("shipment_active")}
                />
              </div>
              {headers3.map((col) => {
                return (
                  <div className="d-flex align-items-end mb-2">
                    <span className="h6 w-100 m-0">{col.header}:</span>
                    <TextField
                      type={col.type}
                      value={values[col.id]}
                      onChange={handleChanges(col.id)}
                      className="w-100"
                    />
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="d-flex flex-column  px-5">
              {headers4.map((col) => {
                return (
                  <div className="d-flex align-items-end mb-2">
                    <span className="h6 w-100 m-0">{col.header}:</span>

                    <TextField
                      type={col.type}
                      value={values[col.id]}
                      onChange={handleChanges(col.id)}
                      className="w-100"
                    />
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>
      }
    />
  );
}
