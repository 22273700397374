import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function IdleInventory(){
    const base_url = process.env.REACT_APP_API_URL
    
    const fields={
        asin:{
            anchor: 'https://www.amazon.com/dp/',
            tag: 'asin',
            id: 'asin',
            header: 'ASIN',
            type: 'anchor'
        },
        sku:{
            id: 'sku',
            header: 'Sku',
            type: 'text'
        },
        item_name: {
            id: 'item_name',
            header: 'Item Name',
            type: 'text',
        },
        profit_margin: {
            id: 'profit_margin',
            header: 'Profit Margin',
            type: 'numeric',
            decimals: 2
        },
        max_ship_qty: {
            id: 'max_ship_qty',
            header: ' Max Ship Qty',
            type: 'numeric',
            decimals: 0
        },
        review_count: {
            id: 'review_count',
            header: 'Review Count',
            type: 'numeric',
            decimals: 0
        },
        
    }

 

    return(
        <PageComponent
            tableTitle="Idle Inventory"
            url={base_url+ "inventory-loc-idle/"}
            tableColumns={Object.values(fields)}
        />
    )
}