import React, { useState, useEffect } from 'react';
import PageComponent2 from '../../components/PageComponent2';

export default function InventorySummary() {
    const url = `${process.env.REACT_APP_API_URL}inventory-loc-summary/`

    return(
        <PageComponent2
            tableTitle="Inventory Summary"
            url={url}
            tableColumns={[
                {
                    id: 'base_sku',
                    header: 'Base SKU',
                    type: 'text',
                },
                {
                    id: 'brand',
                    header: 'Brand',
                    type: 'text',
                },
                {
                    id: 'onhand_qty',
                    header: 'Onhand',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'onorder_qty',
                    header: 'Onorder',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'committed_qty',
                    header: 'Committed',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'adjustments_qty',
                    header: 'Adjustments',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'inbound_received_qty',
                    header: 'Inbound Recieved',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'vc_shipped_qty',
                    header: 'VC Shipped',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'fba_transfer_qty',
                    header: 'FBA Transfer',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'direct_ship_qty',
                    header: 'Direct Ship',
                    type: 'numeric',
                    decimals: 0
                },
            ]}
        />
    )
}