import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import FBADaily from "../modules/Inventory/FBADaily";
import FBALatest from "../modules/Inventory/FBALatest";
import FBAReorder from "../modules/Inventory/FBAReorder";
import InventoryHealth from "../modules/Inventory/InventoryHealth";
import { shallowEqual, useSelector } from "react-redux";
import FBAAsinPriority from "../modules/Inventory/FBAAsinPriority";
import FBAReturns from "../modules/Inventory/FBAReturns";


export default function InventoryPage() {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user
    }),
    shallowEqual
  );


  return (
    <Switch>
      <Redirect
        exact={true}
        from="/inventory"
        to="/inventory/fba-latest"
      />

      <Route
        path="/inventory/fba-latest"
        component={FBALatest}
      />

      <ContentRoute
        path="/inventory/fba-daily"
        component={FBADaily}
      />


      <ContentRoute
        path="/inventory/inventory-health"
        component={InventoryHealth}
      />
      <ContentRoute
        path="/inventory/fba-reorder"
        component={FBAReorder}
      />
      <ContentRoute
        path="/inventory/fba-asin-priority"
        component={FBAAsinPriority}
      />
      <ContentRoute
        path="/inventory/fba-returns"
        component={FBAReturns}
      />
      <Redirect from="/inventory" to="/error" />
    </Switch>
  );
}