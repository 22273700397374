// Rendered in the ProductTableToolbar if columnSelect props is passed as true
// This component is just a button that opens a modal with a checklist for all the columns
// When a column is checked or unchecked then it adds/removes it from the product table
// See AsinEconomicsDaily for an example of how to pass props to render this


import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ViewColumnIcon from '@material-ui/icons/ViewColumnRounded';
import Tooltip from '@material-ui/core/Tooltip';
import BasicModal from '../../Modals/BasicModal';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    maxHeight: 500,
    overflowY:'scroll',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ColumnSelector(props) {
    const classes = useStyles();
    const [show, setShow] = React.useState(false)

  //handles modal state
  function closeModals(){
      setShow(false)
  }

  return (
    <>
        <Tooltip title="Select Columns" className="ml-1">
            <IconButton id="Column Select" aria-label="Column Select" onClick={() =>setShow(true)} >
                <ViewColumnIcon/>
            </IconButton>    
        </Tooltip>
  
        <BasicModal
          title="Select Columns to View in Table"
          onHide={closeModals}
          body={
              <div className='d-flex flex-center'>
                  
                <List className={classes.root}>
                {props.columns.map(value => {
                    const labelId = `checkbox-list-label-${value.id}`;
        
                    return (
                    <ListItem key={value} role={undefined} dense button onClick={() =>props.handleColumnSelect(value)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={props.checkedColumns.includes(value.id)}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value.type==="image"? "Image": value.header} />
                    </ListItem>
                    );
                })}
                </List>
            </div>
          }
          show={show}
          handleCancel={closeModals}
          cancelTitle="Close"
          noConfirm
        />

        
    </>
  );
}
