import React, { useState, useEffect, useRef } from "react";
import PageComponent from "../../components/PageComponent";
import ProductTable from "../../components/Tables/ProductTable/ProductTable";
import BasicModal from "../../components/Modals/BasicModal";
import DownloadCSVBtn from "../../components/Buttons/DownloadCSVBtn";
import { useReactToPrint } from "react-to-print";
import { BarcodePrint } from "../../components/BarcodePrint";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Print } from "@material-ui/icons";
import DisplayTable from "../../components/Tables/DisplayTable";
import axios from "axios";

export default function VCPriority() {
  const url = `${process.env.REACT_APP_API_URL}sales-vc-priority/`;
  const barcodeRef = useRef(null);
  const componentRef = useRef();
  const [fulfillmentData, setFulfillmentData] = useState(null);
  const [selectedASIN, setSelectedASIN] = useState(null);
  const [headers, setHeaders] = useState({
    arn_number: null,
    shipment_mode: null,
    carrier_name: null,
  });

  const fields = {
    arn_number: {
      required: true,
      id: "arn_number",
      label: "ARN Number",
      header: "ARN Number",
      type: "function",
      function: openArnModal,
      ip: { maxLength: 255 },
    },
    shipment_mode: {
      id: "shipment_mode",
      label: "Shipment Mode",
      header: "Shipment Mode",
      type: "text",
      ip: { maxLength: 255 },
    },
    carrier_name: {
      id: "carrier_name",
      label: "Carrier Name",
      header: "Carrier Name",
      type: "text",
      ip: { maxLength: 255 },
    },
    po_number: {
      required: true,
      id: "po_number",
      label: "PO Number",
      header: "PO Number",
      type: "text",
      ip: { maxLength: 255 },
    },
    window_start: {
      id: "window_start",
      label: "Window Start",
      header: "Window Start",
      type: "date",
      format: "MM/DD/YYYY",
      ip: { maxLength: 255 },
    },
    po_state: {
      required: true,
      id: "po_state",
      label: "PO State",
      header: "PO State",
      type: "text",
      ip: { maxLength: 255 },
    },
    priority: {
      id: "priority",
      label: "Priority",
      header: "Priority",
      type: "numeric",
      decimals: 0,
    },
    priority_score: {
      id: "priority_score",
      label: "Priority Score",
      header: "Priority Score",
      type: "numeric",
      decimals: 1,
    },
    age_score: {
      id: "age_score",
      label: "Age Score",
      header: "Age Score",
      type: "numeric",
      decimals: 0,
    },
    value_score: {
      id: "value_score",
      label: "Value Score",
      header: "Value Score",
      type: "numeric",
      decimals: 1,
    },
    ship_score: {
      id: "ship_score",
      label: "Ship Score",
      header: "Ship Score",
      type: "numeric",
      decimals: 0,
    },
    prep_time: {
      id: "prep_time",
      label: "Prep Time(sec)",
      header: "Prep Time(sec)",
      type: "numeric",
      decimals: 0,
    },
    fillable_percent: {
      id: "fillable_percent",
      label: "Fillable Percent",
      header: "Fillable Percent",
      type: "numeric",
      decimals: 2,
    },
    onhand_shippable_value: {
      id: "onhand_shippable_value",
      label: "Onhand Shippable Value",
      header: "Onhand Shippable Value",
      type: "numeric",
      decimals: 2,
    },
  };
  const fulfillment = {
    po_number: {
      required: true,
      id: "po_number",
      label: "PO Number",
      header: "PO Number",
      type: "text",
      ip: { maxLength: 255 },
    },
    window_start: {
      id: "window_start",
      label: "Window Start",
      header: "Window Start",
      type: "date",
      format: "MM/DD/YYYY",
      ip: { maxLength: 255 },
    },
    asin: {
      required: true,
      label: "ASIN",
      anchor: "https://www.amazon.com/dp/",
      tag: "asin",
      id: "asin",
      header: "ASIN",
      type: "anchor",
      ip: { maxLength: 255 },
    },
    accepted_qty: {
      required: true,
      id: "accepted_qty",
      label: "Accepted Qty",
      header: "Accepted Qty",
      type: "numeric",
      decimals: 0,
      ip: { maxLength: 255 },
    },
    shipped_qty: {
      required: true,
      id: "shipped_qty",
      label: "Shipped Qty",
      header: "Shipped Qty",
      type: "numeric",
      decimals: 0,
      ip: { maxLength: 255 },
    },
    onhand_qty: {
      required: true,
      id: "onhand_qty",
      label: "Onhand Qty",
      header: "Onhand Qty",
      type: "numeric",
      decimals: 0,
      ip: { maxLength: 255 },
    },
    onorder_qty: {
      required: true,
      id: "onorder_qty",
      label: "Onorder Qty",
      header: "Onorder Qty",
      type: "numeric",
      decimals: 0,
      ip: { maxLength: 255 },
    },
    vc_prep: {
      required: true,
      id: "vc_prep",
      label: "VC Prep",
      header: "VC Prep",
      type: "text",
      ip: { maxLength: 255 },
    },
    base_sku: {
      required: true,
      id: "base_sku",
      label: "Base Sku",
      header: "Base Sku",
      type: "text",
      ip: { maxLength: 255 },
    },
    vc_title: {
      required: true,
      id: "vc_title",
      label: "VC Title",
      header: "VC Title",
      type: "text",
      ip: { maxLength: 255 },
    },
    total_cartons: {
      required: true,
      id: "total_cartons",
      label: "Total Cartons",
      header: "Total Cartons",
      type: "numeric",
      decimals: 0,
      ip: { maxLength: 5 },
    },
    qty_per_base: {
      required: true,
      id: "qty_per_base",
      label: "Qty per Base",
      header: "Qty per Base",
      type: "numeric",
      decimals: 0,
      ip: { maxLength: 5 },
    },
    kit: {
      id: "kit",
      label: "Kit",
      header: "Kit",
      type: "bool",
    },
    flag_packed: {
      id: "flag_packed",
      label: "Packed",
      header: "Packed",
      type: "bool",
    },
    view: {
      id: "asin",
      type: "button",
      header: "View Barcode",
      function: openBarcode,
      label: (
        <span class="svg-icon svg-icon-primary svg-icon-2x">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path
                d="M13,5 L15,5 L15,20 L13,20 L13,5 Z M5,5 L5,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,6 C2,5.44771525 2.44771525,5 3,5 L5,5 Z M16,5 L18,5 L18,20 L16,20 L16,5 Z M20,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,19 C22,19.5522847 21.5522847,20 21,20 L20,20 L20,5 Z"
                fill="#000000"
              />
              <polygon
                fill="#000000"
                opacity="0.3"
                points="9 5 9 20 7 20 7 5"
              />
            </g>
          </svg>
        </span>
      ),
    },
  };

  function openArnModal(arn) {
    axios
      .get(`${process.env.REACT_APP_API_URL}sales-vc-po-shipments/?arn=${arn}`)
      .then(({ data }) => {
        setFulfillmentData(data);
        let first = data[0],
          newHeaders = {};

        for (const key in headers) {
          newHeaders[key] = first[key];
        }
        setHeaders(newHeaders);
      })
      .catch(function(error) {
        console.log(error);
        setFulfillmentData([]);
        let newHeaders = {};
        for (const key in headers) {
          newHeaders[key] = "error";
        }
        setHeaders(newHeaders);
      });
  }

  function openBarcode(row) {
    setSelectedASIN(row.asin);
  }

  function closeModals() {
    setFulfillmentData(null);
    setSelectedASIN(null);
  }

  function handleBack(event) {
    setSelectedASIN(null);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <DisplayTable
            data={fulfillmentData ? fulfillmentData : []}
            columns={Object.values(fulfillment).slice(0, 12)}
          />
        </div>
      </div>
      <BasicModal
        title={
          <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-center">
            <span>
              <span className="font-weight-bolder">ARN -</span>{" "}
              {headers.arn_number}
            </span>
            <span>
              <span className="font-weight-bolder">Carrier Name -</span>{" "}
              {headers.carrier_name}
            </span>
            <span>
              <span className="font-weight-bolder">Shipment Mode -</span>{" "}
              {headers.shipment_mode}
            </span>
            <span>
              <Tooltip title="Print Table" className="mr-1">
                <IconButton aria-label="Print Table" onClick={handlePrint}>
                  <Print />
                </IconButton>
              </Tooltip>
              <DownloadCSVBtn
                name={headers.arn_number}
                data={fulfillmentData}
              />
            </span>
          </div>
        }
        onHide={closeModals}
        size="xl"
        body={
          selectedASIN !== null ? (
            <BarcodePrint ref={barcodeRef} asin={selectedASIN} />
          ) : (
            <ProductTable
              small
              height={400}
              data={fulfillmentData}
              rows={25}
              noToolbar
              columns={Object.values(fulfillment)}
            />
          )
        }
        show={fulfillmentData !== null}
        handleCancel={selectedASIN !== null ? handleBack : closeModals}
        cancelTitle={selectedASIN !== null ? "Back" : "Cancel"}
        noConfirm
      />

      <PageComponent
        tableTitle="VC Priority"
        batch
        url={url}
        tableColumns={Object.values(fields)}
        orderBy={{ id: "priority", type: "numeric" }}
      />
    </>
  );
}
