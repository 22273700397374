/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import BasicSnackBar from '../../../../../app/components/Alerts/BasicSnackBar'
import axios from 'axios'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FileIcon from '@material-ui/icons/InsertDriveFile';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function MyCartDropdown() {
  const [selectedScript, setSelectedScript] = useState({name: '', script: ''})
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [isError, setIsError] = useState(false);
  const bgImage = 'https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/misc/bg-1.jpg'

  // make the scripts object
  //each module on the aside will have a list of scripts
  const modules = [
    {
      module : 'Catalog',
      script_list: [
        {name: "ASIN Margin", script: 'catalog_asin_margin_maker'}
      ],
      icon: (          
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect x="0" y="0" width="24" height="24" />
            <path d="M13.6855025,18.7082217 C15.9113859,17.8189707 18.682885,17.2495635 22,17 C22,16.9325178 22,13.1012863 22,5.50630526 L21.9999762,5.50630526 C21.9999762,5.23017604 21.7761292,5.00632908 21.5,5.00632908 C21.4957817,5.00632908 21.4915635,5.00638247 21.4873465,5.00648922 C18.658231,5.07811173 15.8291155,5.74261533 13,7 C13,7.04449645 13,10.79246 13,18.2438906 L12.9999854,18.2438906 C12.9999854,18.520041 13.2238496,18.7439052 13.5,18.7439052 C13.5635398,18.7439052 13.6264972,18.7317946 13.6855025,18.7082217 Z" fill="#000000" />
            <path d="M10.3144829,18.7082217 C8.08859955,17.8189707 5.31710038,17.2495635 1.99998542,17 C1.99998542,16.9325178 1.99998542,13.1012863 1.99998542,5.50630526 L2.00000925,5.50630526 C2.00000925,5.23017604 2.22385621,5.00632908 2.49998542,5.00632908 C2.50420375,5.00632908 2.5084219,5.00638247 2.51263888,5.00648922 C5.34175439,5.07811173 8.17086991,5.74261533 10.9999854,7 C10.9999854,7.04449645 10.9999854,10.79246 10.9999854,18.2438906 L11,18.2438906 C11,18.520041 10.7761358,18.7439052 10.4999854,18.7439052 C10.4364457,18.7439052 10.3734882,18.7317946 10.3144829,18.7082217 Z" fill="#000000" opacity="0.3" />
          </g>
        </svg>
      )
    },
    {
      module : 'FBA Inventory',
      script_list: [
        {name: "FBA ASIN Priority", script: 'inventory_fba_priority_maker'}
      ],
      icon: (          
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000" />
                <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) " x="16.3255682" y="2.94551858" width="3" height="18" rx="1" />
              </g>
            </svg>
      )
    },
    {
      module : 'VC Orders',
      script_list: [
        {name: "VC Priority", script: 'sales_vc_priority_maker'}
      ],
      icon: (          
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect x="0" y="0" width="24" height="24" />
            <path d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z" fill="#000000" />
            <path d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z" fill="#000000" opacity="0.3" />
          </g>
        </svg>
      )
    },

  ]

  //click event function for the Run Script button
  function runScript(){
    setLoading(true)
    //send the script name to the logger-commands endpoint
    axios.post(`${process.env.REACT_APP_API_URL}logger-commands/`,{script: selectedScript.script})
    .then(({data}) => {
      setLoading(false)
      setMessage(data.message)
      setSelectedScript({name: '', script: ''})
    }).catch(function(error){
      setLoading(false)
      setMessage(error.error)
      setIsError(true)
      setSelectedScript({name: '', script: ''})
      console.log(error)
    })
  }

  //function for closing the snackbar alert
  function closeAlert() {
    setMessage(null)
    setIsError(false)
  }  


  return (
    <>
      <BasicSnackBar
        open={message !== null} 
        variant={isError? 'error': 'success'} 
        message={message} 
        handleClose={closeAlert}
      />
    
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-my-cart-toggle"
        >
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="mycart-panel-tooltip">Run Scripts</Tooltip>}
          >
            <div className="btn btn-icon btn-clean btn-lg mr-1">
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M16.3740377,19.9389434 L22.2226499,11.1660251 C22.4524142,10.8213786 22.3592838,10.3557266 22.0146373,10.1259623 C21.8914367,10.0438285 21.7466809,10 21.5986122,10 L17,10 L17,4.47708173 C17,4.06286817 16.6642136,3.72708173 16.25,3.72708173 C15.9992351,3.72708173 15.7650616,3.85240758 15.6259623,4.06105658 L9.7773501,12.8339749 C9.54758575,13.1786214 9.64071616,13.6442734 9.98536267,13.8740377 C10.1085633,13.9561715 10.2533191,14 10.4013878,14 L15,14 L15,19.5229183 C15,19.9371318 15.3357864,20.2729183 15.75,20.2729183 C16.0007649,20.2729183 16.2349384,20.1475924 16.3740377,19.9389434 Z" fill="#000000"/>
                      <path d="M4.5,5 L9.5,5 C10.3284271,5 11,5.67157288 11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L4.5,8 C3.67157288,8 3,7.32842712 3,6.5 C3,5.67157288 3.67157288,5 4.5,5 Z M4.5,17 L9.5,17 C10.3284271,17 11,17.6715729 11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L4.5,20 C3.67157288,20 3,19.3284271 3,18.5 C3,17.6715729 3.67157288,17 4.5,17 Z M2.5,11 L6.5,11 C7.32842712,11 8,11.6715729 8,12.5 C8,13.3284271 7.32842712,14 6.5,14 L2.5,14 C1.67157288,14 1,13.3284271 1,12.5 C1,11.6715729 1.67157288,11 2.5,11 Z" fill="#000000" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
          <form>
            <div
              className="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
                <i className="flaticon2-files-and-folders text-success"></i>
              </span>
              <h4 className="text-white m-0 flex-grow-1 mr-3">My Scripts</h4>
            </div>
            {
              loading?
                <div className="w-100 d-flex flex-column flex-center p-5 ">
                  <h5>Running Script...</h5>
                  <div><CircularProgress color="secondary"/></div>
                </div>
                :
              
              <PerfectScrollbar
                options={perfectScrollbarOptions}
                className="scroll"
                style={{ maxHeight: "35vh", position: "relative" }}
              >
                {modules.map(mod =>{  

                  return(
                    <>
                      <div className="d-flex align-items-center justify-content-between p-8">
                        <div className="d-flex flex-column mr-2">
                          <div className="font-weight-bold text-dark-75 font-size-lg ">
                            {mod.module}
                          </div>
                          <span className="text-muted">{mod.script_list.length + " total scripts"}</span>
                          <div className="d-flex align-items-center mt-2">
                            <List dense={true}>
                              {mod.script_list.map(script => {
                                return(
                                  <ListItem >
                                    <ListItemIcon>
                                      <FileIcon />
                                    </ListItemIcon>
                                    <a
                                      onClick={() => setSelectedScript(script)}
                                      className="font-weight-bold mr-1 text-dark-75 font-size-2 text-hover-primary"
                                    >

                                    {script.name}
                                    </a>
 
                                  </ListItem>
                                )
                            })}
                            </List>
                          </div>
                        </div>
                        <span className="symbol symbol-70 flex-shrink-0 ">
                          <span className="svg-icon svg-icon-3x">
                            {mod.icon}
                          </span>
                        </span>

                      </div>

                      <div className="separator separator-solid"></div>              
                    </>
                  )
                })}    
              </PerfectScrollbar>
            }
            <div className="p-8">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <span className='font-weight-bolder font-size-xl'>
                  {selectedScript.name? selectedScript.name: 'No Script Selected'}
                </span>
                <button
                  type="button"
                  className="btn btn-primary text-weight-bold"
                  disabled={loading || !selectedScript.name}
                  onClick={runScript}
                >
                  Run Script
                </button>
              </div>
            </div>
          </form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
