import React, { useState, useEffect } from "react";
import PageComponent from "../../components/PageComponent";

export default function ItemPrep() {
  const url = `${process.env.REACT_APP_API_URL}sales-vc-cancel/`;

  return (
    <PageComponent
      tableTitle="VC Cancel List"
      url={url}
      // deleteItems
      batch
      orderBy={{ id: "cancel_date", type: "date" }}
      // addItems
      // checkbox
      // bulkAdd
      // bulkEdit
      // import
      requiredCSVColumns={["po_number", "asin"]}
      formInputs={[
        {
          required: true,
          id: "asin",
          label: "ASIN",
          ip: { maxLength: 15 },
        },
        {
          required: true,
          id: "po_number",
          label: "PO Number",
          ip: { maxLength: 20 },
        },
      ]}
      tableColumns={[
        {
          anchor: "https://www.amazon.com/dp/",
          tag: "asin",
          id: "asin",
          header: "ASIN",
          type: "anchor",
        },
        {
          id: "po_number",
          header: "PO Number",
          type: "text",
        },
        {
          id: "cancel_date",
          header: "Cancel Date",
          type: "date",
          format: "MM/DD/YYYY",
          ip: { maxLength: 255 },
        },
        {
          id: "flag_shipped",
          header: "Shipped",
          type: "bool",
        },
        {
          id: "flag_asn",
          header: "ASN",
          type: "bool",
        },
        {
          id: "shipped_qty",
          header: "Qty Shipped",
          type: "numeric",
          decimals: 0,
        },
        {
          id: "date_shipped",
          header: "Ship Date",
          type: "date",
          format: "MM/DD/YYYY",
          ip: { maxLength: 255 },
        },
        {
          id: "qty_ordered",
          header: "Qty Ordered",
          type: "numeric",
          decimals: 0,
        },
        {
          id: "qty_received",
          header: "Qty Rcvd",
          type: "numeric",
          decimals: 0,
        },
        {
          id: "qty_outstanding",
          header: "Qty Oustanding",
          type: "numeric",
          decimals: 0,
        },
        {
          id: "qty_cancelled",
          header: "Qty Cancelled",
          type: "numeric",
          decimals: 0,
        },
        {
          id: "vc_sku",
          header: "SKU",
          type: "text",
        },
        {
          id: "vc_title",
          header: "Title",
          type: "text",
        },
        {
          id: "window_start",
          header: "Window Start",
          type: "date",
          format: "MM/DD/YYYY",
          ip: { maxLength: 255 },
        },
        {
          id: "flag_packed",
          header: "Packed",
          type: "bool",
        },
        
        {
          id: "flag_invoiced",
          header: "Invoiced",
          type: "bool",
        },
      ]}
    />
  );
}




