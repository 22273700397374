import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import CatalogPage from "./pages/CatalogPage";
import { BuilderPage } from "./pages/BuilderPage";
import DashboardPage from "./pages/DashboardPage";
import InventoryPage from "./pages/InventoryPage";
import ContentPage from "./pages/ContentPage";
import SalesPage from "./pages/SalesPage";
import PricingPage from "./pages/PricingPage";
import OpportunitesPage from "./pages/OpportunitesPages";
import FeedbackPage from "./pages/FeedbackPage";
import LocalInventoryPage from "./pages/LocalInventory";
import FinancialsPage from './pages/FinancialsPage';
import InstockPage from "./pages/InstockPage";
import VCOrdersPage from "./pages/VCOrdersPage";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <Route path="/catalog" component={CatalogPage} />
        <Route path="/inventory" component={InventoryPage} />
        <Route path="/content" component={ContentPage} />
        <Route path="/sales" component={SalesPage} />
        <Route path="/pricing" component={PricingPage} />
        <Route path="/opportunities" component={OpportunitesPage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/feedback" component={FeedbackPage} />
        <Route path="/local-inventory" component={LocalInventoryPage} />
        <Route path="/financials" component={FinancialsPage} />
        <Route path="/instock" component={InstockPage} />
        <Route path="/vc-orders" component={VCOrdersPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
