// Child component of Filters
// Rendered if a column in the tableColumns prop has a type of "date"

import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';


export default function DateFilter(props){
    const ops = ['On', 'Before', 'After', 'On or Before', 'On or After', 'Not On'];

    return(
        <div className="d-flex flex-row" >
          
        <FormControl className='w-50 mr-1' >     
          <Select
            value={props.value.op? props.value.op: ''}
            name="Operator"
            onChange={props.onChange(props.id, 'op')}
            displayEmpty
          >
            <MenuItem value="" disabled >
              <span className='text-muted'>Operators</span>
            </MenuItem>
            {ops.map((op, index) =>{
              return(
                <MenuItem key={index} value={op}>{op}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
          <TextField        
            id="standard-number"
            placeholder="Enter Number"
            type='date'
            value={props.value.date? props.value.date: ''}
            onChange={props.onChange(props.id, 'date')}
            className='w-50 ml-1'
           
          />
        </div>
    )
}