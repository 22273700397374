import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function FBAAsinPriority() {
    const url = `${process.env.REACT_APP_API_URL}inventory-fba-asin-priority/`

    return(
        <PageComponent
            tableTitle="FBA ASIN Priority"
            url={url}
            orderBy={{id:'total_score', type: 'numeric'}}
            order={"desc"}
            
            tableColumns={[
                {
                    anchor: 'https://www.amazon.com/dp/',
                    tag: 'asin',
                    id: 'asin',
                    header: 'ASIN',
                    type: 'anchor'
                },
                {
                    id: 'sku',
                    header: 'SKU',
                    type: 'text',
                },
                {
                    id: 'brand',
                    header: 'Brand',
                    type: 'text',
                },
                {
                    id: 'item_name',
                    header: 'Item Name',
                    type: 'text',
                },
                {
                    id: 'kit',
                    header: 'Kit',
                    type: 'bool',
                },
                {
                    id: 'woc_score',
                    header: 'WOC Score',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'rev_score',
                    header: 'REV Score',
                    type: 'numeric',
                    decimals: 1
                },
                {
                    id: 'total_score',
                    header: 'Total Score',
                    type: 'numeric',
                    decimals: 1
                },
                {
                    id: 'send_qty',
                    header: 'Send Qty',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 't90d_rev',
                    header: 'T90d REV',
                    type: 'numeric',
                    decimals: 2
                },
                {
                    id: 't90d_units_per_day',
                    header: 'T90d Units per Day',
                    type: 'numeric',
                    decimals: 2
                },
                {
                    id: 'fba_current_woc',
                    header: 'FBA Current WOC',
                    type: 'numeric',
                    decimals: 2
                },
                {
                    id: 'avail_kit_qty_asin',
                    header: 'Avail Kit Qty',
                    type: 'numeric',
                    decimals: 0
                },
            ]}
        />
    )
}