import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import CombinedReorders from "../modules/Instock/CombinedReorder";
import StagnantItems from "../modules/Instock/StagnantItems";
import VendorDetails from "../modules/Instock/VendorDetails";
import Inbound from "../modules/Instock/Inbound"
import ShortShipped from "../modules/Instock/ShortShipped";
import PO from "../modules/Instock/PO";
import Invoices from "../modules/Financials/Invoices";

export default function InstockPage() {

    return (
        <Switch>
            <Redirect
                exact={true}
                from="/instock"
                to="/instock/combined-reorders"
            />

            <ContentRoute
                path="/instock/combined-reorders"
                component={CombinedReorders}
            />

            <ContentRoute
                path="/instock/vendor-details"
                component={VendorDetails}
            />
            <ContentRoute
                path="/instock/stagnant-items"
                component={StagnantItems}
            />
            <ContentRoute
                path="/instock/inbound"
                component={Inbound}
            />
            <ContentRoute
                path="/instock/purchase-orders"
                component={PO}
            />
            <ContentRoute
                path="/instock/short-shipped"
                component={ShortShipped}
            />

            <ContentRoute
                path="/instock/vendor-invoices" 
                component={Invoices} 
            />

            
        </Switch>
    );
}