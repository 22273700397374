import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";

export default function SingleDataIconTile(props) {
    return (
        <div
            class={"card card-custom border-secondary gutter-b"}
            style={{ height: 150 }}
        >
            <div class="card-body">
                <span class="svg-icon svg-icon-3x svg-icon-success ml-n2">
                    {props.icon}
                </span>

                <div class={"text-dark font-weight-bolder font-size-h2 mt-3"}>
                    {props.tileValue !== null ? (
                        props.tileValue.toLocaleString()
                    ) : (
                        <CircularProgress color="text-muted" size="20px" />
                    )}
                </div>

                <a
                    href="#"
                    class={"text-muted font-weight-bold font-size-lg mt-1"}
                >
                    {props.tileTitle}
                </a>
            </div>
        </div>
    );
}
