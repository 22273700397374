
import { useBarcode } from 'react-barcodes';
import React, { useState, useEffect } from 'react';

export const BarcodePrint = React.forwardRef((props, ref) => {
    useEffect(() => {
        
    }, [props.asin]);
    const { inputRef } = useBarcode({
        value: 	props.asin,
    });
    return (
      <div ref={ref} className='w-100 h-100 d-flex flex-center'>
          <img ref={inputRef} />
      </div>
    );
  });