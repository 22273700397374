import React, { useEffect, useState } from 'react';
import PropTypes, { array } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import ProductTableHead from './ProductTableHeader';
import ProductTableToolbar from './ProductTableToolbar';
import ProductTableBody from './ProductTableBody';
import BasicModal from '../../Modals/BasicModal';
import ProductTablePagActions from './PoductTablePagActions.js';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Icon, Button, TableContainer, Checkbox, FormControlLabel } from '@material-ui/core';
import VCOrderEditModal from '../../Modals/VCOrderEditModal';
import Filters from './Filters/Filters';
import POModal from '../../Modals/POModal'
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';


ProductTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

ProductTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

ProductTablePagActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
 
  },
  table: {

  },
  tableWrapper: {
    overflow: 'hidden',
    width: '100%'
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 300px)',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'calc(100vh - 130px)',

    }

  },
  smallTableContainer: {
    maxHeight: 235
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 100
  },
  actions: {
    marginLeft: '35%'
  }
}));

export default function ProductTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState({id: null, type: null});
  const [selected, setSelected] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([])
  const [selectedPO, setSelectedPO] = React.useState(null)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(0);
  const [colNum, setCols] = React.useState(null);
  const [itemCount, setCount] = React.useState(null);//item count for the table
  const [tableItems, setTableItems] = React.useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);
  const [editRow, setEditRow] = React.useState(null);
  const [edits, setEdits] = React.useState({});
  const [vcRow, setVCRow] = React.useState(null);
  const [saveConfirmation, setSaveConfirmation] = React.useState(false);
  const [columnModal, setColumnModal] = React.useState(false)
  const [filterCount, setFilterCount] = React.useState(0)
  const [showFilter, setShowFilter] = React.useState(false);
  const [filters, setFilters] = React.useState({});
  const [keyword, setKeyword] = React.useState(null);
  const [searchVal, setSearchVal] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [bulkEditValues, setBulkEditValues] = React.useState({})
  const [showColumns, setShowColumns] = React.useState([])
  const [checkedColumns, setCheckedColumns] = React.useState([])
  const [batch, setBatch] = useState(false)



  //*******************************EFFECTS******************************* */
  useEffect(() => {
    if (props.data) {
      //set the batch date
      if(props.batch){
        setBatch(moment(props.data[0].batch_date).format('MM/DD/YYYY @h:mma'))
      }

      setCount(props.count);
      //add IDs to each row if there is none
      if(props.addIds){
        let data = props.data
        data.map((row, index) => {
          row['id'] = index
        })
        setTableItems(data);
      }else{
        setTableItems(props.data);
      }
      
      setLoading(false);
      
      if(props.search){
        doSearch(props.search, props.data)
      }else{
        if (filterCount > 0) {
          handleFilter()
        } else {
          resetFilters()
        }
  
        if (searchVal) {
          doSearch(searchVal, props.data)
        }
      }

      initializeColumnChecklist()
    }
    

    setPage(0)
    if (props.checkbox) {
      setCols(props.columns.length + 1);
    } else {
      setCols(props.columns.length);
    }

  }, [props.data]);

  useEffect(() => {
    setRowsPerPage(props.rows);
    if(props.orderBy){
      setOrderBy(props.orderBy)
    }
    if(props.order){
      setOrder(props.order)
    }
  }, [])

  //rerenders the component when the table items are changed
  useEffect(() => {
    if (tableItems) { setCount(tableItems.length) }
  }, [tableItems, deleteConfirmation, filters, edits]);

  useEffect(() => {
    if(props.count){
      setCount(props.count)
    }
  }, [props.count]);

  //effect for storing the getting the whole row of the selected id
  //selectedRows is used in bulk edits
  useEffect(() => {
    if (tableItems) {
      let rows = []
      selected.map(id => {
        tableItems.map(item => {
          if (item.id === id) {
            rows.push(item);
          }
        })
      })
      setSelectedRows(rows)
    }
  }, [selected]);

  //******************************METHODS************************************ */
  function initializeColumnChecklist(){
    //set the columns to show to all columns, and add column ids to checklist
    setShowColumns(props.columns)
    let checkList = []
    props.columns.map(col =>{
      checkList.push(col.id)
    })
    setCheckedColumns(checkList)
  }
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = tableItems.map(n => n.id).slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
      setSelected(newSelecteds)
      setSelectedRows(tableItems.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage))
      return;
    }
    setSelected([]);
  }

  function handleClick(event, row, index) {
    if(props.checkbox){
      console.log(props.checkbox)
      let name = row.id
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
  
      setSelected(newSelected);
    }

    if(props.rowSelect){
      props.rowSelect(row)
    }

  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    let newRows = event.target.value
    let newEmpty = newRows - Math.min(newRows, itemCount - page * newRows);

    if (newRows <= newEmpty) {
      setPage(0)
    }
    setRowsPerPage(+event.target.value);
  }


  //the following functions are for handling the opening and closing of the modals
  function showModal() {
    setDeleteConfirmation(true);
  }
  const showEditModal = (row) => event => {
    setEditRow(row)
  }

  const showVCEditModal = (row) => event => {
    setVCRow(row)
  }
  function showSaveModal() {

    if (props.bulkEdits) {
      let newEdit = {}
      selectedRows.map(row => {
        let values = { ...bulkEditValues }
        values['id'] = row.id

        //check for the match values
        if (values['match']) {
          //match the value for the id with the value for the matchID
          let id = values.match[0]
          let matchID = values.match[1]
          values[id] = row[matchID]
          //delete the match key from the object
          delete values['match']
        }
        newEdit[row.id] = values
      })
      setEdits(newEdit)
    }
    setSaveConfirmation(true)
  }
  function showColumnModal(value) {
    setSelectedPO(value)
    setColumnModal(true)
  }
  function closeModal() {
    setSaveConfirmation(false)

    setColumnModal(false)
    setSelectedPO(null)
    if (!props.resetSave || editRow !== null) {
      setEdits({})
    }
    setEditRow(null)
    setVCRow(null)
    setDeleteConfirmation(false);
  }
  function handleColumnModalSuccess(message) {
    props.displayMessage(message)
    closeModal()
  }

  //-------------EDIT FUNCTIONS START---------------------

  //function for handling edits of a item
  function handleEdit() {
    props.handleEdit(editRow, edits)
    closeModal();
    setSelectedRows([])
    setSelected([])
    setEdits({})
  }

  //function for changing the value of the bulk edit selected
  const handleBulkEditChange = (event, id, show) => {
    //check if match alreadey exists, then toggle
    if (id === 'match' && bulkEditValues['match']) {
      let newValue = bulkEditValues
      delete newValue['match']
      setBulkEditValues(newValue); // update the value in the toolbar
    }
    else if (id === 'flag_shipped') { //check for flag_shipped and set flag_packed if so
      let newValue = { ...bulkEditValues }
      newValue[id] = event.target.value
      newValue['flag_packed'] = event.target.value
      setBulkEditValues(newValue); // update the value in the toolbar
    }
    else {
      setBulkEditValues({ ...bulkEditValues, [id]: event.target.value }); // update the value in the toolbar
    }

    if (show) { // show the other bulk edit if it depends on this value being selected
      show.map(index => {
        if (props.bulkEdits[index].show === id) {
          props.bulkEdits[index].hidden = false;
        }
        else {
          props.bulkEdits[index].hidden = true;
        }
      })
    }
  }

  //function for handling everytime a user changes a field in the edit modal
  const handleEditChanges = id => event => {
    let newEdits = edits
    if(event.target.value === ""){
      newEdits[id] = null
    }else{
      newEdits[id] = event.target.value
    }
    
    setEdits(newEdits)
  }

  //function for handling the edits of bools, just toggles between true or false
  const handleBoolEdits = id => event => {
    let newEdits = edits, value = edits[id]
    if (typeof value === 'undefined') {
      value = editRow[id]
    }

    if (value) {
      newEdits[id] = false
    } else {
      newEdits[id] = true
    }

    setEdits(newEdits)
  }
  //-------------EDIT FUNCTIONS END---------------------


  //-------------DELETE FUNCTIONS START---------------------
  //function for handling the deletion of a product
  function handleDelete() {
    setPage(0);
    closeModal();
    props.doDelete(0, selected.slice(0, 20), selected);
    setSelected([]);
    setSelectedRows([])
  }

  //function for handling the click of the delete button in the table body
  function handleDeleteIcon(id) {
    setSelected([id])
    showModal()
  }
  //-------------DELETE FUNCTIONS END---------------------


  //-------------FILTER FUNCTIONS START---------------------

  //function for setting all of the filter fields to empty
  function clearFilters() {
    resetFilters()
    if (searchVal) {
      doSearch(searchVal, props.data);
    }
    else {
      setTableItems(props.data)
    }
  }

  //helper function for clearFilters
  function resetFilters() {
    let newFilter = {}
    props.columns.map(col => {
      if (col.type === 'numeric') {
        newFilter[col.id] = { number: '', op: '' }
      }
      else if (col.type === 'date') {
        newFilter[col.id] = { date: '', op: '' }
      }
      else {
        newFilter[col.id] = ''
      }
    })
    setFilterCount(0)
    setFilters(newFilter);
  }

  //function for adding and subtracting filters to the table items
  function handleFilter(event) {
    if (event) {
      event.preventDefault()

    }
    setPage(0);
    let newCount = 0, data
    if (!(searchVal || keyword)) {
      data = props.data
    }
    else {
      data = tableItems
    }

    props.columns.map((col, index) => {

      if (filters[col.id] !== null && filters[col.id] !== '') {

        //check if the filter is a number or string
        if (filters[col.id].number && filters[col.id].number !== '') {
          data = filterByNum(data, col.id, filters[col.id])
          newCount++
        }
        else if (filters[col.id].date && filters[col.id].date !== '') {
          data = filterBydate(data, col.id, filters[col.id])
          newCount++
        }
        else if (typeof filters[col.id] === 'string') { // make sure the value is a string
          data = filterByText(data, col.id, filters[col.id])
          newCount++
        }
        else if (typeof filters[col.id] === 'boolean') {

          data = filterbyBool(data, col.id, filters[col.id])
          newCount++
        }

      }
    })

    setTableItems(data)
    setFilterCount(newCount)
  }

  //helper function for handleFilter
  function filterByNum(data, id, filter) {
    let newItems = [];
    let val = parseFloat(filter.number)

    data.map((item) => {
      let column = parseFloat(item[id])
      switch (filter.op) {
        case 'Equals':
          if (column === val) {
            newItems.push(item);
          }
          break;
        case 'Less Than':
          if (column < val) {
            newItems.push(item);
          }
          break;
        case 'Greater Than':
          if (column > val) {
            newItems.push(item);
          }
          break;
        case 'Does Not Equal':
          if (column !== val) {
            newItems.push(item);
          }
          break;
        case 'Less Than or Equal to':
          if (column <= val) {
            newItems.push(item);
          }
          break;
        case 'Greater Than or Equal to':
          if (column >= val) {
            newItems.push(item);
          }
          break;
      }
    })
    return newItems
  }

  //function for filtering by dates
  function filterBydate(data, id, filter) {
    let newItems = [];
    let val = moment(filter.date)

    data.map((item) => {
      let column = moment(item[id])
      switch (filter.op) {
        case 'On':
          if (column.isSame(val, 'day')) {
            newItems.push(item);
          }
          break;
        case 'Before':
          if (column.isBefore(val)) {
            newItems.push(item);
          }
          break;
        case 'After':
          if (column.isAfter(val)) {
            newItems.push(item);
          }
          break;
        case 'On or Before':
          if (column.isSameOrBefore(val)) {
            newItems.push(item);
          }
          break;
        case 'On or After':
          if (column.isSameOrAfter(val)) {
            newItems.push(item);
          }
          break;
        case 'Not On':
          if (!column.isSame(val, 'day')) {
            newItems.push(item);
          }
          break;
      }
    })
    return newItems
  }

  function filterbyBool(data, field, value) {
    let newItems = []

    //loop through the table items to and keep each item that matches the filter value
    data.map(item => {
      if (item[field] === null && !value) {
        newItems.push(item)
      }
      if (item[field] === value) {
        newItems.push(item)
      }
    })

    return newItems
  }

  //function for filtering the with strings
  function filterByText(data, field, value) {
    let newItems = []

    //loop through the table items to and keep each item that matches the filter value
    data.map(item => {
      if (item[field] && item[field].toString().toLowerCase().indexOf(value.toLowerCase()) > -1) {
        newItems.push(item)
      }
    })

    return newItems
  }

  //function for toggling the filter in the table 
  function toggleFilter(event) {

    //change the background color and pulse 
    let filter = document.getElementById(props.title + 'Filter')

    if (showFilter) {
      filter.className = 'MuiButtonBase-root MuiIconButton-root'
      setShowFilter(false)
    }
    else {
      setShowFilter(true)
      filter.className = 'MuiButtonBase-root MuiIconButton-root  bg-light-primary'
    }

    if (filterCount > 0) {
      filter.className += ' pulse pulse-primary'
    }
  }

  //function for storing the changes of each filter
  //is called when the user inputs something into a filter
  const handleFilterChange = id => event => {
    setFilters({ ...filters, [id]: event.target.value });
  }

  //function for handling number/date filters
  //stores in a different way because theres also the operator field
  const handleOpChange = (id, field) => event => {
    let newFilter = filters[id]
    newFilter[field] = event.target.value
    setFilters({ ...filters, [id]: newFilter })
  }
  //----------------FILTER FUNCTIONS END-------------------------


  //----------------SEARCH FUNCTIONS START-----------------------

  //functions for handling search 
  function doSearch(value, data) {
    let newItems
    if (value && value !== "") {
      newItems = [];
      data.map((item) => {
        let match = false;
        showColumns.map((attribute) => {
          if (attribute.id === 'keyword' && props.title !== 'Keywords List') {
            return;
          }
          //compare the search value to each attribute of each item
          if (item[attribute.id] && item[attribute.id].toString().toLowerCase().indexOf(value.toLowerCase()) > -1) {
            match = true
          }
        })

        if (match) {
          newItems.push(item)
        }
      })

      setTableItems(newItems);
    }
    else {
      refresh();
    }
  }

  function handleSearch(value) {
    setPage(0);
    setSearchVal(value);
    doSearch(value, props.data);

  }
  //----------------SEARCH FUNCTIONS END-----------------------

  //function for handling the date picker
  function handlePicker(value) {
    if (value === keyword) {

      return;
    }
    setPage(0);
    resetFilters()
    setSearchVal(null);
    setLoading(true);
    props.handlePicker(value)
    setKeyword(value);
  }

  //function for handling the dropdown select
  function handleDropDown(newKeyword, id) {
    if (newKeyword === keyword) {

      return;
    }
    setPage(0);
    resetFilters()
    setSearchVal(null);
    setLoading(true);
    props.handleKey(newKeyword, id)
    setKeyword(newKeyword);
  }

  //function for handling changes of the select cells
  //when the user changes a select then we store that row with all of the other changed rows
  const handleSelectChange = (row, field) => event => {
    setEdits({ ...edits, [row.id]: event.target.value })
  }

  //function for handling when the user clicks the save button 
  //sends all of the changed selects to the parent component
  function handleSelectSubmit() {
    props.handleSelectSubmit(edits)
    closeModal()
    setEditRow(null)
    reset()
  }

  function handleVCSave(row, values){
    props.handleEdit(row, values)
    reset()
  }

  //click handler for ColumnSelector
  //triggered when a column in the list is checked
  //adds/removes columns from the array of viewable columns
  function handleColumnSelect(value){
    const currentIndex = checkedColumns.indexOf(value.id);
    const newChecked = [...checkedColumns];

    //add/remove col id in the checklist
    if (currentIndex === -1) {
      newChecked.push(value.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    

    let newColumns=[]

    //loop through all of the columns and see if they're in the checkList, then add them to the viewable columns
    props.columns.map(col =>{
      if(newChecked.includes(col.id)){
        newColumns.push(col)
      }
    })

    setCheckedColumns(newChecked);
    setShowColumns(newColumns)
  }

  //sets the table back to the original state
  function refresh() {
    initializeColumnChecklist()
    setOrder('asc')
    setOrderBy({id:null, type: null})
    reset();
    setPage(0);
    resetFilters()
    setSearchVal(null);
    setKeyword(null);
    setTableItems(props.data)
    if (props.handleRefresh) {
      props.handleRefresh();
    }

    //check if the filter menu is open
    let filter = document.getElementById(props.title + 'Filter')
    if (showFilter) {
      filter.className = 'MuiButtonBase-root MuiIconButton-root  bg-light-primary'
    }
    else {
      filter.className = 'MuiButtonBase-root MuiIconButton-root'
    }

  }
  //sets the toolbar back to normal state
  //called when user clicks reset button
  function reset() {

    setSelected([])
    setSelectedRows([])
    setEdits({})
    setBulkEditValues({})

    //loop through the bulk edits to hide certain ones
    if (props.bulkEdits) {
      props.bulkEdits.map((edit, index) => {

        if (typeof edit['hidden'] !== 'undefined') {
          edit.hidden = true
        }
      })
    }
  }


  return (
    <div>
      <BasicModal
        show={deleteConfirmation}
        title="Delete Product Confirmation"
        body={<span>Are you sure you want to delete <span className='text-danger font-size-lg font-weight-bold'>{selected.length}</span> product(s)?</span>}
        handleConfirm={handleDelete}
        handleCancel={closeModal}
        onHide={closeModal}
      />
      <BasicModal
        show={saveConfirmation}
        title="Save Changes Confirmation"
        body={`You have made ${Object.keys(edits).length} changes, are you sure you want to submit them?`}
        handleConfirm={handleSelectSubmit}
        handleCancel={closeModal}
        onHide={closeModal}
      />
      <VCOrderEditModal
        show={vcRow !== null}
        closeModal={closeModal}
        onHide={closeModal}
        row={vcRow}
        handleConfirm={handleVCSave}
      />
      {columnModal &&
        <POModal
          value={selectedPO}
          closeModal={closeModal}
          handleSuccess={handleColumnModalSuccess}
        />
      }

      <BasicModal
        show={editRow !== null}
        size='lg'
        title="Edit"
        confirmTitle='Save Changes'
        body={
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container spacing={2}>
              {props.columns.map((col, index) => {
                let type, props, step
                if (col.noEdit || col.type === 'edit') {
                  return null
                }
                else {

                  switch (col.type) {
                    case 'bool-icon':
                    case 'bool':
                      return (
                        <Grid item xs={4} className="d-flex flex-center align-items-end ">
                          <FormControlLabel
                            label={<div style={{ fontSize: '1rem', color: 'rgba(0, 0, 0, 0.54)' }}>{col.header}</div>}
                            labelPlacement="top"
                            control={
                              <Checkbox
                                key={index}

                                defaultChecked={editRow && editRow[col.id]}
                                className='p-0 mt-2'
                                onChange={handleBoolEdits(col.id)}
                                inputProps={{
                                  'aria-label': 'primary checkbox',
                                }}
                              />
                            }
                          />
                        </Grid>
                      )
                    case 'date':
                      type = 'date'
                      props = { shrink: true }
                      break;
                    default:
                      type = ''
                      break;
                  }
                }
                return (
                  <Grid item xs={4}>
                    <TextField
                      type={type}
                      key={index}
                      step={step}
                      margin="normal"
                      label={col.header}
                      InputLabelProps={props}
                      defaultValue={editRow && editRow[col.id]}
                      onChange={handleEditChanges(col.id)}
                      className='w-100'
                      handle
                    />
                  </Grid>
                );
              })}
            </Grid>
          </form>
        }
        handleConfirm={handleEdit}
        handleCancel={closeModal}
        onHide={closeModal}
      />
      <Paper className={classes.paper}>
        {!props.noToolbar &&
        <ProductTableToolbar
          batch={batch}
          small={props.small}
          title={props.title}
          disableDownload={props.disableDownload}
          requiredHeaders={props.requiredHeaders}
          handleImport={props.handleImport}
          handleOnError={props.handleOnError}
          numSelected={selected.length}
          selectedRows={selectedRows}
          handleDelete={showModal}
          delete={props.deleteItems}
          handleRefresh={refresh}
          searchAsin={handleSearch}
          data={tableItems}
          keywords={props.keywords}
          handleDropDown={handleDropDown}
          filters={filters}
          toggleFilter={toggleFilter}
          handleFilter={handleFilter}
          filterCount={filterCount}
          handleForm={props.handleForm}
          datePicker={props.datePicker}
          handlePicker={handlePicker}
          bulkEdits={props.bulkEdits}
          bulkEditValues={bulkEditValues}
          handleBulkEditChange={handleBulkEditChange}
          handleMultipleEdits={props.handleSelectSubmit}
          showSaveModal={showSaveModal}
          bulkAdd={props.bulkAdd}
          bulkEdit={props.bulkEdit}
          columnSelect={props.columnSelect}
          columns={props.columns}
          handleColumnSelect={handleColumnSelect}
          checkedColumns={checkedColumns}
        />
        }       

        {props.resetSave && !loading &&
          <div className='w-100 d-flex flex-row justify-content-end my-3'>
            <Button disabled={props.bulkEdits ? (Object.keys(bulkEditValues).length === 0) : Object.keys(edits).length === 0} variant="contained" color="default" onClick={reset} className='px-8 ml-3'>
              Reset
              <Icon>clear</Icon>
            </Button>

            <Button disabled={props.bulkEdits ? (Object.keys(bulkEditValues).length === 0) : Object.keys(edits).length === 0} variant="contained" color="secondary" onClick={showSaveModal} className='px-8 ml-2 mr-5'>
              Save
              <Icon>check</Icon>
            </Button>
          </div>
        }

        <Filters
          filters={filters}
          columns={showColumns}
          clearFilters={clearFilters}
          handleFilterChange={handleFilterChange}
          handleOpChange={handleOpChange}
          handleFilter={handleFilter}
          showFilter={showFilter}
        />

        <div className={classes.tableWrapper}>
          <TableContainer className={!props.small ? classes.tableContainer : classes.smallTableContainer} style={props.height && {maxHeight: props.height}}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size='small'
              stickyHeader
            >
              <ProductTableHead
                mode={props.mode}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={itemCount}
                columns={showColumns}
                checkbox={props.checkbox}
                rowsPerPage={tableItems && tableItems.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).length}
              />
              <ProductTableBody
                tableItems={tableItems}
                small={props.small}
                loading={loading}
                colNum={colNum}
                page={page}
                rowsPerPage={rowsPerPage}
                itemCount={itemCount}
                order={order}
                orderBy={orderBy}
                keyword={keyword}
                columns={showColumns}
                edits={edits}
                datePicker={props.datePicker}
                field={props.field}
                checkbox={props.checkbox}
                selected={selected}
                getRowStyle={props.getRowStyle}
                showEditModal={showEditModal}
                showVCEditModal={showVCEditModal}
                handleClick={handleClick}
                handleDeleteIcon={handleDeleteIcon}
                handleSelectChange={handleSelectChange}
                showColumnModal={showColumnModal}
              />
            </Table>
          </TableContainer>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
          ActionsComponent={props.small ? TablePaginationActions : ProductTablePagActions}
          component='div'
          count={itemCount}
          colSpan={10}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'Rows per page' },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
