import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Download from '@material-ui/icons/GetApp';
import {CSVDownloader} from 'react-papaparse'
import { CircularProgress } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
      backgroundColor: '#EFEFEF',
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
      },
  }));


export default function DownloadCSVBtn(props){
    const classes = useStyles();
  
    return(  
        props.disabled?
            <Tooltip title="Download CSV" className="ml-1">
            <IconButton aria-label="Download CSV" disabled >
                <CircularProgress size={20} style={{zIndex: 3, position: 'relative'}}/>
   
            </IconButton>
        </Tooltip>
        :
        <CSVDownloader
            data={props.data}
            filename={props.name}
            bom={true}     
        >
            <Tooltip title="Download CSV" className="ml-1">
                <IconButton aria-label="Download CSV" >
                    <Download/> 
                </IconButton>
            </Tooltip>
        </CSVDownloader>  
        
        
    )
}