import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function Keywords(){
    const base_url = process.env.REACT_APP_API_URL

    const fields={
        keyword: {
            required: true,
            id: 'keyword',
            header: 'Keyword',
            label: 'Keyword',
            type: 'text',
            ip: {maxLength: 100}
        },
        source:{
            required: true,
            id: 'source',
            header: 'Source',
            label: 'Source',
            type: 'text',
            ip: {maxLength: 100}
        },
        active:{
            required: true,
            id: 'active',
            header: 'Active',
            label: 'Active',
            type: 'bool',
        },
        last_scrape_date:{
            noEdit: true,
            id: 'last_scrape_date',
            header: 'Last Scrape Date',
            label: 'Last Scrape Date',
            type: 'date',
            format: 'MM/DD/YYYY',
        },
        //TABLE ONLY-----------
        edit: {
            header: 'Action',
            type: 'edit'
        }
    }

    const requiredKeywords = [
        'keyword',
        'active',
        'source',
    ]



    return(
        <PageComponent
            tableTitle="Keywords List"
            url={base_url+'scraper-keyword-queue/'}
            checkbox
            deleteItems
            addItems
            import
            bulkAdd
            bulkEdit
            requiredEditFields={requiredKeywords}
            requiredCSVColumns={requiredKeywords}
            formInputs={[fields.keyword, fields.source, fields.active, fields.last_scrape_date]}
            tableColumns={[fields.keyword, fields.source, fields.active, fields.last_scrape_date, fields.edit]}
        />
    )
}