import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';
import { UpdateTable } from '../../components/Tables/ProductTable/TableData';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function PcogsDiscrepancy(props){
    const history = useHistory()
    const base_url = process.env.REACT_APP_API_URL

    const fields={
        base_sku: {
            id: 'base_sku',
            header: 'Base_sku',
            type: 'text',
        },
        brand: {
            id: 'brand',
            header: 'Brand',
            type: 'text',
        },
        pcogs: {
            id: 'pcogs',
            header: 'Catalog Pcogs',
            type: 'numeric',
            decimals: 2
        },
        unit_line_price: {
            id: 'unit_line_price',
            header: 'Latest Invoice Price',
            type: 'numeric',
            decimals: 2
        },
        invoice_number:{
            id: 'invoice_number',
            header: 'Invoice Number',
            type: 'function',
            function: props.handleInvoice
        },
        invoice_date:{
            id: 'invoice_date',
            header: 'Invoice Date',
            type: 'date',
            format: 'MM/DD/YYYY'
          
        },
        
        po_number: {
            id: 'po_number',
            header: 'PO Number',
            type: 'function',
            function: sendToPOPage
        },
    }

      //method called when user clicks on a PO number
    //redirects user to the purchase order page and passes the input PO as a state parameter
    function sendToPOPage(po){
        history.push({pathname: '/instock/purchase-orders/', state: po})
    }


    return(
      
            <PageComponent
            tableTitle="PCOGS Discrepancy"
            url={base_url+'financials-pcogs-discrepancy/'}
            tableColumns={Object.values(fields)}
        />
     
        
    )
}