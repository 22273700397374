import React, { useState, useEffect } from "react";
import PageComponent from "../../components/PageComponent";

export default function FBAReturns() {
  const base_url = process.env.REACT_APP_API_URL;

  const fields = {
    image: {
      id: "image_links",
      header: "",
      type: "image",
    },
    asin: {
      anchor: "https://www.amazon.com/dp/",
      tag: "asin",
      id: "asin",
      header: "ASIN",
      type: "anchor",
    },
    sku: {
      id: "sku",
      header: "SKU",
      type: "text",
    },
    brand: {
      id: "brand",
      header: "Brand",
      type: "text",
    },
    item_name: {
      id: "item_name",
      header: "Item Name",
      type: "text",
    },
    seller_account: {
      id: "seller_account",
      header: "Seller Account",
      type: "text",
    },
    kit: {
      id: "kit",
      type: "bool",
    },
    qty_returned: {
      id: "qty_returned",
      header: "Qty Returned",
      type: "numeric",
      decimals: 0,
    },
    detailed_disposition: {
      id: "detailed_disposition",
      header: "Detailed Disposition",
      type: "text",
    },
    reason: {
      id: "reason",
      header: "Reason",
      type: "text",
    },
    status: {
      id: "status",
      header: "Status",
      type: "text",
    },
    customer_comments: {
      id: "customer_comments",
      header: "Customer Comments",
      type: "text",
    },
    returned_date: {
      id: "returned_date",
      header: "Returned Date",
      type: "date",
      format: "MM/DD/YYYY",
    },
  };

  return (
    <PageComponent
      tableTitle="FBA Returns"
      url={base_url + "inventory-fba-returns/"}
      tableColumns={Object.values(fields)}
    />
  );
}
