import { refreshAuthToken } from "../app/modules/Auth/_redux/authCrud";


export default function setupAxios(axios, store) {

  axios.interceptors.request.use(
    config => {
      const {
        auth: {authToken},
      } = store.getState();
      

      //add the access token as part of the request header
      //if(config.url === "")
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err =>   Promise.reject(err)
  );

  axios.interceptors.response.use( (response) => {
    // Return a successful response back to the calling service
    return response;
  }, (error) => {

    const {
      auth: {refreshToken},
    } = store.getState();

    // Return any error which is not due to authentication back to the calling service
    if(error.response.status === 403){
      
      console.log("FORBIDDEN")
      
    }
    if (axios.isCancel(error) || error.response.status !== 401) {
      console.log("FORBIDDEN")
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }


    // Logout user if token refresh didn't work or user is disabled
    if (error.config.url === 'https://pike-place-dj.herokuapp.com/api/token/refresh/') { 
      console.log("refresh expired")

      store.dispatch({type: "[Logout] Action"});
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    //refresh the token and set the new auth and refresh tokens
    return  refreshAuthToken(refreshToken).then(({data}) => {
      console.log("access expired")
        let authToken = data.access;
        store.dispatch({type: "[Refresh Auth] Action", payload: {authToken}});

        const config = error.config;
        config.headers.Authorization = `Bearer ${authToken}`; 

        // Try request again with new token
        return new Promise((resolve, reject) => {
          axios.request(config).then(response => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          })
        }); 
      })
    .catch((error) => {
      	Promise.reject(error);
      });
  });

}


