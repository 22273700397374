import React, { useState, useEffect } from "react";
import {
  getTableData,
  UpdateTable,
} from "../../components/Tables/ProductTable/TableData";
import PageComponent from "../../components/PageComponent";
import { useLocation } from "react-router-dom";

export default function PricingRules() {
  const location = useLocation();
  const [keywords, setKeywords] = useState([]);
  const tableURL =
    process.env.REACT_APP_API_URL + "pricing-rule-asin-inventory/?type=instock";
  const url = process.env.REACT_APP_API_URL + "pricing-rule-asin-inventory/";

  //********EFFECTS********* */

  //effect for calling the endpoint to get the correct data for the table and keyword rules
  //stores the response data in state as 'tableData' and 'keywords'
  useEffect(() => {
    //get the rules keywords
    getTableData("PricingRules")
      .then(({ data }) => {
        let newKeys = [];
        data.map((rule) => {
          newKeys.push({ value: rule.rule, label: rule.rule });
        });
        setKeywords(newKeys);
      })
      .catch(function(error) {
        setKeywords([]);
        console.log(error);
      });
  }, []);

  return (
    <>
      <PageComponent
        tableTitle="Pricing Rules"
        tableURL={tableURL}
        url={url}
        bulkEdit
        import
        resetSave
        checkbox
        search={location.state && location.state.item_name}
        bulkEdits={[
          {
            type: "select",
            id: "rule",
            label: "Rules",
            options: keywords && keywords,
          },
        ]}
        requiredCSVColumns={["asin", "rule", "min_price", "max_price"]}
        trimCSVColumns
        tableColumns={[
          {
            id: "image_links",
            header: "",
            type: "image",
            noEdit: true,
          },
          {
            anchor: "https://www.amazon.com/dp/",
            tag: "asin",
            id: "asin",
            header: "ASIN",
            type: "anchor",
          },
          {
            id: "item_name",
            header: "Item Name",
            type: "text",
          },
          {
            id: "brand",
            header: "Brand",
            type: "text",
          },
          {
            id: "rule",
            header: "Rule",
            type: "text",
          },
          {
            id: "min_price",
            header: "Min Price",
            type: "numeric",
            decimals: 2,
          },
          {
            id: "max_price",
            header: "Max Price",
            type: "numeric",
            decimals: 2,
          },
          {
            id: "buybox_price",
            header: "Buybox Price",
            type: "numeric",
            decimals: 2,
          },
          {
            id: "landed_pcogs",
            header: "Landed Product Cost",
            type: "numeric",
            decimals: 2,
          },
          {
            id: "fl_pcogs",
            header: "FL Product Cost",
            type: "numeric",
            decimals: 2,
          },
          {
            id: "map_price",
            header: "MAP",
            type: "numeric",
            decimals: 2,
          },
          {
            id: "offer_count",
            header: "Offers",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "review_count",
            header: "Reviews",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "star_rating",
            header: "Rating",
            type: "numeric",
            decimals: 1,
          },
          {
            id: "unit_profit",
            header: "Unit Profit",
            type: "numeric",
            decimals: 2,
          },
          {
            id: "total_current_inv",
            header: "Current Inventory",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "current_available",
            header: "Current Available",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "current_fc_transfer",
            header: "Current FC Transfer",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "current_inbound",
            header: "Current Inbound",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "t90d_units_sold",
            header: "T90D Units Sold",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "t90d_instock_days",
            header: "T90D Instock Days",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "t90d_units_per_day",
            header: "T90D Units per Day",
            type: "numeric",
            decimals: 2,
          },
          {
            id: "inv_age_0_to_90_days",
            header: "Inventory Age 0-90 days",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "inv_age_91_to_180_days",
            header: "Inventory Age 91-180 days",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "inv_age_181_to_270_days",
            header: "Inventory Age 181-270 days",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "inv_age_271_to_365_days",
            header: "Inventory Age 271-365 days",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "inv_age_365_plus_days",
            header: "Inventory Age 365+ days",
            type: "numeric",
            decimals: 0,
          },
          {
            type: "edit",
            header: "Action",
          },
        ]}
      />
    </>
  );
}
