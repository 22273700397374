import React, {useMemo, useLayoutEffect, useEffect, useState} from "react";
import objectPath from "object-path";
import { getBreadcrumbsAndTitle } from "../../_core/MetronicSubheader";
import { useLocation } from "react-router";

import {useHtmlClassService} from "../../_core/MetronicLayout";
import {Topbar} from "./Topbar";
import {HeaderMenuWrapper} from "./header-menu/HeaderMenuWrapper";
import {AnimateLoading} from "../../../_partials/controls";

export function Header() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const [title, setTitle] = useState(null);

  const pathToHeader={
    'catalog': 'Catalog',
    'local-inventory': 'Local Inventory',
    'inventory': 'FBA Inventory',
    'sales': 'Sales',

  }

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      )
    };
  }, [uiService]);

  useLayoutEffect(() => {
    getHeader(location.pathname)
    // eslint-disable-next-line
  }, [location.pathname]);

  //function to return the convert the pathname to a header
  function getHeader(path){
    //break up the path using '/' as a delimeter and get the module
    let mod = path.split('/')[1]

  
    if (mod === 'dashboard'){
      //if we're in dashboard module then use the aside menu titles
      const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
      setTitle(aside.title)
    }
    else{
      //convert the pathname into the header
      if(mod.indexOf('-') > -1){
        //if the header has multiple words then we split it
        let str = mod.split('-')
        mod = ""
        //then loop through and make the first letter of each word uppercase
        str.map(word=> {
          mod += word.charAt(0).toUpperCase() + word.slice(1) + ' '
        })

      }else{
        //make the first letter uppercase
        mod = mod.charAt(0).toUpperCase() + mod.slice(1)
      }

      setTitle(mod)
    }
  }

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}>
          <div className="d-flex align-items-center mr-5">
            <h5 className="text-dark font-weight-bold my-2 mr-5">
              <>
                {title}
              </>
              {/*<small></small>*/}
            </h5>
          </div>
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {/*layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />*/}
          {!layoutProps.menuHeaderDisplay && <div />}
          {/*end::Header Menu Wrapper*/}
          
          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
