import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function VCInvoices(){
    const base_url = process.env.REACT_APP_API_URL

    const fields={
        invoice_no: {
            id: 'invoice_no',
            header: 'Invoice Number',
            type: 'text',
        },
        po_number: {
            id: 'po_number',
            header: 'PO Number',
            type: 'text',
        },
        invoice_date: {
            id: 'invoice_date',
            header: 'Invoice Date',
            type: 'date',
            format: 'MM/DD/YYYY'
        },
        vendor_account: {
            id: 'vendor_account',
            header: 'Vendor Account',
            type: 'text',
        },
        asin:                 {
            anchor: 'https://www.amazon.com/dp/',
            tag: 'asin',
            id: 'asin',
            header: 'ASIN',
            type: 'anchor'
        },
        order_qty: {
            id: 'order_qty',
            header: 'Order Qty',
            type: 'numeric',
            decimals: 0
        },
        unit_cost: {
            id: 'unit_cost',
            header: 'Unit Cost',
            type: 'numeric',
            decimals: 2
        },
        date_shipped:{
            id: 'date_shipped',
            header: 'Date Shipped',
            type: 'date',
            format: 'MM/DD/YYYY'
        },
        transaction_number: {
            id: 'transaction_number',
            header: 'Transaction Number',
            type: 'text',
        },
        transaction_status: {
            id: 'transaction_status',
            header: 'Transaction Status',
            type: 'text',
        },
        
    }

    return(
        <PageComponent
            tableTitle="VC Invoices"
            url={base_url+'sales-vc-invoices/'}
            order='desc'
            orderBy={{id:'invoice_number', type: 'text'}}
            tableColumns={[fields.invoice_no, fields.invoice_date, fields.vendor_account, fields.po_number, fields.asin, fields.order_qty,
                            fields.unit_cost, fields.date_shipped, fields.transaction_number, fields.transaction_status]}
        />
    )
}