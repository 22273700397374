import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function FBATransfer() {
    const url = `${process.env.REACT_APP_API_URL}inventory-loc-fba-transfer/`

    return(
        <PageComponent
            tableTitle="FBA Transfer"
            url={url}
            bulkAdd
            bulkEdit
            import
            deleteItems
            addItems
            checkbox
            requiredCSVColumns={
                [
                    'transfer_date',
                    'asin',
                    'base_sku',
                    'base_sku_qty',
                    'fba_account',
                ]
            }
            formInputs={[
                {
                    required: true,
                    id: 'transfer_date',
                    label: 'Transfer Date',
                    type: 'date'
                },
                {
                    required: true,
                    id: 'asin',
                    label: 'ASIN',
                    ip: {maxLength: 15}
                },
                {
                    required: true,
                    id: 'base_sku',
                    label: 'Base SKU',
                    ip: {maxLength: 100}
                },
                {
                    required: true,
                    id: 'base_sku_qty',
                    label: 'Quantity',
                    ip: {maxLength: 100}
                },
                {
                    required: true,
                    id: 'fba_account',
                    label: 'Account',
                    ip: {maxLength: 100}
                },
                {
                    id: 'fba_shipment_id',
                    label: 'FBA Shipment ID',
                    ip: {maxLength: 100}
                },
                {
                    id: 'total_inbound_freight_cost',
                    label: 'Freight Cost',
                    ip: {maxLength: 100}
                }
                
            ]}
            tableColumns={[
                {
                    type: 'date',
                    format: 'MM/DD/YYYY',
                    id: 'transfer_date',
                    header: 'Transfer Date',
                },
                {
                    anchor: 'https://www.amazon.com/dp/',
                    tag: 'asin',
                    id: 'asin',
                    header: 'ASIN',
                    type: 'anchor'
                },
                {
                    id: 'base_sku',
                    header: 'Base SKU',
                    type: 'text',
                },
                {
                    id: 'base_sku_qty',
                    header: 'Quantity',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'fba_account',
                    header: 'Account',
                    type: 'text',
                },
                {
                    id: 'fba_shipment_id',
                    header: 'FBA Shipment ID',
                    type: 'text'
                },
                {
                    id: 'total_inbound_freight_cost',
                    header: 'Total Freight Cost',
                    type: 'numeric',
                    decimals: 2
                },
                {
                    header: 'Action',
                    type: 'edit',
                },
            ]}
        />
    )
}