import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function DirectShip() {
    const url = `${process.env.REACT_APP_API_URL}inventory-loc-direct-ship/`

    return(
        <PageComponent
            tableTitle="Direct Ship"
            url={url}
            bulkAdd
            bulkEdit
            import
            deleteItems
            addItems
            checkbox
            requiredCSVColumns={
                [
                    'ship_date',
                    'sales_channel',
                    'base_sku',
                    'qty_shipped',
                ]
            }
            formInputs={[
                {
                    required: true,
                    id: 'ship_date',
                    label: 'Ship Date',
                    type: 'date'
                },
                {
                    required: true,
                    id: 'base_sku',
                    label: 'Base SKU',
                    ip: {maxLength: 100}
                },
                {
                    id: 'order_number',
                    label: 'Order Number',
                    ip: {maxLength: 100}
                },
                {
                    required: true,
                    id: 'sales_channel',
                    label: 'Channel',
                    ip: {maxLength: 50}
                },
                {
                    required: true,
                    id: 'qty_shipped',
                    label: 'Quantity Shipped',
                    ip: {maxLength: 50}
                },
                {
                    id: 'carrier_name',
                    label: 'Carrier',
                    ip: {maxLength: 100}
                },
                {
                    id: 'tracking_number',
                    label: 'Tracking Number',
                    ip: {maxLength: 50}
                },
            ]}
            tableColumns={[
                {
                    type: 'date',
                    format: 'MM/DD/YYYY',
                    id: 'ship_date',
                    header: 'Ship Date',
                },
                {
                    id: 'base_sku',
                    header: 'Base SKU',
                    type: 'text',
                },
                {
                    id: 'order_number',
                    header: 'Order Number',
                    type: 'text',
                },
                {
                    id: 'sales_channel',
                    header: 'Channel',
                    type: 'text',
                },
                {
                    id: 'qty_shipped',
                    header: 'Quantity Shipped',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'carrier_name',
                    header: 'Carrier',
                    type: 'text',
                },
                {
                    id: 'tracking_number',
                    header: 'Tracking Number',
                    type: 'text',
                },
                {
                    header: 'Action',
                    type: 'edit',
                },
            ]}
        />
    )
}