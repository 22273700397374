import React, { useState, useEffect } from "react";
import ProductTable from "../../components/Tables/ProductTable/ProductTable";
import { getTableData } from "../../components/Tables/ProductTable/TableData";

export default function StagnantItems(props) {
  const [tableData, setTableData] = useState(null);

  //*****************EFFECTS******************* */
  useEffect(() => {
    //Stagnant Items Tile Data
    getTableData("StagnantItems")
      .then(({ data }) => {
        setTableData(data);
      })
      .catch(function(error) {
        console.log(error);
        setTableData([]);
      });
  }, []);

  return (
    <div>
      <ProductTable
        data={tableData}
        title="Stagnant Items"
        rows={25}
        cols={12}
        columns={[
          {
            id: "image_links",
            header: "",
            type: "image",
          },
          {
            anchor: "https://www.amazon.com/dp/",
            tag: "asin",
            id: "asin",
            header: "ASIN",
            type: "anchor",
          },
          {
            id: "item_name",
            header: "Item Name",
            type: "redirect",
            path: "/pricing/pricing-rules",
          },
          {
            id: "total_inventory_value",
            header: "Total Inventory Value",
            type: "numeric",
            decimals: 2,
          },
          {
            id: "total_inventory_units",
            header: "Total Inventory Units",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "over_90_days_value",
            header: "Over 90D Value",
            type: "numeric",
            decimals: 2,
          },
          {
            id: "units_over_90_days",
            header: "Units Over 90D",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "t90d_units_sold",
            header: "T90D Units Sold",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "t90d_units_per_day",
            header: "T90D Units per Day",
            type: "numeric",
            decimals: 2,
          },
          {
            id: "offer_count",
            header: "Offers",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "review_count",
            header: "Reviews",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "rule",
            header: "Rule",
            type: "text",
          },
        ]}
      />
    </div>
  );
}
