// Component rendered by ProductTableToolbar(its only parent component)
// Only rendered if bulkEdits props is set correctly by the pages,
// See Adjustments, Inbound, and VCOrders for examples of how to pass props to render this 
// shows up next to the table title when user selects a row in the table

import React, { useState, useEffect } from 'react';
import { TextField, Badge, Select, FormControl, MenuItem, FormControlLabel, Checkbox} from '@material-ui/core';
import { fade, lighten, makeStyles } from '@material-ui/core/styles';
import BulkEditModal from './BulkEditModal';
import moment from 'moment';


const useToolbarStyles = makeStyles(theme => ({
    textField: {
      margin: theme.spacing(1),
 
      width: 136,
      
    },
  }));

export default function BulkEdits(props){
    const classes = useToolbarStyles()
    const [openModal, setOpenModal] = useState(false)
    const [inputs, setInputs] = useState(null)
    const [headerIDs, setHeaderIDs] = useState(null)

    //************EFFECTS*********** */
    //forces rerender if bulkedits changes
    useEffect(() => {
    }, [props.bulkEdits]);

    //************METHODS*********** */
    //functions for opening and closing the edit modal
    //also sets inputs and headerIDs in state
    function showModal(edit){
        setInputs(edit.fields)
        setHeaderIDs(edit.headerIDs)
        setOpenModal(true)
    }
    function closeModal(){
        setOpenModal(false)
    }

    //closes the modal and sends changes to the page component
    function handleConfirm(changes){
        props.handleMultipleEdits(changes)
        closeModal()
    }


   return(
    <>
    {openModal &&
        <BulkEditModal
            show={openModal}
            inputs={inputs && inputs}
            onHide={closeModal}
            handleCancel={closeModal}
            handleConfirm={handleConfirm}
            handleBulkEditChange={props.handleBulkEditChange}
            selectedRows={props.selectedRows}
            headerIDs={headerIDs}
        />
    }
    {props.bulkEdits.map((edit, index) => {
        let cellChild

        switch(edit.type){
            case 'select':
                cellChild=  <FormControl className='ml-5' >     
                                <Select
                                key={index}
                                name='select'
                                className={classes.textField}
                                defaultValue=''
                                onChange={event => props.handleBulkEditChange(event, edit.id)}
                                placeholder={edit.label}
                                displayEmpty               
                                >
                                <MenuItem key={-1} value='' disabled >                                                  
                                        {edit.label}
                                </MenuItem>
                                { edit.options.map((key, index) =>{
                                    return(
                                    <MenuItem key={index} value={key.value}>                                                  
                                        {key.label}
                                    </MenuItem>
                                    );
                                })}
                                </Select>
                            </FormControl>
                break;
            case 'date':
                cellChild=  <TextField
                                type={'date'}
                                key={index}
                                hidden={edit.hidden}
                                onChange={event => props.handleBulkEditChange(event, edit.id)}
                                className='ml-5'               
                            />  
                break;

            case 'checkbox':
                cellChild=  <FormControlLabel
                                label={<div style={{fontSize: '1rem', color: 'rgba(0, 0, 0, 0.54)'}}>{edit.label}</div>}
                                labelPlacement="left"
                                hidden={edit.hidden}
                                className='ml-10'
                                control={
                                    <Checkbox
                                        key={index}
                       
                                        className='p-0 '
                                        onChange={event => props.handleBulkEditChange({target: {value: edit.matchIDs}}, edit.id)}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                }
                            />
                break;
            case 'bool':
                cellChild=  <FormControl key={index} className='ml-5' >     
                                <Select
                                name='select'
                                
                                className={classes.textField}
                                defaultValue=''
                                onChange={event => props.handleBulkEditChange({target: {value: true}}, event.target.value, edit.show)}
                                placeholder={edit.label}
                                displayEmpty               
                                >
                                <MenuItem key={-1} value='' disabled >                                                  
                                        {edit.label}
                                </MenuItem>
                                { edit.options.map((key,index) =>{
                                    return(
                                    <MenuItem key={index} value={key.value}>                                                  
                                        {key.label}
                                    </MenuItem>
                                    );
                                })}
                                </Select>
                            </FormControl>
                break;
            case 'modal':
                cellChild = 
                            <span className="btn btn-transparent-primary btn-sm font-weight-bolder font-size-md ml-3" onClick={()=> showModal(edit)}> 
                                {edit.btnTitle}
                            </span>

                break;
        }

        return(
            
            cellChild
        )
      })}
      </>
   )
}