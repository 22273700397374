import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';
import { useHistory } from 'react-router-dom';

export default function ShortShipped(){
    const base_url = process.env.REACT_APP_API_URL
    const history = useHistory()

    const fields={
        base_sku: {
            id: 'base_sku',
            header: 'Base Sku',
            type: 'text',
        },
        po_number: {
            id: 'po_number',
            header: 'PO Number',
            type: 'function',
            function: sendToPOPage
        },
        vendor_name: {
            id: 'vendor_name',
            header: 'Vendor Name',
            type: 'text',
        },
        qty_ordered: {
            id: 'qty_ordered',
            header: 'Qty Ordered',
            type: 'numeric',
            decimals: 0
        },
        qty_received: {
            id: 'qty_received',
            header: 'Qty Received',
            type: 'numeric',
            decimals: 0
        },
        qty_cancelled: {
            id: 'qty_cancelled',
            header: 'Qty Cancelled',
            type: 'numeric',
            decimals: 0
        },
        qty_outstanding: {
            header: 'Qty Oustanding',
            type: 'subtract',
            id1: 'qty_ordered',
            id2: 'qty_received',
            decimals: 0
        },
        shorted_value: {
            id: 'shorted_value',
            header: 'Shorted Value',
            type: 'numeric',
            decimals: 2
        },
        order_date:{
            id: 'order_date',
            header: 'Order Date',
            type: 'date',
            format: 'MM/DD/YYYY'
          
        },
        received_date:{
            id: 'received_date',
            header: 'Received Date',
            type: 'date',
            format: 'MM/DD/YYYY'
          
        },
        
    }

    //method called when user clicks on a PO number
    //redirects user to the purchase order page and passes the input PO as a state parameter
    function sendToPOPage(po){
        history.push({pathname: '/instock/purchase-orders/', state: po})
    }

    return(
        <PageComponent
            tableTitle="Short Shipped"
            url={base_url+'inventory-loc-inbound/?type=partial'}
            tableColumns={[fields.base_sku, fields.po_number, fields.vendor_name,fields.order_date, fields. received_date, fields.qty_ordered, fields.qty_received, fields.qty_cancelled, fields. qty_outstanding, fields.shorted_value]}
        />
    )
}