import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { KTUtil } from "../../../_metronic/_assets/js/components/util";
import { useHtmlClassService } from "../../../_metronic/layout";

export default function ColumnChart(props) {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray100: objectPath.get(
                uiService.config,
                "js.colors.gray.gray100"
            ),
            colorsGrayGray700: objectPath.get(
                uiService.config,
                "js.colors.gray.gray700"
            ),
            colorsThemeBaseSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.base.success"
            ),
            colorsThemeLightSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.light.success"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
        };
    }, [uiService]);

    useEffect(() => {
        const element = document.getElementById("column_chart_" + props.name);
        if (!element) {
            return;
        }

        const height = parseInt(KTUtil.css(element, "height"));
        const options = getChartOptions(
            props.data,
            props.name,
            props.categories,
            props.color,
            props.xtitle,
            props.ytitle
        );

        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [layoutProps, props.data]);

    return (
        <div
            id={"column_chart_" + props.name}
            style={{ padding: 15, paddingLeft: 10 }}
        ></div>
    );
}

function getChartOptions(data, name, categories, color, xaxis, yaxis) {
    let tick1 = 6,
        dif1 = Math.max(...data) - 1;

    if (dif1 < 5 && dif1 > 0) {
        tick1 = dif1 + 1;
    } else if (dif1 === 0) {
        tick1 = 1;
    }

    const options = {
        series: [
            {
                name: "Amount",
                data: data,
            },
        ],
        chart: {
            height: 250,
            type: "bar",
            zoom: {
                enabled: false,
            },
        },
        colors: color,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        title: {
            text: name,
            align: "left",
        },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        yaxis: {
            title: {
                text: yaxis,
            },
            min: 0,
            max: Math.max(...data),
            tickAmount: tick1,
            labels: {
                formatter: function(val) {
                    return val.toFixed(0);
                },
            },
        },
        xaxis: {
            categories: categories,
            title: {
                text: xaxis,
                offsetX: -15,
            },
        },
    };
    return options;
}
