import React from "react";
import {Redirect, Switch} from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import DPScraper from "../modules/Content/DPScraper";


export default function ContentPage() {
    return (
        <Switch>
          <Redirect
            exact={true}
            from="/content"
            to="/content/dp-scraper"
          />
    
          <ContentRoute
            path="/content/dp-scraper" 
            component={DPScraper} 
          />
        </Switch>
    );
}