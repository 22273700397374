import React, { useState, useEffect } from "react";
import PageComponent from "../../components/PageComponent";

export default function ItemPrep() {
  const url = `${process.env.REACT_APP_API_URL}sales-asin-vc/`;

  return (
    <PageComponent
      tableTitle="Item Prep"
      url={url}
      deleteItems
      addItems
      checkbox
      bulkAdd
      bulkEdit
      import
      requiredCSVColumns={["asin", "vc_prep"]}
      formInputs={[
        {
          required: true,
          id: "asin",
          label: "ASIN",
          ip: { maxLength: 15 },
        },
        {
          required: true,
          id: "vc_prep",
          label: "VC Prep",
          ip: { maxLength: 100 },
        },
      ]}
      tableColumns={[
        {
          anchor: "https://www.amazon.com/dp/",
          tag: "asin",
          id: "asin",
          header: "ASIN",
          type: "anchor",
        },
        {
          id: "vc_prep",
          header: "VC Prep",
          type: "text",
        },
        {
          id: "sku",
          header: "SKU",
          type: "text",
        },
        {
          id: "item_name",
          header: "Item Name",
          type: "text",
        },
        {
          id: "brand",
          header: "Brand",
          type: "text",
        },
      ]}
    />
  );
}
