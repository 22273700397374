import React, { useState, useEffect } from "react";
import ProductTable from "../../components/Tables/ProductTable/ProductTable";
import { getTableData } from "../../components/Tables/ProductTable/TableData";

export default function DPScraper() {
  const [tableData, setTableData] = useState(null);

  //********EFFECTS********* */
  //effect for calling the endpoint to get the correct data for the table
  //stores the response data in state as 'tableData'
  useEffect(() => {
    getTableData("DPScraper")
      .then(({ data }) => {
        setTableData(data);
      })
      .catch(function(error) {
        setTableData([]);
        console.log(error);
      });
  }, []);

  return (
    <>
      <div>
        <ProductTable
          title="DP Scraper"
          data={tableData}
          rows={25}
          cols={14}
          columns={[
            {
              id: "image_links",
              header: "",
              type: "image",
            },
            {
              anchor: "https://www.amazon.com/dp/",
              tag: "asin",
              id: "asin",
              header: "ASIN",
              type: "anchor",
            },
            {
              id: "item_name",
              header: "Item Name",
              type: "text",
            },
            {
              id: "brand",
              header: "Brand",
              type: "text",
            },
            {
              id: "buybox_seller_name",
              header: "Seller Name",
              type: "text",
            },
            {
              id: "fulfillment_mode",
              header: "Shipped By",
              type: "text",
            },
            {
              id: "buybox_price",
              header: "Price",
              type: "numeric",
              decimals: 2,
            },
            {
              id: "star_rating",
              header: "Rating",
              type: "numeric",
              decimals: 1,
            },
            {
              id: "review_count",
              header: "Reviews",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "offer_count",
              header: "Offers",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "dp_yanked",
              header: "Yanked",
              type: "bool",
            },
            {
              id: "buybox_exists",
              header: "Buybox Exists",
              type: "bool",
            },
            {
              id: "oos_dp",
              header: "OOS DP",
              type: "bool",
            },
            {
              type: "date",
              format: "ddd, MMM Do @h:mm a",
              id: "snapshot_date",
              header: "Snapshot",
            },
          ]}
        />
      </div>
    </>
  );
}
