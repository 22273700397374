import React, { useState, useEffect } from 'react';
import KeywordSelect from '../Buttons/KeywordSelect';
import { Tooltip, IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

export default function SelectWidget({
    title,
    refresh,
    keywords,
    placeholder,
    handleSelect,
    width,
    className

}) {

    const [value, setValue] = useState(null)


    //function for handling the select of an option from the dropdown
    function handleDropDown(option){
        //pass the value to the prop function
        handleSelect(option.value, option.label)

        //set the selected value
        setValue(option)
    }

    //function for handling the click event of the refresh button
    function handleRefresh(event){
        //set the value to null
        setValue(null)

        //call the props refresh function
        refresh()
    }
    


    return(
        <div className={`card card-custom card-stretch p-3 d-flex flex-row justify-content-between ${className}`} style={{minWidth: 389, width:width, height: 76}}>
            <div className='d-flex flex-center border-right font-size-lg font-weight-bold pr-4'>{title} </div>
            <div className='mx-8 d-flex flex-center'>
                <KeywordSelect
                    id={0} 
                    placeholder={placeholder} 
                    keywords={keywords} 
                    handleDropDown={handleDropDown}
                    value={value}
                />
            </div>
            <div className="border-left pl-2 ">
                <Tooltip title="Refresh" >
                    <IconButton aria-label="Refresh" onClick={handleRefresh}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>
            </div>
    </div>
    )
}