import React from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';


export default function ProductTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, rowsPerPage } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow >

                {props.checkbox &&
                <>
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowsPerPage}
                            checked={numSelected === rowsPerPage}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'Select all' }}
                        />
                    </TableCell>
                    
                </>}

                {props.columns.map((row,index) => {
                    let tooltip = null;
                    return(
                    <TableCell
                        key={index}
                        align={row.type === 'numeric' || row.type==='subtract' ? 'right' : 'left'}
                        padding={ 'none'}
                        className='p-2'
                        sortDirection={orderBy.id === row.id ? order : false}
                    >
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            disabled 
                            overlay={<Tooltip hidden={tooltip? false: true} > {tooltip} </Tooltip>}
                            >
                            <TableSortLabel
                                active={orderBy.id === row.id}
                                direction={order}
                                onClick={createSortHandler(row)}
                            >
                                {row.header}{tooltip? <>&#9432;</> : null}
                            </TableSortLabel>
                        </OverlayTrigger>
                    </TableCell>);
                })}
            </TableRow>
        </TableHead>
    );
}
