import React, { useState, useEffect } from "react";
import ColumnChart from "../../components/Charts/ColumnChart";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import ProductTable from "../../components/Tables/ProductTable/ProductTable";
import PieChart from "../../components/Charts/PieChart";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function InventoryOverview() {
  const base_url = process.env.REACT_APP_API_URL;
  const days = [
    { value: 7, label: "7 Days" },
    { value: 30, label: "30 Days" },
    { value: 90, label: "90 Days" },
    { value: 180, label: "180 Days" },
    { value: 365, label: "365 Days" },
  ];

  //state variables for the charts
  const [fbaInventory, setFbaInventory] = useState(null);
  const [brands, setBrands] = useState(null);
  const [invSnapshot, setInvSnapshot] = useState(null);
  const [invStatus, setInvStatus] = useState(null);

  //state variables for the tables
  const [invItems, setinvItems] = useState(null);
  const [stagnantInv, setStagnantInv] = useState(null);
  const [invAdjustments, setInvAdjustments] = useState(null);

  //***************EFFECTS******************* */
  //effect for getting and initializing all the data for each tile
  useEffect(() => {
    //-----------Endpoints for the charts-------------
    //call the endpoint for the fba inventory tile and set the brands and fbainventory state variables
    axios
      .get(base_url + "inventory-brand-summary/?type=brands")
      .then(({ data }) => {
        let newData = [],
          newBrands = [];
        data
          .sort(function(a, b) {
            return b.total_inventory - a.total_inventory;
          })
          .slice(0, 20)
          .map((item) => {
            newData.unshift(parseFloat(item.total_inventory.toFixed(0)));
            newBrands.unshift(item.brand);
          });
        setBrands(newBrands);
        setFbaInventory(newData);
      })
      .catch(function(error) {
        console.log(error);
        setBrands([]);
        setFbaInventory([]);
      });

    axios
      .get(base_url + "inventory-brand-summary/?type=summary")
      .then(({ data }) => {
        let newData = [];
        newData.push(parseFloat(data.onhand_inventory.toFixed(0)));
        newData.push(parseFloat(data.onorder_inventory.toFixed(0)));
        newData.push(parseFloat(data.fba_inventory.toFixed(0)));
        newData.push(parseFloat(data.committed_inventory.toFixed(0)));
        setInvSnapshot(newData);
      })
      .catch(function(error) {
        console.log(error);
        setInvSnapshot([]);
      });

    axios
      .get(base_url + "inventory-status/?type=pie")
      .then(({ data }) => {
        let newData = [];
        data.map((item) => {
          if (item.inv_group !== "out_of_stock") {
            // skip out of stock bc it will always be 0
            newData.push(parseFloat(item.total_pcogs.toFixed(0)));
          }
        });
        setInvStatus(newData);
      })
      .catch(function(error) {
        console.log(error);
        setInvStatus([]);
      });

    //-----------Endpoints for the 3 tables-------------
    axios
      .get(base_url + "inventory-brand-summary/?type=items")
      .then(({ data }) => {
        setinvItems(data);
      })
      .catch(function(error) {
        console.log(error);
        setinvItems([]);
      });
    axios
      .get(base_url + "inventory-stagnant-items/?type=brands")
      .then(({ data }) => {
        setStagnantInv(data);
      })
      .catch(function(error) {
        console.log(error);
        setStagnantInv([]);
      });
    axios
      .get(base_url + "inventory-loc-adjustments/?type=brands&days=30")
      .then(({ data }) => {
        setInvAdjustments(data);
      })
      .catch(function(error) {
        console.log(error);
        setInvAdjustments([]);
      });
  }, []);

  //***************METHODS******************* */
  //function for handling the key in the inventory adjustments table
  function handleKey(day) {
    axios
      .get(
        base_url + "inventory-loc-adjustments/?type=brands&days=" + day.value
      )
      .then(({ data }) => {
        setInvAdjustments(data);
      })
      .catch(function(error) {
        console.log(error);
        setInvAdjustments([]);
      });
  }
  console.log(invSnapshot);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <div className="card card-custom card-stretch ">
          <div className="px-5 mt-4" style={{ fontSize: 14 }}>
            Onhand + FBA Inventory (Top 20)
          </div>
          {fbaInventory && brands ? (
            <span className="card-body p-3">
              <ColumnChart
                name=""
                data={fbaInventory}
                categories={brands}
                xtitle="Brand"
                ytitle="Amount"
              />
            </span>
          ) : (
            <div className="w-100 d-flex flex-column flex-center ">
              <h5>Loading Data...</h5>
              <div>
                <CircularProgress color="secondary" />
              </div>
            </div>
          )}
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <div className="card card-custom card-stretch ">
          <div className="px-5 mt-4" style={{ fontSize: 14 }}>
            Inventory Snapshot
          </div>
          {invSnapshot ? (
            <span className="d-flex flex-center w-100">
              <PieChart
                name="invSnapshot"
                data={invSnapshot}
                labels={["Onhand", "Onorder", "FBA", "Committed"]}
              />
            </span>
          ) : (
            <div className="w-100 d-flex flex-column flex-center ">
              <h5>Loading Data...</h5>
              <div>
                <CircularProgress color="secondary" />
              </div>
            </div>
          )}
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <ProductTable
          title="Inventory Items"
          small
          orderBy={{ id: "onhand_amount", type: "numeric" }}
          order={"desc"}
          rows={5}
          data={invItems}
          columns={[
            {
              id: "base_sku",
              header: "Base SKU",
              type: "text",
            },
            {
              id: "onhand_amount",
              header: "Onhand Amount",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "fba_amount",
              header: "FBA Amount",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "onorder_amount",
              header: "Onorder Amount",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "committed_amount",
              header: "Committed Amount",
              type: "numeric",
              decimals: 0,
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <div className="card card-custom card-stretch ">
          <div className="px-5 mt-4" style={{ fontSize: 14 }}>
            Onhand Inventory Status
          </div>
          {invStatus ? (
            <span className="d-flex flex-center w-100">
              <PieChart
                name="invStatus"
                data={invStatus}
                labels={["FBA Active", "Idle", "Stranded", "VC Active"]}
              />
            </span>
          ) : (
            <div className="w-100 d-flex flex-column flex-center ">
              <h5>Loading Data...</h5>
              <div>
                <CircularProgress color="secondary" />
              </div>
            </div>
          )}
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <ProductTable
          title="Stagnant Inventory"
          small
          orderBy={{ id: "total_inventory_value", type: "numeric" }}
          order={"desc"}
          rows={5}
          data={stagnantInv}
          columns={[
            {
              id: "brand",
              header: "Brand",
              type: "text",
            },
            {
              id: "over_90_days_value",
              header: "Over 90D Value",
              type: "numeric",
              decimals: 2,
            },
            {
              id: "total_inventory_value",
              header: "Total Value",
              type: "numeric",
              decimals: 2,
            },
            {
              id: "units_over_90_days",
              header: "Over 90D Units",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "asin",
              header: "SKU Count",
              type: "numeric",
              decimals: 0,
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ProductTable
          title="Inventory Adjustments"
          small
          handleKey={handleKey}
          orderBy={{ id: "adjustment_loss", type: "numeric" }}
          order={"asc"}
          keywords={[{ placeholder: "30 Days", data: days }]}
          rows={5}
          data={invAdjustments}
          columns={[
            {
              id: "brand",
              header: "Brand",
              type: "text",
            },
            {
              id: "adjustment_loss",
              header: "Adjustment Loss",
              type: "numeric",
              decimals: 2,
            },
            {
              id: "qty_adjusted",
              header: "Adjustment Quantity",
              type: "numeric",
              decimals: 0,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
