import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function VCFulfillment() {
    const url = `${process.env.REACT_APP_API_URL}sales-vc-po-shipments/`
     const fields = {
        'po_number': {
            required: true,
            id: 'po_number',
            label: 'PO Number',
            header: 'PO Number',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'window_start': {
            id: 'window_start',
            label: 'Window Start',
            header: 'Window Start',
            type: 'date',
            format: 'MM/DD/YYYY',
            ip: { maxLength: 255 }
        },
        'asin': {
            required: true,
            label: 'ASIN',
            anchor: 'https://www.amazon.com/dp/',
            tag: 'asin',
            id: 'asin',
            header: 'ASIN',
            type: 'anchor',
            ip: { maxLength: 255 }
        },
        'onorder_qty': {
            required: true,
            id: 'onorder_qty',
            label: 'Onorder Qty',
            header: 'Onorder Qty',
            type: 'numeric',
            decimals: 0,
            ip: { maxLength: 255 }
        },
        'accepted_qty': {
            required: true,
            id: 'accepted_qty',
            label: 'Accepted Qty',
            header: 'Accepted Qty',
            type: 'numeric',
            decimals: 0,
            ip: { maxLength: 255 }
        },
        'shipped_qty': {
            required: true,
            id: 'shipped_qty',
            label: 'Shipped Qty',
            header: 'Shipped Qty',
            type: 'numeric',
            decimals: 0,
            ip: { maxLength: 255 }
        },
        'vc_prep': {
            required: true,
            id: 'vc_prep',
            label: 'VC Prep',
            header: 'VC Prep',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'base_sku': {
            required: true,
            id: 'base_sku',
            label: 'Base Sku',
            header: 'Base Sku',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'vc_title': {
            required: true,
            id: 'vc_title',
            label: 'VC Title',
            header: 'VC Title',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'qty_per_base': {
            required: true,
            id: 'qty_per_base',
            label: 'Qty per Base',
            header: 'Qty per Base',
            type: 'numeric',
            decimals: 0,
            ip: { maxLength: 255 }
        },

        'kit': {
            id: 'kit',
            label: 'Kit',
            header: 'Kit',
            type: 'bool',
        },
    }

    return (
        <PageComponent
            tableTitle="VC Fulfillment"
            url={url}
            tableColumns={Object.values(fields)}
        />
    )
}