import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import ItemCogs from "../modules/Dashboard/ItemCogs";
import AsinEconomicsDaily from "../modules/Financials/AsinEconomicsDaily";
import AsinEconomicsAggregation from "../modules/Financials/AsinEconomicsAggregation";
import BrandEconomicsAggregation from "../modules/Financials/BrandEconomicsAggregation";
import DailyCogs from "../modules/Financials/DailyCogs";

import InvoiceAlerts from "../modules/Financials/InvoiceAlerts";

import VendorBills from "../modules/Financials/VendorBills";

export default function FinancialsPage() {
  return (
    <Switch>
      <Redirect exact={true} from="/financials" to="/financials/vendor-bills" />

      <ContentRoute path="/financials/item-cogs" component={ItemCogs} />

      <ContentRoute path="/financials/daily-cogs" component={DailyCogs} />

      <ContentRoute
        path="/financials/asin-economics-daily"
        component={AsinEconomicsDaily}
      />

      <ContentRoute
        path="/financials/asin-economics-aggregation"
        component={AsinEconomicsAggregation}
      />

      <ContentRoute
        path="/financials/brand-economics-aggregation"
        component={BrandEconomicsAggregation}
      />
    </Switch>
  );
}
