// Similar to InputTable but specifically tailored for Invoices
// Child Component of InvoiceModal


import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, TextField, Checkbox, FormControlLabel, InputBase, FormControl, Select, MenuItem, TableSortLabel} from '@material-ui/core';
import { UpdateTable, AddTableData, DeleteTableData, getSorting, stableSort} from './ProductTable/TableData';
import BasicSnackBar from '../Alerts/BasicSnackBar';
import axios from 'axios';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    textfield: {
        width: '100% !important',
        height: '38px !important',
        [`& fieldset`]: {
            borderRadius: 0,
            
          },
    },
    popover: {
        overflowY: 'scroll !important'
    }

  }));

export default function InvoicesTable(props) {

    const classes = useStyles()
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState({id: 'sku', type: 'text'})
    const [values, setValues] = useState([])
    const [count, setCount] = useState(0)
    const [changeIds, setChangeIds] = useState([])
    const [message, setMessage] = useState(null)
    const [isError, setIsError] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [invoiceVals, setInvoiceVals] = useState({
        invoice_date: null,
        invoice_number: "",
        payment_terms: "",
        invoice_paid: null,
        due_date: null,
        total_amount: 0
    })

    const modeToTitle ={
        'POST': 'Adding New Invoice',
        'PUT': 'Editing Selected Invoice',
        'ALL': "Viewing All Invoices(Summed)"
    }

    const modeToColor ={
        'POST': 'success',
        'PUT': 'primary',
        'ALL': "info"
    }

    //******************************EFFECTS****************************** */
    //effect for setting the vendorbills and invoice vals in this components state
    useEffect(() => {
        setInvoiceVals(props.invoiceVals)
    }, [ props.invoiceVals]);

    //effect for setting the values variable to keep track of all the changes 
    //called everytime the input data changes
    useEffect(() => {
        if(props.vendorBills){
            initialize()
        }
    }, [props.vendorBills]);

    useEffect(() => {
        let newCount = 0
        let newValues = []

        if(values.length > 0){
            stableSort(values, getSorting(order, orderBy)).map(item => {
                newValues.push(item)
                newCount++;
            })

            setValues(newValues)
            setCount(newCount)
        }
    }, [order, orderBy]);

    //function for initializing all of the values data
    function initialize() {
        let newCount = 0
        let newValues = []

        //add a rowId to keep track of each row
        stableSort(props.vendorBills, getSorting(order, orderBy)).map((item, index)=> {
            let newItem = {...item}
            newItem['rowId'] = index
            newValues.push({...item})
            newCount++
        })

        
        
        if(newValues.length > 0){
            setValues(newValues)
            setCount(newCount)
        }else{
            addRow()
        }
        
        setChangeIds([])
        setSubmitted(false)
    }

    

    //******************************METHODS****************************** */
    //function for handling changes in the table
    const handleChange = (index, field) =>event =>{
        let newValues = [...values]
        let newItem = newValues[index]
        newItem[field]  =event.target.value
        newValues[index] = newItem
        setValues(newValues)
        setChangeIds([...changeIds, index])//store the rowindex of the change
    }
   
    const handleTopChange = id => event => {
        //change the table data if user changes invoice number
        if(id === 'invoice_number' && props.mode !=='POST'){
            props.handleInvoiceSelect(event.target.value)
        }else{
            if(props.mode === 'PUT'){
                //store all row indexes
                let allRows = changeIds
                allRows.push(...Object.keys(values))
                setChangeIds(allRows)
            }
            
            setInvoiceVals({...invoiceVals, [id]: event.target.value})
        }
    }

    //function for handling checkbox for bool values in the table
    const handleCheck = (id) =>event =>{

        //see if item was checked or not and toggle
        let checked = false
        if(event.target.checked){
            checked = true
        }
        setInvoiceVals({...invoiceVals,['invoice_paid']: checked})
    }

    //function for adding rows
    function addRow(){
        //make an empty object
        let newRow = {}
        props.columns.map(field => {
            newRow[field.id] = null
            if(field.type === 'bool'){
                newRow[field.id] = false
            }
        })
        //add vendor_name since its required for posting
        newRow['vendor_name'] = props.vendor_name
        //add vendor_name since its required for posting
        newRow['po_number'] = props.po_number
        //add rowId 
        newRow['rowId'] = count

        //add to values list to show in table
        setValues([...values, newRow])

        //update count 
        let newCount = count
        newCount++
        setCount(newCount)

        //set message
        setMessage('Row added to the bottom of the table.')
    }

    //function for handleing the submit button
    async function handleSubmit(){
        
        let allChanges = [],additions = [], edits = [], allRows = values
        let newErrors = { empty: 0, failed: [] }
        
        //make a distinct list of rows changed
        let disticintIds = [...new Set(changeIds)] //remove duplicate

        //make sure to add all rows to the change list so that their common values get updated as well
        //such as total amount 
        if(props.mode === 'PUT'){
            allRows.map((row, index )=> {
                if(!disticintIds.includes(index)){
                   disticintIds.push(index)
                }
            })
        }   

        //loop thorugh all the change ids and make an array of all rows changed
        disticintIds.map(id => {
            if(typeof values[id] !== 'undefined'){
                allChanges.push(values[id])
            }
        })

        //add the total amount and due_date to inovoice vals
        let newVals = {...invoiceVals}
        newVals.total_amount = calcTotalAmount()
        newVals.due_date = moment(invoiceVals.invoice_date).add(getDaystoPay(invoiceVals.payment_terms), 'days').format('YYYY-MM-DD')

        //loop through the input array and seperate into additions or edits bin
        //if row has an id then its a edit, otherwise its an addition
        //also, add the topRowValues to each row
        allChanges.map(row => {
            let obj = Object.assign(row, newVals)
            obj.total_line_price = obj.qty * obj.unit_line_price

            //sort into bins
            if(typeof obj['id'] !== 'undefined'){
                edits.push(obj)
            }else{
                additions.push(obj)
            }
        })
        
        //make the calls and keep count of the successfull calls
        let result = 0

        if(edits.length > 0){
            result += await UpdateTable(props.url, 0,newErrors, edits.slice(0,20), edits)
        }
        if(additions.length > 0){
            result += await AddTableData(props.url, 0, newErrors, additions.slice(0, 20), additions)
        }
        
        setSubmitted(true) // set true to show any errors
        props.handleSubmit(result, newErrors)
    }

    //function for closing the alert snackbar
    function closeAlert() {
        setMessage(null)
        setIsError(false)
    }


    //function for returning the textfield type
    function getBottomInputs(index, col) {
        let cellChild, type, align='left', justify='start', value =values[index][col.id]
        switch (col.type) {
            case 'select':
                cellChild =
                    <FormControl className='w-100  px-2' style={{height: '40px'}}>
                        <Select
                            name='select'
                            value={value}
                            onChange={col.onChange? col.onChange(index, col.id) :handleChange(index, col.id)}
                            displayEmpty
                            className='h-100'
                            input={<InputBase />}
                           
                        >
                   

                           
                            <MenuItem key={-1} value={null} disabled >
                                {'Select ' + col.header}
                            </MenuItem>
                            <MenuItem className='h-100' key={-2} value={'Freight'}>
                                Freight
                            </MenuItem>
                            <MenuItem className='h-100' key={-3} value={'Discount'}>
                                Discount
                            </MenuItem>
                            <MenuItem className='h-100' key={-4} value={'Packing'}>
                                Packing
                            </MenuItem>
                            <MenuItem className='h-100' key={-5} value={'Misc.'}>
                                Misc.
                            </MenuItem>
                            {col.options && col.options.map((item, index) => {
                                return (
                                    <MenuItem className='h-100' key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                break;
            case 'numeric':
                if(col.type === 'numeric'){
                    align='right'
                    justify='end'

                    if(value === null){
                        value = 0
                    }
                }
            case 'display-num':
                if(col.type === 'display-num'){
                    align='right'
                    justify= 'end'
                    value = parseFloat((values[index]['unit_line_price'] * (parseFloat(values[index]['qty']))).toFixed(2))

                }
            case 'text':
                cellChild= (
                    <InputBase
                        type={type}
                        disabled={col.disabled}    
                        value={value}
                        onChange={col.onChange? col.onChange(index, col.id) :handleChange(index, col.id)}
                        className={classes.textfield}                                    
                        inputProps={{
                            style: { textAlign: align, fontSize: 12, padding: 10, height: '100%', borderRadius: 0}
                        }}          
                    />                              
                )
                break;
        
            default:
                break;
        }

        return cellChild
    }

    //funtion to get the number of days to pay from the payment term
    function getDaystoPay(term) {
        let num 
        if(props.paymentTerms){
            //loop through the paymentTerms object
            props.paymentTerms.map(item => {
                if(term === item.payment_terms){
                    num = item.days_to_pay
                }
            })
        }

        return parseFloat(num)
    }

    //function for calculating the total amount
    function calcTotalAmount(){
        //calculate all of the total amount by summing up all of the total prices
        let sum = 0
        values.map(item=> {
            sum += item.unit_line_price * (parseFloat(item.qty))
        })

        return parseFloat(sum.toFixed(2))
    }

    //function for returning the textfield type
    function getTopInputs(col) {
        let cellChild, type, align='left', justify='start', value =invoiceVals[col.id]
        switch (col.type) {
            case 'bool':
                cellChild=
                <div className='w-100 d-flex flex-center'>
                    <Checkbox
                        checked={value}
                        onChange={handleCheck(col.id)}
                        inputProps={{
                        'aria-label': 'primary checkbox',
                        
                        }}
                    />
                </div>
                break;
            
            case 'select':
                cellChild =
                    <FormControl className='w-100  px-2' style={{height: '40px'}}>
                        <Select
                            name='select'
                            value={value}
                            onChange={handleTopChange(col.id)}
                            displayEmpty
                            className='h-100'
                            input={<InputBase />}
                           
                        >
                            <MenuItem key={-1} value={""} disabled >
                                {'Select ' + col.header}
                            </MenuItem>
                            {col.options.map((option, index) => {
                                    return (
                                        <MenuItem className='h-100' key={index} value={option.value}>
                                            {option.header}
                                        </MenuItem>
                                    );
                            })}
                        </Select>
                    </FormControl>
                break;
            case 'date': 
                type='date'
                if(col.id === 'due_date'){
                    value = moment(invoiceVals.invoice_date).add(getDaystoPay(invoiceVals.payment_terms), 'days').format('YYYY-MM-DD')
                }
            case 'numeric':
                if(col.type === 'numeric'){
                    align='right'
                    justify='end'
                }
            case 'display-num':
                if(col.type === 'display-num'){
                    align='right'
                    justify= 'end'
                    
                    value = calcTotalAmount()
                }
            case 'text':
                cellChild= (
                    <InputBase
                        type={type}         
                        value={value}
                        onChange={handleTopChange(col.id)}
                        error={col.required && submitted && value=== null}
                        className={classes.textfield}  
                        disabled={col.disabled}    
                        inputProps={{
                            style: { textAlign: align, fontSize: 12, padding: 10, height: '100%', borderRadius: 0}
                        }}          
                    />                              
                )
                break;
        
            default:
                break;
        }

        return cellChild
    }

    //function for refreshing the data
    function refresh(){
        
        props.handleRefresh()
    }

    //function to handle sorting of the table
    const handleSort = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    return(
        <>
        <BasicSnackBar
            open={message !== null}
            variant={isError ? 'error' : 'success'}
            message={message}
            handleClose={closeAlert}
        />
        {props.vendorBills && values?
        <>
            {props.mode && 
                <div className={`w-100 text-start text-${modeToColor[props.mode]}`}>
                    <span class={`svg-icon pb-1 svg-icon-${modeToColor[props.mode]} svg-icon-md`}>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"/>
                                <path d="M12,16 C14.209139,16 16,14.209139 16,12 C16,9.790861 14.209139,8 12,8 C9.790861,8 8,9.790861 8,12 C8,14.209139 9.790861,16 12,16 Z M12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 Z" fill="#000000" fill-rule="nonzero"/>
                            </g>
                        </svg>
                    </span>
                    {`${modeToTitle[props.mode]}`}
                    <span className='text-muted ml-1'>{`(${count} Item${count === 1? '': 's'})`}</span>
                </div>
            }
            <div class="table-responsive w-100">
                <table class="table" style={{width: '99%'}}>
                    <thead>
                        <tr style={{ top: 0, left: 0, zIndex: 2, position: 'sticky', backgroundColor: '#ffffff' }}>
                            {props.topColumns.map(col => {
                                return(
                                    <th class="hidden text-center font-weight-bolder ">{col.header}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody >
                        <tr class="font-weight-bold ">                                           
                            {props.topColumns.map(col => {
                                return(
                                    <td class={"p-0 border border-2 "} style={col.style}>
                                        {getTopInputs(col)}                      
                                    </td>
                                )
                            })}  
                        </tr>   
                    </tbody>
                </table>
            </div>
            <div class="table-responsive w-100" style={{ height: "calc(100% - 200px)", position: "relative" }}>
                <table class="table" style={{width: '99%'}}>
                    <thead>
                        <tr style={{ top: 0, left: 0, zIndex: 2, position: 'sticky', backgroundColor: '#ffffff' }}>
                            {props.columns.map(col => {
                                return(
                                    <th class="hidden text-center font-weight-bolder ">
                                        <TableSortLabel
                                            active={orderBy.id === col.id}
                                            direction={order}
                                            onClick={handleSort(col)}
                                        >
                                            {col.header}
                                        </TableSortLabel>
                                        
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody >
                   
                        { values.length > 0 ?                           
                            values.map((row, index) => { 
                                return (
                                    <>
                                        <tr class="font-weight-bold bg-hover-light-primary">                                           
                                            {props.columns.map(col => {
                                                
                                                return(
                                                    <td class={"p-0 border border-2 "} style={col.style}>
                                                        {getBottomInputs(index, col)}                      
                                                    </td>
                                                )
                                            })}  
                                        </tr>
                                    </>
                                )
                            })
                        :
                            <tr>
                                <td class="pt-7 ">
                                    <div className='w-100 d-flex flex-center'>
                                    No data available
                                    </div>
                                    
                                </td>       
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div class="w-100 pt-10 d-flex justify-content-between">
                <div>
                    <button type="button" disabled={ props.mode ==='ALL' }  class="mr-3 btn btn-light-danger font-weight-bold" onClick={refresh}>Reset All</button>
                    <button type="button" disabled={changeIds.length === 0 || props.mode ==='ALL'} class="btn btn-primary font-weight-bold" onClick={handleSubmit}>Save Changes</button>
                </div>
                <div>
                    {
                        (props.mode !== 'POST') && (<button type="button" class="btn btn-light-success font-weight-bold mr-3" onClick={props.addInvoice}>New Invoice</button>)
                    }
                    {props.mode !== 'ALL' && <button type="button" class="btn btn-success font-weight-bold" onClick={addRow}>Add Row</button>}
                </div>
                

            </div>

        </>
        
        :
            <div className="w-100 h-100 d-flex flex-column flex-center ">
                <h5>Loading Data...</h5>
                <div><CircularProgress color="secondary" /></div>
            </div>
        }

        </>
        )
        
}