import React, { useState, useEffect } from 'react';
import ProductTable from '../../components/Tables/ProductTable/ProductTable';
import { getTableData } from '../../components/Tables/ProductTable/TableData';

export default function OrderQueue() {
    const [tableData, setTableData] = useState(null);

    //********EFFECTS********* */
    //effect for calling the endpoint to get the correct data for the table
    //stores the response data in state as 'tableData'
    useEffect(() => {
        getTableData('OrderQueue' ).then(({data}) => { 
            setTableData(data);      
        }).catch(function(error){
            setTableData([]);
            console.log(error);
        })
    }, []);

  
    return (<>
        <div>
            <ProductTable
                title="Order Queue"
                data={tableData}
                rows={25}
                cols={7}
                columns={[
                    {
                        type: 'date',
                        format: 'ddd, MMM Do @h:mm a',
                        id: 'blast_date',
                        header: 'Blast Date',
                    },
                    {
                        id: 'order_id',
                        header: 'Order ID',
                        type: 'text'
                    },
                    {
                        type: 'date',
                        format: 'ddd, MMM Do @h:mm a',
                        id: 'purchase_date',
                        header: 'Purchase Date',
                    },
                    {
                        id: 'total_price',
                        header: 'Order Amount',
                        type: 'numeric',
                        decimals: 2
                    },
                    {
                        id: 'ship_country',
                        header: 'Customer Country',
                        type: 'text'
                    },
                    {
                        id: 'seller_account',
                        header: 'Seller Account',
                        type: 'text'
                    },
                ]}
            
            />
        </div>
    </>);
}