import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ProductTable from "../../components/Tables/ProductTable/ProductTable";
import PieChart from "../../components/Charts/PieChart";
import PeriodSelectWidget from "../../components/Widgets/PeriodSelectWidget";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { BrandSettingsTile } from "../../components/Tiles/BrandSettingsTile";
import SelectWidget from "../../components/Widgets/SelectWidget";
import moment from "moment";

export default function BrandOverview() {
  //state variables for the tables
  const [vcReorder, setVCReorder] = useState(null);
  const [fbaReorder, setFBAReorder] = useState(null);
  const [fbaNew, setFBANew] = useState(null);
  const [sales, setSales] = useState(null);
  const [invItems, setInvItems] = useState(null);
  const [invSummary, setInvSummary] = useState(null);
  const [salesSummary, setSalesSummary] = useState(null);
  const [itemReorder, setItemReorder] = useState(null);

  //other const variables
  const base_url = process.env.REACT_APP_API_URL;
  const endpoints = {
    vcReorder: { ep: "inventory-loc-vc-reorder/?brand=", set: setVCReorder },
    fbaReorder: { ep: "inventory-woc-analysis/?brand=", set: setFBAReorder },
    fbaNew: { ep: "expand-existing-brands/?brand=", set: setFBANew },
    sales: {
      ep: `sales-item-daily/?start_date=${moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD")}&brand=`,
      set: setSales,
    },
    invItems: {
      ep: "inventory-brand-summary/?type=items&brand=",
      set: setInvItems,
    },
    invSummary: {
      ep: "inventory-brand-summary/?type=summary&brand=",
      set: setInvSummary,
    },
    salesSummary: {
      ep: `sales-item-daily/?start_date=${moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD")}&type=summary&brand=`,
      set: setSalesSummary,
    },
    itemReorder: {
      ep: "inventory-loc-combined-reorder/?type=summary&brand=",
      set: setItemReorder,
    },
  };

  const days = [
    { value: 7, label: "7 Days" },
    { value: 30, label: "30 Days" },
    { value: 90, label: "90 Days" },
    { value: 180, label: "180 Days" },
    { value: 365, label: "365 Days" },
    { value: "lw", label: "Last Week" },
    { value: "cw", label: "Current Week" },
    { value: "lm", label: "Last Month" },
    { value: "cm", label: "Current Month" },
    { value: "ly", label: "Last Year" },
    { value: "cy", label: "Current Year" },
  ];

  //state variable for the select
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedDay, setSelectedDay] = useState("30 Days");

  const initialData = {
    startDate: moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };

  //variable for storing the data for the selected brands
  const [brandData, setBrandData] = useState({});

  //*************EFFECTS************* */

  //effect for getting all of the data for each table and select
  useEffect(() => {
    //call for the data in the brand select
    let newData = [];
    axios
      .get(base_url + "catalog-base-sku/?type=brands")
      .then(({ data }) => {
        // get the brands first
        // make the object to be passed in to the select, it needs a value and lable key
        data.brand_list.map((brand) => {
          newData.push({ value: brand, label: brand });
        });
        setBrands(newData);
      })
      .catch(function(error) {
        console.log(error);
        setBrands([]);
      });

    //initialize each table without brand query
    let newBrandData = { ...brandData };
    let newBrandEntry = {};
    for (const key in endpoints) {
      if (endpoints.hasOwnProperty(key)) {
        let endpoint = endpoints[key].ep.slice(0, -7); //take out the 'brand= from each enpoint to initialize
        axios
          .get(base_url + endpoint)
          .then(({ data }) => {
            if (key === "invSummary") {
              //this enpoint comes back as an object, so you have to format as an array
              let newData = [];
              newData.push(parseFloat(data.onhand_inventory.toFixed(0)));
              newData.push(parseFloat(data.onorder_inventory.toFixed(0)));
              newData.push(parseFloat(data.fba_inventory.toFixed(0)));
              newData.push(parseFloat(data.committed_inventory.toFixed(0)));
              endpoints[key].set(newData);
              newBrandEntry[key] = newData;
            } else if (key === "salesSummary") {
              let newData = [0, 0];
              data.map((item) => {
                if (item.channel === "SC") {
                  newData[0] = parseFloat(item.sold_amount.toFixed(0));
                } else {
                  newData[1] = parseFloat(item.sold_amount.toFixed(0));
                }
              });

              endpoints[key].set(newData);
              newBrandEntry[key] = newData;
            } else {
              newBrandEntry[key] = data;
              endpoints[key].set(data);
            }
          })
          .catch(function(error) {
            endpoints[key].set([]);
            console.log(error);
          });
      }
    }

    newBrandData["initial"] = newBrandEntry;
    setBrandData(newBrandData);
  }, []);

  useEffect(() => {}, [selectedBrand]);

  //*************METHODS************* */

  //function for setting the tables to data that has already be stored from previous brands
  function restoreTables(brand) {
    let brandOBJ = brandData[brand];

    for (const key in brandOBJ) {
      if (brandOBJ.hasOwnProperty(key)) {
        endpoints[key].set(brandOBJ[key]);
      }
    }
  }

  //function for initializing the tables
  //calls each enpoint for each table, but takes out the brand param
  function initializeTables() {
    setSelectedBrand(null);
    setSelectedDay(initialData);
    restoreTables("initial");
  }

  //funtion for switching the table data based on the brand selected
  function handleSelect(brand) {
    setSelectedBrand(brand);
    setSelectedDay(initialData);
    //check to see if the brand has already been selected, we stored the data for all tables already
    if (typeof brandData[brand] !== "undefined") {
      restoreTables(brand);
    } else {
      let newBrandData = { ...brandData };
      let newBrandEntry = {};
      for (const key in endpoints) {
        if (endpoints.hasOwnProperty(key)) {
          //loop through the endpoints object to call each enpoint and set the state accordinglyt
          axios
            .get(base_url + endpoints[key].ep + brand)
            .then(({ data }) => {
              if (key === "invSummary") {
                //this enpoint comes back as an object, so you have to format as an array
                let newData = [];
                newData.push(parseFloat(data.onhand_inventory.toFixed(0)));
                newData.push(parseFloat(data.onorder_inventory.toFixed(0)));
                newData.push(parseFloat(data.fba_inventory.toFixed(0)));
                newData.push(parseFloat(data.committed_inventory.toFixed(0)));
                endpoints[key].set(newData);
                newBrandEntry[key] = newData;
              } else if (key === "salesSummary") {
                let newData = [0, 0];
                data.map((item) => {
                  if (item.channel === "SC") {
                    newData[0] = parseFloat(item.sold_amount.toFixed(0));
                  } else {
                    newData[1] = parseFloat(item.sold_amount.toFixed(0));
                  }
                });
                endpoints[key].set(newData);
                newBrandEntry[key] = newData;
              } else {
                endpoints[key].set(data);
                newBrandEntry[key] = data;
              }
            })
            .catch(function(error) {
              endpoints[key].set([]);
              console.log(error);
            });
        }
      }
      //store the data for the brand
      newBrandData[brand] = newBrandEntry;
      setBrandData(newBrandData);
    }
  }

  //function for handling the select in the sales table and pie chart
  function handleSalesKey(key) {
    setSelectedDay(key);
    axios
      .get(
        base_url +
          `sales-item-daily/?start_date=${key.startDate}&end_date=${
            key.endDate
          }${selectedBrand ? "&brand=" + selectedBrand : ""}`
      )
      .then(({ data }) => {
        setSales(data);
      })
      .catch(function(error) {
        setSales([]);
        console.log(error);
      });

    axios
      .get(
        base_url +
          `sales-item-daily/?start_date=${key.startDate}&end_date=${
            key.endDate
          }&type=summary${selectedBrand ? "&brand=" + selectedBrand : ""}`
      )
      .then(({ data }) => {
        let newData = [0, 0];
        data.map((item) => {
          if (item.channel === "SC") {
            newData[0] = parseFloat(item.sold_amount.toFixed(0));
          } else {
            newData[1] = parseFloat(item.sold_amount.toFixed(0));
          }
        });
        setSalesSummary(newData);
      })
      .catch(function(error) {
        setSalesSummary([]);
        console.log(error);
      });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <div className="d-flex flex-center w-100">
          <SelectWidget
            title="Select a Brand"
            placeholder="Brand"
            keywords={brands}
            refresh={initializeTables}
            handleSelect={handleSelect}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="d-flex flex-center w-100">
          <PeriodSelectWidget
            handleSubmit={handleSalesKey}
            handleRefresh={() =>
              handleSalesKey({
                startDate: moment()
                  .subtract(30, "days")
                  .format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
              })
            }
          />
        </div>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ProductTable
          title={`Sales`}
          data={sales}
          small
          orderBy={{ id: "sold_amount", type: "numeric" }}
          order={"desc"}
          rows={5}
          columns={[
            {
              id: "image_links",
              header: "",
              type: "image",
            },
            {
              anchor: "https://www.amazon.com/dp/",
              tag: "asin",
              id: "asin",
              header: "ASIN",
              type: "anchor",
            },
            {
              id: "sku",
              header: "SKU",
              type: "text",
            },
            {
              id: "sold_units",
              header: "Units",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "sold_amount",
              header: "Amount",
              type: "numeric",
              decimals: 0,
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <div className="card card-custom card-stretch ">
          <div className="px-5 mt-4" style={{ fontSize: 14 }}>
            {`Sales Summary `}
          </div>
          {salesSummary ? (
            <span className="d-flex flex-center w-100">
              <PieChart
                name="salessumm"
                data={salesSummary}
                labels={["SC", "VC"]}
              />
            </span>
          ) : (
            <div className="w-100 d-flex flex-column flex-center ">
              <h5>Loading Data...</h5>
              <div>
                <CircularProgress color="secondary" />
              </div>
            </div>
          )}
        </div>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ProductTable
          title="Inventory Items"
          data={invItems}
          orderBy={{ id: "onhand_qty", type: "numeric" }}
          order={"desc"}
          small
          rows={5}
          columns={[
            {
              id: "base_sku",
              header: "Base SKU",
              type: "text",
            },
            {
              id: "onhand_qty",
              header: "Onhand Quantity",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "onorder_qty",
              header: "Onorder Quantity",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "fba_qty",
              header: "FBA Quantity",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "committed_qty",
              header: "Committed Quantity",
              type: "numeric",
              decimals: 0,
            },
          ]}
        />
      </Grid>
      <Grid Grid item xs={12} md={6} lg={4}>
        <div className="card card-custom card-stretch ">
          <div className="px-5 mt-4" style={{ fontSize: 14 }}>
            Inventory Summary
          </div>
          {invSummary ? (
            <span className="d-flex flex-center w-100">
              <PieChart
                name="sumamry"
                data={invSummary}
                labels={["Onhand", "Onorder", "FBA", "Committed"]}
              />
            </span>
          ) : (
            <div className="w-100 d-flex flex-column flex-center ">
              <h5>Loading Data...</h5>
              <div>
                <CircularProgress color="secondary" />
              </div>
            </div>
          )}
        </div>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ProductTable
          title="Item Reorder"
          data={itemReorder}
          small
          rows={5}
          columns={[
            {
              id: "image_links",
              header: "",
              type: "image",
            },
            {
              id: "base_sku",
              header: "Base SKU",
              type: "text",
            },
            {
              anchor: "https://www.amazon.com/dp/",
              tag: "asin",
              id: "asin",
              header: "ASIN",
              type: "anchor",
            },
            {
              id: "brand",
              header: "Brand",
              type: "text",
            },
            {
              id: "landed_pcogs",
              header: "Landed PCOGS",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "reorder_qty",
              header: "Reorder QTY",
              type: "numeric",
              decimals: 0,
            },
            {
              id: "intended_channel",
              header: "Intended Channel",
              type: "text",
            },
            {
              id: "generated_by",
              header: "Generated By",
              type: "text",
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <BrandSettingsTile className="card-stretch" brand={selectedBrand} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ProductTable
          title="VC Reorder"
          data={vcReorder}
          small
          rows={5}
          columns={[
            {
              anchor: "https://www.amazon.com/dp/",
              tag: "asin",
              id: "asin",
              header: "ASIN",
              type: "anchor",
            },
            {
              id: "sku",
              header: "sku",
              type: "text",
            },
            {
              id: "new_order_qty",
              header: "Order Quantity",
              type: "numeric",
              decimals: 0,
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ProductTable
          title="FBA Reorder"
          data={fbaReorder}
          small
          rows={5}
          columns={[
            {
              anchor: "https://www.amazon.com/dp/",
              tag: "asin",
              id: "asin",
              header: "ASIN",
              type: "anchor",
            },
            {
              id: "sku",
              header: "SKU",
              type: "text",
            },
            {
              id: "reorder_qty",
              header: "Reorder Quantity",
              type: "numeric",
              decimals: 0,
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ProductTable
          title="FBA New Items"
          data={fbaNew}
          small
          rows={5}
          columns={[
            {
              anchor: "https://www.amazon.com/dp/",
              tag: "asin",
              id: "asin",
              header: "ASIN",
              type: "anchor",
            },
            {
              id: "sku",
              header: "SKU",
              type: "text",
              lim: 9,
            },
            {
              id: "profit_margin",
              header: "Profit Margin",
              type: "numeric",
              decimals: 2,
            },
            {
              id: "review_count",
              header: "Review Count",
              type: "numeric",
              decimals: 2,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
