import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { KTUtil } from "../../../_metronic/_assets/js/components/util";
import { useHtmlClassService } from "../../../_metronic/layout";

export default function SplineChart(props) {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(
        uiService.config,
        "js.colors.gray.gray100"
      ),
      colorsGrayGray700: objectPath.get(
        uiService.config,
        "js.colors.gray.gray700"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("spline_chart_" + props.name);
    if (!element) {
      return;
    }

    const height = parseInt(KTUtil.css(element, "height"));
    const options = getChartOptions(
      props.data1,
      props.data2,
      props.name1,
      props.name2,
      props.categories,
      props.color,
      props.xtitle,
      props.ytitle1,
      props.ytitle2,
      props.chartTitle
    );

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, props.data]);

  return (
    <div
      id={"spline_chart_" + props.name}
      style={{ padding: 15, paddingLeft: 10 }}
    ></div>
  );
}

function getChartOptions(
  data1,
  data2,
  name1,
  name2,
  categories,
  color,
  xaxis,
  yaxis1,
  yaxis2,
  chartTitle
) {
  let tick1 = 6,
    dif1 = Math.max(...data1) - 1;

  if (dif1 < 5 && dif1 > 0) {
    tick1 = dif1 + 1;
  } else if (dif1 === 0) {
    tick1 = 1;
  }

  let tick2 = 6,
    dif2 = Math.max(...data2) - 1;

  if (dif2 < 5 && dif2 > 0) {
    tick2 = dif2 + 1;
  } else if (dif2 === 0) {
    tick2 = 1;
  }

  const options = {
    series: [
      {
        name: name1,
        data: data1,
      },
      {
        name: name2,
        data: data2,
      },
    ],
    chart: {
      height: 280,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    colors: color,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: chartTitle,
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    yaxis: [
      {
        title: {
          text: yaxis1,
        },
        min: 0,
        max: Math.max(...data1),
        tickAmount: tick1,
        labels: {
          formatter: function(val) {
            return val.toFixed(0);
          },
        },
      },
      {
        opposite: true,
        title: {
          text: yaxis2,
        },
        min: 0,
        max: Math.max(...data2),
        tickAmount: tick2,
        labels: {
          formatter: function(val) {
            return val.toFixed(0);
          },
        },
      },
    ],
    xaxis: {
      categories: categories,
      title: {
        text: xaxis,
        offsetX: -15,
      },
    },
  };
  return options;
}
