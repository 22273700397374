import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function Sellers(){
    const base_url = process.env.REACT_APP_API_URL

    const fields={
        //SELLERS----------------------
        seller_name: {
            required: true,
            id: 'seller_name',
            header: 'Seller Name',
            label: 'Seller Name',
            type: 'text',
            ip: {maxLength: 100}
        },
        seller_id: {
            required: true,
            id: 'seller_id',
            header: 'Seller ID',
            label: 'Seller ID',
            type: 'text',
            ip: {maxLength: 100}
        },
        source:{
            required: true,
            id: 'source',
            header: 'Source',
            label: 'Source',
            type: 'text',
            ip: {maxLength: 100}
        },
        active:{
            required: true,
            id: 'active',
            header: 'Active',
            label: 'Active',
            type: 'bool',
        },
        last_scrape_date:{
            noEdit: true,
            id: 'last_scrape_date',
            header: 'Last Scrape Date',
            label: 'Last Scrape Date',
            type: 'date',
            format: 'MM/DD/YYYY',
        },
        //TABLE ONLY-----------
        edit: {
            header: 'Action',
            type: 'edit'
        }
    }

    const requiredSellers = [
        'seller_name',
        'seller_id',
        'active',
        'source',
    ]



    return(
        <PageComponent
            tableTitle="Sellers List"
            url={base_url+'scraper-seller-queue/'}
            checkbox
            deleteItems
            addItems
            import
            bulkAdd
            bulkEdit
            requiredEditFields={requiredSellers}
            requiredCSVColumns={requiredSellers}
            formInputs={[fields.seller_name, fields.seller_id, fields.source, fields.active, fields.last_scrape_date]}
            tableColumns={[fields.seller_name, fields.seller_id, fields.source, fields.active, fields.last_scrape_date, fields.edit]}
        />
    )
}