// Rendered in the ProductTable if the small props is false
// Opens an area under the Toolbar which consists of a set of textfields to filter the table data 
// Uses the tableColumns props passed into the ProductTable



import React, { useState, useEffect } from 'react';
import DateFilter from './DateFilter';
import NumericalFilter from './NumericalFilter';
import { Collapse, TextField, Select, Grid, Button, Icon, MenuItem, FormControl} from '@material-ui/core';



export default function Filters(props) {
    const {filters} = props;


    return(
        <div className={`w-100  border-top `}>
            <Collapse in={props.showFilter}>
                <form className='w-100 p-8' onSubmit={props.handleFilter}>
                    <Grid container spacing={2} className='w-100'>
                        {props.columns.map((col, index) => {
                            let cellChild
                            if(col.noFilter){
                                return
                            }
                            switch(col.type){
                                case 'select':
                                case 'bool-icon':
                                case 'bool':
                                    cellChild =     <FormControl className='w-100 '>     
                                                        <Select
                                                            value={filters[col.id] !== null && typeof filters[col.id] !== 'undefined'? filters[col.id]: ''}
                                                            name="Bool"
                                                            onChange={props.handleFilterChange(col.id)}
                                                            displayEmpty

                                                        >
                                                        <MenuItem value="" disabled>
                                                            <span className='text-muted'>Select</span>
                                                        </MenuItem>
                                                        {col.type === 'bool' || col.type ==='bool-icon'?
                                                           [ <MenuItem value={true}> Yes</MenuItem>,
                                                            <MenuItem value={false}> No</MenuItem>]
                                                        :
                                                            col.options.map(option => {
                                                                return(
                                                                    <MenuItem value={option.value}> {option.label}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                        </Select>
                                                    </FormControl>
                                    break;
                                case 'modal':
                                case 'anchor':
                                case 'link':
                                case 'text':
                                    cellChild = <TextField
                                                    value={filters[col.id]? filters[col.id]: ''}
                                                    placeholder={'Enter ' + col.header}
                                                    onChange={props.handleFilterChange(col.id)}                                    
                                                    className='w-100'
                                                />
                                    break;
                                case 'numeric':
                                    cellChild = <NumericalFilter
                                                    label={col.header}
                                                    value={filters[col.id]? filters[col.id]: ''}
                                                    onChange={props.handleOpChange}
                                                    id={col.id}
                                                />
                                    break;
                                case 'date': 
                                    cellChild = <DateFilter
                                                    label={col.header}
                                                    value={filters[col.id]? filters[col.id]: ''}
                                                    onChange={props.handleOpChange}                                    
                                                    id={col.id}
                                                />
                                    break;         

                                default:
                                    return(null)

                            }
                            return(
                                <Grid item xs={3}>
                                    <div style={{fontSize: 11.375, fontWeight: 500}} className='mb-2'>{col.header}:</div>
                                    {cellChild}
                                </Grid>
                            )
                        })}
                    </Grid>

                <div className='w-100 d-flex flex-row mb-3 mt-5'>
                    <Button variant="contained" color="default" onClick={props.clearFilters} className='px-8'>           
                    Clear
                    <Icon>clear</Icon>
                    </Button>
                    <Button  variant="contained" color="secondary" type='submit' className='px-8 ml-2 mr-5'>          
                    Apply
                    <Icon>add</Icon>
                    </Button>
                </div>
            </form>
            </Collapse>
      </div>
    )
}