import React, { useState, useEffect } from 'react';
import ProductTable from '../../components/Tables/ProductTable/ProductTable';
import { getTableData } from '../../components/Tables/ProductTable/TableData';

export default function FBALatest() {
    const [tableData, setTableData] = useState([{}]);

    //********EFFECTS********* */

    //effect for calling the endpoint to get the correct data for the table
    //stores the response data in state as 'tableData'
    useEffect(() => {
        getTableData('FBALatest' ).then(({data}) => { 
            setTableData(data);      
        }).catch(function(error){
            setTableData([]);
            console.log(error);
        })
    }, []);


  
    return (
        <>
        <div>
            <ProductTable
                title="FBA Latest"
                data={tableData}
                rows={25}
                cols={11}
                columns={[
                    {
                        anchor: 'https://www.amazon.com/dp/',
                        tag: 'asin',
                        id: 'asin',
                        header: 'ASIN',
                        type: 'anchor'
                    },
                    {
                        id: 'seller_sku',
                        header: 'Seller SKU',
                        type: 'text'
                    },
                    {
                        id: 'seller_account',
                        header: 'Seller Account',
                        type: 'text',
                    },
                    {
                        id: 'item_name',
                        header: 'Item Name',
                        type: 'text'
                    },
                    {
                        id: 'total_current_inv',
                        header: 'Current Inventory',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'current_inbound',
                        header: 'Current Inbound',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'current_available',
                        header: 'Current Available',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'current_fc_transfer',
                        header: 'Current FC Transfer',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'current_customer_orders',
                        header: 'Current Customer Orders',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'current_unfulfillable',
                        header: 'Current Unfillable',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        type: 'date',
                        format: 'ddd, MMM Do @h:mm a',
                        id: 'snapshot_date',
                        header: 'Snapshot Date',
                    },

                ]}
            
            />
        </div>
    </>);
}