import React, { useState, useEffect } from "react";
import { Grid, LinearProgress } from "@material-ui/core";
import axios from "axios";
import BasicTable from "../../components/Tables/BasicTable";
import PageComponent from "../../components/PageComponent";

export default function CombinedReorders() {
  const url = `${process.env.REACT_APP_API_URL}inventory-loc-combined-reorder/`;
  const [tileData, setTileData] = useState(null);

  //get the data for the top tiles
  useEffect(() => {
    let source = axios.CancelToken.source();
    axios
      .get(url + "?type=channel", { cancelToken: source.token })
      .then(({ data }) => {
        setTileData(data);
      })
      .catch(function(error) {
        if (axios.isCancel(error)) {
          console.log("REQUEST CANCELLED");
        } else {
          setTileData([]);
          console.log(error);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  //adds "," to numbers, making it more readable
  function formatValue(value) {
    let num = parseFloat(value).toFixed(2); //turn it into a number with decimals
    let str = num.toString().split("."); //turn back to a string and split by decimal point
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); //add commas to the string
    return str.join(".");
  }

  return (
    <>
      <Grid container spacing={2}>
        {tileData ? (
          tileData.map((item) => {
            return (
              <Grid item xs={12} md={4}>
                <div className="card card-custom w-100 h-100">
                  <div className="card-body ">
                    <div className="card-title font-weight-boldest font-size-lg">
                      {item.generated_by}
                    </div>
                    <div className="d-flex flex-row justify-content-between px-20">
                      {item.generated_by === "VC PO" ? (
                        <>
                          <span className="font-weight-bolder">SKU Count:</span>
                          <span>{item.sku_count}</span>
                        </>
                      ) : (
                        <>
                          <span className="font-weight-bolder">
                            ASIN Count:
                          </span>
                          <span>{item.asin_count}</span>
                        </>
                      )}
                    </div>
                    <div className="d-flex flex-row justify-content-between px-20">
                      <span className="font-weight-bolder ">Brand Count:</span>
                      <span>{item.brand_count}</span>
                    </div>
                    <div className="d-flex flex-row justify-content-between px-20">
                      <span className="font-weight-bolder ">Total Value:</span>
                      <span>${formatValue(item.total_value)}</span>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })
        ) : (
          <div className="w-100 my-20 d-flex flex-column flex-center">
            <h4>Loading Data...</h4>
            <div className="w-75">
              <LinearProgress color="secondary" />
            </div>
          </div>
        )}
        <Grid item xs={12}>
          <PageComponent
            tableTitle="Combined Reorders"
            url={url}
            tableColumns={[
              {
                id: "image_links",
                header: "",
                type: "image",
              },
              {
                id: "base_sku",
                header: "Base SKU",
                type: "text",
              },
              {
                anchor: "https://www.amazon.com/dp/",
                tag: "asin",
                id: "asin",
                header: "ASIN",
                type: "anchor",
              },
              {
                id: "brand",
                header: "Brand",
                type: "text",
              },
              {
                id: "landed_pcogs",
                header: "Landed PCOGS",
                type: "numeric",
                decimals: 2,
              },
              {
                id: "order_qty",
                header: "Order QTY",
                type: "numeric",
                decimals: 0,
              },
              {
                id: "intended_channel",
                header: "Intended Channel",
                type: "text",
              },
              {
                id: "kit",
                header: "Kit",
                type: "bool-icon",
              },
              {
                id: "generated_by",
                header: "Generated By",
                type: "text",
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
}
