import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import Adjustments from "../modules/LocalInventory/Adjustments";
import DirectShip from "../modules/LocalInventory/DirectShip";
import FBATransfer from "../modules/LocalInventory/FBATransfer";
import IdleInventory from "../modules/LocalInventory/IdleInventory";
import InventoryHealth from "../modules/LocalInventory/InventoryHealth";
import InventorySummary from "../modules/LocalInventory/InventorySummary";
import InventoryValuation from "../modules/LocalInventory/InventoryValuation";
import Status from "../modules/LocalInventory/Status";

export default function LocalInventoryPage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/local-inventory"
        to="/local-inventory/adjustments"
      />
            <ContentRoute
        path="/local-inventory/inventory-valuation"
        component={InventoryValuation}
      />

      <ContentRoute
        path="/local-inventory/adjustments"
        component={Adjustments}
      />
      <ContentRoute
        path="/local-inventory/direct-ship"
        component={DirectShip}
      />

      <ContentRoute
        path="/local-inventory/fba-transfer"
        component={FBATransfer}
      />

      <ContentRoute
        path="/local-inventory/summary"
        component={InventorySummary}
      />

      <ContentRoute
        path="/local-inventory/inventory-health"
        component={InventoryHealth}
      />

      <ContentRoute
        path="/local-inventory/inventory-status"
        component={Status}
      />

      <ContentRoute
        path="/local-inventory/idle-inventory"
        component={IdleInventory}
      />

    </Switch>
  );
}