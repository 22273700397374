import React, {useMemo, useEffect} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { KTUtil } from "../../../_metronic/_assets/js/components/util";
import { useHtmlClassService } from "../../../_metronic/layout";


export default function PieChart(props){

    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
            colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
            colorsThemeBaseSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.base.success"
            ),
            colorsThemeLightSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.light.success"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
    }, [uiService]);

    useEffect(() => {
        const element = document.getElementById("pie_chart_"+ props.name);
        if (!element) {
        return;
        }

        const height = parseInt(KTUtil.css(element, 'height'));
        const options = getChartOptions(props.data, props.name, props.labels, props.color);

        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [layoutProps, props.data]);




    return(
        <div id={"pie_chart_"+ props.name } style={{padding: 15, paddingLeft: 10}}></div>
    );
}


function getChartOptions(data ,name, labels, color) {

    const options = {    
        series: data,
        chart: {
            width: 325,
            type: 'pie',
        },
        labels: labels,
        dataLabels:{
            formatter: function (val, opts) {
                let num = data[opts.seriesIndex]
                let str = num.toString().split(".");                    //turn back to a string and split by decimal point
                str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");  //add commas to the string 
                let value =  str.join(".");                             //join the decimal to the st
                return value 
              }
        },
        legend: {
            show: true,
            position: 'bottom'
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },

            }
        }]
    };
    return options;
  }