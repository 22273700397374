import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import SelectWidget from "../../components/Widgets/SelectWidget";
import ProductTable from "../../components/Tables/ProductTable/ProductTable";
import PageComponent from "../../components/PageComponent";
import axios from "axios";

function ItemCogs() {
  const base_url = process.env.REACT_APP_API_URL;
  const [asinData, setAsinData] = useState(null);
  const [channelData, setChannelData] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");

  //keywords for the select widgets
  const [brands, setBrands] = useState([]);
  const days = [
    { value: 7, label: "7 Days" },
    { value: 30, label: "30 Days" },
    { value: 90, label: "90 Days" },
    { value: 180, label: "180 Days" },
    { value: 365, label: "365 Days" },
    { value: "lw", label: "Last Week" },
    { value: "cw", label: "Current Week" },
    { value: "lm", label: "Last Month" },
    { value: "cm", label: "Current Month" },
    { value: "ly", label: "Last Year" },
    { value: "cy", label: "Current Year" },
  ];

  useEffect(() => {
    //call for the data in the brand select
    let newData = [];
    axios
      .get(base_url + "catalog-base-sku/?type=brands")
      .then(({ data }) => {
        // get the brands first
        // make the object to be passed in to the select, it needs a value and lable key
        data.brand_list.map((brand) => {
          newData.push({ value: brand, label: brand });
        });
        setBrands(newData);
      })
      .catch(function(error) {
        console.log(error);
        setBrands([]);
      });
  }, []);

  //refreshes the tables data when a new option is selected
  useEffect(() => {
    //make the object to loop through
    let endpoints = [
      { type: "asin", set: setAsinData },
      { type: "channel", set: setChannelData },
    ];

    //loop through the object and save the data
    endpoints.map((endpoint) => {
      axios
        .get(
          base_url +
            `financials-item-cogs/?brand=${selectedBrand}&period=${selectedPeriod}&type=${endpoint.type}`
        )
        .then(({ data }) => {
          endpoint.set(data);
        })
        .catch(function(error) {
          console.log(error);
          endpoint.set([]);
        });
    });
  }, [selectedBrand, selectedPeriod]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <div className="d-flex flex-center flex-column h-100 justify-content-around w-100">
          <SelectWidget
            title="Select a Brand"
            placeholder="Brand"
            keywords={brands}
            refresh={() => setSelectedBrand("")}
            className="mb-2"
            handleSelect={(brand) => setSelectedBrand(brand)}
          />

          <SelectWidget
            title="Select a Period"
            placeholder="All Time"
            keywords={days}
            refresh={() => setSelectedPeriod("")}
            handleSelect={(period) => setSelectedPeriod(period)}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <ProductTable
          title="By Channel"
          small
          data={channelData}
          rows={6}
          columns={[
            {
              id: "channel",
              type: "text",
              header: "Channel",
            },
            {
              id: "total_sales",
              type: "numeric",
              decimals: 2,
              header: "Total Sales",
            },
            {
              id: "total_cogs",
              type: "numeric",
              decimals: 2,
              header: "Total Cogs",
            },
            {
              id: "total_units",
              type: "numeric",
              decimals: 0,
              header: "Total Units",
            },
            {
              id: "profit",
              type: "numeric",
              decimals: 2,
              header: "Profit",
            },
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductTable
          title="By ASIN"
          data={asinData}
          rows={10}
          columns={[
            {
              id: "image_links",
              type: "image",
              header: "",
            },
            {
              anchor: "https://www.amazon.com/dp/",
              tag: "asin",
              id: "asin",
              header: "ASIN",
              type: "anchor",
            },
            {
              id: "item_name",
              type: "text",
              header: "Item Name",
            },
            {
              id: "total_sales",
              type: "numeric",
              decimals: 2,
              header: "Total Sales",
            },
            {
              id: "total_cogs",
              type: "numeric",
              decimals: 2,
              header: "Total Cogs",
            },
            {
              id: "total_units",
              type: "numeric",
              decimals: 0,
              header: "Total Units",
            },
            {
              id: "profit",
              type: "numeric",
              decimals: 2,
              header: "Profit",
            },
            {
              id: "margin",
              type: "numeric",
              decimals: 2,
              header: "Margin",
            },
            {
              id: "markup",
              type: "numeric",
              decimals: 2,
              header: "Markup",
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default ItemCogs;
