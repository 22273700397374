import React from "react";
import {Redirect, Switch} from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import OrderItems from "../modules/Sales/OrderItems";
import VCInvoices from "../modules/Sales/VCInvoices";


export default function SalesPage() {
    return (
        <Switch>
          <Redirect
            exact={true}
            from="/sales"
            to="/sales/order-items"
          />
    
          <ContentRoute
            path="/sales/order-items" 
            component={OrderItems} 
          />

          <ContentRoute
            path="/sales/vc-invoices" 
            component={VCInvoices} 
          />
        </Switch>
    );
}