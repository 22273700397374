// This component is rendered in the ProductTable
// Shown when a item in the tableColumns prop has type "modal"
// See the tableColumns in VCOrders.js and VCShipments.js
// this component takes a po_number as input and shows all items within that po to edit

import React, { useState, useEffect } from 'react';
import axios from 'axios'
import BasicSnackBar from '../Alerts/BasicSnackBar';
import BasicModal from './BasicModal';
import InputTable from '../Tables/InputTable';
import { Grid, CircularProgress } from '@material-ui/core';

export default function POModal(props) {
    const url =`${process.env.REACT_APP_API_URL}sales-vc-po/`
    const source = axios.CancelToken.source();
    const [modalData, setModalData] = useState(null)
    const [message, setMessage] = useState(null)
    const [isError, setIsError] = useState(false)
    const [loading, setLoading] = useState(false)

    //make the call to get the data for the same PO's
    useEffect(() => {
        getData()

        //callback function when component is unmounted
        //cancels the axios request if there is one going at the time
        return () => {
            source.cancel()
        }
    }, [props.value]);

    //function to get and set the data for the endpoint
    function getData(){
        setLoading(true)
        axios.get(url + "?po=" +props.value, {cancelToken: source.token}).then(({data}) =>{
            //get all the rows with the same PO number 
            
            let sameRows = []
            data.results.map(row => {
                if (row.po_number === props.value) {
                    sameRows.push(row)
                }
            })
            //order by vc title
            sameRows.sort((a,b) => {
                if(a.vc_title > b.vc_title){
                return 1
                }
                return -1
            })
            setLoading(false)
            setModalData(sameRows)
        }).catch(function(error){
            if(axios.isCancel(error)){
                console.log('REQUEST CANCELLED')
            }else{
                setModalData([])
                console.log(error)
            }
        })
    }

    //function for closing the modal 
    function closeModal(){
        setModalData(null)

        props.closeModal()
    }

    //function for handling the changes submitted of the modal
    //gets the list of changes made from the table and passes them in a PUT call
    async function handleConfirm(result, newErrors){

        //check if there were any errors and set message accordingly
        if(!result){
            setIsError(true)
            setMessage('Error! Cannot update items')
        }
        else{
            props.handleSuccess(result + " items updated successfully with " + newErrors.failed.length  + " errors!")
            closeModal()
        }
    }

    //function for closing the snackbar message
    function closeAlert(){
        setMessage(null)
        setIsError(false)
    }


    return(
        <>
            <BasicSnackBar
                open={message !== null} 
                variant={isError? 'error': 'success'} 
                message={message} 
                handleClose={closeAlert}
            />
             <BasicModal
                title={"Edit Fields (" + (modalData? modalData.length : 'Loading') + " Items)"}
                size='xl'
                body={
                    !modalData || loading? 
                        <div className="w-100 d-flex flex-column flex-center " style={{height: 'calc(75vh - 130px)'}}>
                            <h5>Loading Data...</h5>
                            <div><CircularProgress color="secondary"/></div>
                        </div>
                    :
                    modalData.length === 0?
                    <div className="w-100 d-flex flex-column flex-center " style={{height: 'calc(75vh - 130px)'}}>
                            <h5>No Data Available</h5>
                            
                    </div>
                    :
                <>

                    <Grid container spacing={2} className='border-bottom pb-5 mb-5'>
                            {
                                [{id: 'fc', header: 'FC'}, {id:'po_number', header: 'PO Number'}, {id: 'window_start', header: 'Windows Start'}, {id: 'window_end', header:'Windows End'}]
                                .map(col => {
                                    let row = modalData[0]
                                    
                                    return(
                                        <Grid item xs={3}>
                                            <div className='d-flex w-100 flex-row align-items-end'>
                                                <div className='font-weight-bolder font-size-lg mr-3'> {col.header}:  </div>
                                                <div>{row[col.id] }</div>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                    </Grid>
                    <div style={{height: 'calc(75vh - 130px)'}}>
                        
                        <InputTable
                            data={modalData}
                            noAdd
                            noDelete
                            disabled={true}
                            url={url}
                            handleSubmit={handleConfirm}
                            refresh={getData}
                            columns={ [ 
                                {id: 'accepted_qty', type: 'numeric', header: 'Acc Qty' , decimals: 0, style:{maxWidth: 60}}, 
                                {id: 'asin', type: 'text', header: 'ASIN'}, 
                                {id: 'vc_title', type: 'text', header: 'VC Title', style:{width: 110}}, 
                                {id:'shipped_qty', type: 'numeric', header:' Ship Qty', decimals: 0, style:{maxWidth: 60}}, 
                                {id: 'flag_routed', type: 'bool', header: 'Routed'}, 
                                {id: 'flag_packed', type: 'bool', header: 'Packed'}, 
                                {id: 'flag_shipped', type: 'bool', header: 'Shipped'}, 
                                {id: 'flag_cancelled', type: 'bool', header: 'Cancelled'},
                                {id: 'flag_asn', type: 'bool', header: 'ASN'}, 
                                {id: 'date_shipped', type: 'date', header: 'Date Shipped', style:{maxWidth: 115}}, 
                                {id: 'vc_prep', type: 'text', header: 'VC Prep'}, 
                                {id: 'packing_remarks', type:'text', header: 'Packing Remarks'},
                          
                            ]}
                        />
                    </div>
                    </>
                }
                show={props.value !== null}
                handleCancel={closeModal}
                noConfirm
            />
        </>
    )

}