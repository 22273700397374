import React, { useState, useEffect } from 'react';
import PageComponent2 from '../../components/PageComponent2';

export default function InventoryHealth() {
    const url = `${process.env.REACT_APP_API_URL}inventory-loc-health/`

    return(
        <PageComponent2
            tableTitle="Inventory Health"
            url={url}
            tableColumns={[
                {
                    id: 'base_sku',
                    header: 'Base SKU',
                    type: 'text',
                },
                {
                    id: 'brand',
                    header: 'Brand',
                    type: 'text',
                },
                {
                    id: 'onhand_units',
                    header: 'Onhand Units',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'instock_units_0_to_30_days',
                    header: 'Instock 0-30 Days',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'instock_units_31_to_60_days',
                    header: 'Instock 31-60 Days',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'instock_units_61_to_90_days',
                    header: 'Instock 61-90 Days',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'instock_units_91_to_180_days',
                    header: 'Instock 91-180 Days',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'instock_units_181_to_365_days',
                    header: 'Instock 181-365 Days',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'instock_units_365_plus_days',
                    header: 'Instock 365+ Days',
                    type: 'numeric',
                    decimals: 0
                },

            ]}
        />
    )
}