import React, { useState, useEffect } from 'react';
import ProductTable from '../../components/Tables/ProductTable/ProductTable';
import { getTableData } from '../../components/Tables/ProductTable/TableData';

export default function MessageLog() {
    const [tableData, setTableData] = useState(null);

    //********EFFECTS********* */
    //effect for calling the endpoint to get the correct data for the table
    //stores the response data in state as 'tableData'
    useEffect(() => {
        getTableData('MessageLog' ).then(({data}) => { 
            setTableData(data);      
        }).catch(function(error){
            setTableData([]);
            console.log(error);
        })
    }, []);

  
    return (<>
        <div>
            <ProductTable
                title="Message Log"
                data={tableData}
                rows={25}
                cols={7}
                columns={[
                    {
                        type: 'date',
                        format: 'ddd, MMM Do @h:mm a',
                        id: 'snapshot_date',
                        header: 'Date',
                    },
                    {
                        id: 'order_id',
                        header: 'Order ID',
                        type: 'text'
                    },
                    {
                        id: 'message_template',
                        header: 'Template',
                        type: 'text'
                    },
                    {
                        id: 'seller_account',
                        header: 'Seller Account',
                        type: 'text'
                    },
                    {
                        id: 'amzn_api_request_id',
                        header: 'Amazon Request',
                        type: 'text',
                    },
                    {
                        id: 'message_status',
                        header: 'Status',
                        type: 'text'
                    },

                ]}
            
            />
        </div>
    </>);
}