import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ColumnChart from "../../components/Charts/ColumnChart";
import SplineChart from "../../components/Charts/SplineChart";
import ProductTable from "../../components/Tables/ProductTable/ProductTable";
import PieChart from "../../components/Charts/PieChart";
import PeriodSelectWidget from "../../components/Widgets/PeriodSelectWidget";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { BrandSettingsTile } from "../../components/Tiles/BrandSettingsTile";
import SelectWidget from "../../components/Widgets/SelectWidget";
import moment from "moment";
import { Card } from "react-bootstrap";
import SingleDataIconTile from "../../components/Tiles/SingleDataIconTile";
import { LabelOutlined, StyleOutlined } from "@material-ui/icons";

export default function WeeklyOverview(props) {
  //state variables for selection tiles and chart
  const [selectionTilesData, setSelectionTilesData] = useState(null);
  const [selectionChartData1, setSelectionChartData1] = useState(null);
  const [selectionChartData2, setSelectionChartData2] = useState(null);
  const [selectionChartCat, setSelectionChartCat] = useState(null);

  //state variables for reorder tiles and chart
  const [reorderTilesData, setReorderTilesData] = useState(null);
  const [reorderChartData1, setReorderChartData1] = useState(null);
  const [reorderChartData2, setReorderChartData2] = useState(null);
  const [reorderChartCat, setReorderChartCat] = useState(null);

  //state variables for fulfillment tiles and chart
  const [fulfillmentTilesData, setFulfillmentTilesData] = useState(null);
  const [fulfillmentChartData1, setFulfillmentChartData1] = useState(null);
  const [fulfillmentChartData2, setFulfillmentChartData2] = useState(null);
  const [fulfillmentChartCat, setFulfillmentChartCat] = useState(null);

  //state variables for inv age tiles and chart
  const [inventoryTilesData, setInventoryTilesData] = useState(null);
  const [inventoryChartData1, setInventoryChartData1] = useState(null);
  const [inventoryChartData2, setInventoryChartData2] = useState(null);
  const [inventoryChartCat, setInventoryChartCat] = useState(null);

  //other const variables
  const base_url = process.env.REACT_APP_API_URL;

  //*************EFFECTS************* */

  //effect for selection data
  useEffect(() => {
    axios
      .get(base_url + "wbr-active-skus/?period=t12w")
      .then(({ data }) => {
        setSelectionTilesData(data.slice(0, 2));
        setSelectionChartData1(
          data.map((obj) => parseFloat(obj.active_skus)).reverse()
        );
        setSelectionChartData2(
          data.map((obj) => parseFloat(obj.active_brands)).reverse()
        );
        setSelectionChartCat(data.map((obj) => parseFloat(obj.week)).reverse());
      })
      .catch(function(error) {
        console.log(error);
        setSelectionTilesData([]);
      });
  }, []);

  //effect for reorder data
  useEffect(() => {
    axios
      .get(base_url + "wbr-instock-reorders/?period=t12w")
      .then(({ data }) => {
        setReorderTilesData(data[0]);
        setReorderChartData1(
          data.map((obj) => parseFloat(obj.act_order_amount)).reverse()
        );
        setReorderChartData2(
          data
            .map((obj) => parseFloat(obj.calc_feasible_order_amount))
            .reverse()
        );
        setReorderChartCat(data.map((obj) => parseFloat(obj.week)).reverse());
      })
      .catch(function(error) {
        console.log(error);
        setReorderTilesData([]);
      });
  }, []);

  //effect for fulfillment data
  useEffect(() => {
    axios
      .get(base_url + "wbr-fulfillment-shipped/?period=t12w")
      .then(({ data }) => {
        setFulfillmentTilesData(data[0]);
        setFulfillmentChartData1(
          data.map((obj) => parseFloat(obj.vc_shipped_amount)).reverse()
        );
        setFulfillmentChartData2(
          data.map((obj) => parseFloat(obj.fba_total_shipped_amount)).reverse()
        );
        setFulfillmentChartCat(
          data.map((obj) => parseFloat(obj.week)).reverse()
        );
      })
      .catch(function(error) {
        console.log(error);
        setFulfillmentTilesData([]);
      });
  }, []);

  //effect for inv aged data
  useEffect(() => {
    axios
      .get(base_url + "wbr-inventory-aged/?period=t12w")
      .then(({ data }) => {
        setInventoryTilesData(data[0]);
        setInventoryChartData1(
          data.map((obj) => parseFloat(obj.onhand_aged_amount)).reverse()
        );
        setInventoryChartData2(
          data.map((obj) => parseFloat(obj.fba_aged_amount)).reverse()
        );
        setInventoryChartCat(data.map((obj) => parseFloat(obj.week)).reverse());
      })
      .catch(function(error) {
        console.log(error);
        setInventoryTilesData([]);
      });
  }, []);

  //*************RETURN************* */
  return (
    <>
      <Grid container spacing={2}>
        {/* BEGIN Top Row for week number */}
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <Card.Body>
              {fulfillmentTilesData ? (
                <h6>
                  Weekly Summary - Current Week is {fulfillmentTilesData.week} -
                  {fulfillmentTilesData.year}
                </h6>
              ) : (
                <>
                  <p>Loading Data...</p>
                  <div>
                    <CircularProgress color="secondary" />
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Grid>
        {/* END Top Row for week number */}

        {/* BEGIN Row 1 for fulfillment data */}
        <Grid item xs={6} md={3} lg={2}>
          <SingleDataIconTile
            icon={<LabelOutlined color="disabled" />}
            tileValue={
              fulfillmentTilesData && fulfillmentTilesData.vc_shipped_amount
            }
            tileTitle="VC Shipped"
            bgColor="secondary"
            textColor="text-primary"
          />
          <SingleDataIconTile
            icon={<LabelOutlined color="disabled" />}
            tileValue={
              fulfillmentTilesData &&
              fulfillmentTilesData.vc_max_shippable_value
            }
            tileTitle="VC Shippable"
            bgColor="light"
            textColor="text-primary"
          />
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <SingleDataIconTile
            icon={<StyleOutlined color="disabled" />}
            tileValue={
              fulfillmentTilesData && fulfillmentTilesData.vc_total_prep_time
            }
            tileTitle="VC Prep Time"
            bgColor="primary"
            textColor="text-inverse-primary"
          />
          <SingleDataIconTile
            icon={<StyleOutlined color="disabled" />}
            tileValue={
              fulfillmentTilesData &&
              fulfillmentTilesData.fba_total_shipped_amount
            }
            tileTitle="FBA Shipped"
            bgColor="primary"
            textColor="text-inverse-primary"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Card>
            <Card.Body>
              {/* <Card.Title>Sales over Weeks</Card.Title> */}
              {fulfillmentChartData1 &&
              fulfillmentChartData2 &&
              fulfillmentChartCat ? (
                <SplineChart
                  data1={fulfillmentChartData1}
                  data2={fulfillmentChartData2}
                  name="fulfillment"
                  name1="VC"
                  name2="FBA"
                  categories={fulfillmentChartCat}
                  xtitle="Week"
                  ytitle1="VC Shipped Amount"
                  ytitle2="FBA Shipped Amount"
                />
              ) : (
                <>
                  <p>Loading Data...</p>
                  <div>
                    <CircularProgress color="secondary" />
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Grid>

        {/* END Row 1 for fulfillment data */}

        {/* BEGIN Row 2 for reorder data */}
        <Grid item xs={12} md={6} lg={8}>
          <Card>
            <Card.Body>
              {/* <Card.Title>Sales over Weeks</Card.Title> */}
              {reorderChartData1 && reorderChartData2 && reorderChartCat ? (
                <SplineChart
                  data1={reorderChartData1}
                  data2={reorderChartData2}
                  name="reorder"
                  name1="Actual Reorder"
                  name2="Feasible Reorder"
                  categories={reorderChartCat}
                  xtitle="Week"
                  ytitle1="Ordered Amount"
                  ytitle2="Feasible Amount"
                />
              ) : (
                <>
                  <p>Loading Data...</p>
                  <div>
                    <CircularProgress color="secondary" />
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <SingleDataIconTile
            icon={<LabelOutlined color="disabled" />}
            tileValue={reorderTilesData && reorderTilesData.act_order_amount}
            tileTitle="Ordered Amt"
            bgColor="primary"
            textColor="text-inverse-primary"
          />
          <SingleDataIconTile
            icon={<LabelOutlined color="disabled" />}
            tileValue={
              reorderTilesData && reorderTilesData.ordered_to_feasible_ratio
            }
            tileTitle="Ordered Ratio"
            bgColor="primary"
            textColor="text-inverse-primary"
          />
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <SingleDataIconTile
            icon={<StyleOutlined color="disabled" />}
            tileValue={
              reorderTilesData && reorderTilesData.calc_feasible_order_amount
            }
            tileTitle="Feasible Amt"
            bgColor="primary"
            textColor="text-inverse-primary"
          />
          <SingleDataIconTile
            icon={<StyleOutlined color="disabled" />}
            tileValue={reorderTilesData && reorderTilesData.ordered_sku_count}
            tileTitle="SKU Count"
            bgColor="primary"
            textColor="text-inverse-primary"
          />
        </Grid>
        {/* END Row 2 for reorder data */}

        {/* BEGIN Row 3 for inventory aged data */}
        <Grid item xs={6} md={3} lg={2}>
          <SingleDataIconTile
            icon={<LabelOutlined color="disabled" />}
            tileValue={
              inventoryTilesData && inventoryTilesData.onhand_aged_amount
            }
            tileTitle="Onhand >180D"
            bgColor="secondary"
            textColor="text-primary"
          />
          <SingleDataIconTile
            icon={<LabelOutlined color="disabled" />}
            tileValue={
              inventoryTilesData && inventoryTilesData.onhand_total_amount
            }
            tileTitle="Onhand Total"
            bgColor="light"
            textColor="text-primary"
          />
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <SingleDataIconTile
            icon={<StyleOutlined color="disabled" />}
            tileValue={inventoryTilesData && inventoryTilesData.fba_aged_amount}
            tileTitle="FBA >180D"
            bgColor="primary"
            textColor="text-inverse-primary"
          />
          <SingleDataIconTile
            icon={<StyleOutlined color="disabled" />}
            tileValue={
              inventoryTilesData && inventoryTilesData.fba_total_amount
            }
            tileTitle="FBA Total"
            bgColor="primary"
            textColor="text-inverse-primary"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Card>
            <Card.Body>
              {inventoryChartData1 &&
              inventoryChartData2 &&
              inventoryChartCat ? (
                <SplineChart
                  data1={inventoryChartData1}
                  data2={inventoryChartData2}
                  name="inventory"
                  name1="Onhand Aged"
                  name2="FBA Aged"
                  categories={inventoryChartCat}
                  xtitle="Week"
                  ytitle1="Onhand Aged Amount"
                  ytitle2="FBA Aged Amount"
                />
              ) : (
                <>
                  <p>Loading Data...</p>
                  <div>
                    <CircularProgress color="secondary" />
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Grid>

        {/* END Row 3 for inventory aged data */}

        {/* BEGIN Row 4 for selection data */}
        <Grid item xs={12} md={6} lg={8}>
          <Card>
            <Card.Body>
              {/* <Card.Title>Sales over Weeks</Card.Title> */}
              {selectionChartData1 &&
              selectionChartData2 &&
              selectionChartCat ? (
                <SplineChart
                  data1={selectionChartData1}
                  data2={selectionChartData2}
                  name="selection"
                  name1="Base SKU"
                  name2="Brands"
                  categories={selectionChartCat}
                  xtitle="Week"
                  ytitle1="SKU Count"
                  ytitle2="Brand Count"
                  // chartTitle="Active Selection"
                />
              ) : (
                <>
                  <p>Loading Data...</p>
                  <div>
                    <CircularProgress color="secondary" />
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <SingleDataIconTile
            icon={<LabelOutlined color="disabled" />}
            tileValue={selectionTilesData && selectionTilesData[0].active_skus}
            tileTitle="Active SKUs"
            bgColor="primary"
            textColor="text-inverse-primary"
          />
          <SingleDataIconTile
            icon={<LabelOutlined color="disabled" />}
            tileValue={selectionTilesData && selectionTilesData[1].active_skus}
            tileTitle="Active SKUs PW"
            bgColor="primary"
            textColor="text-inverse-primary"
          />
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <SingleDataIconTile
            icon={<StyleOutlined color="disabled" />}
            tileValue={
              selectionTilesData && selectionTilesData[0].active_brands
            }
            tileTitle="Active Brands"
            bgColor="primary"
            textColor="text-inverse-primary"
          />
          <SingleDataIconTile
            icon={<StyleOutlined color="disabled" />}
            tileValue={
              selectionTilesData && selectionTilesData[1].active_brands
            }
            tileTitle="Active Brands PW"
            bgColor="primary"
            textColor="text-inverse-primary"
          />
        </Grid>

        {/* END Row 4 for selection data */}
      </Grid>
    </>
  );
}
