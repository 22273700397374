import React, { useState, useEffect } from 'react';
import ProductTable from '../../components/Tables/ProductTable/ProductTable';
import { getTableData } from '../../components/Tables/ProductTable/TableData';

export default function OrderItems() {
    const [tableData, setTableData] = useState(null);

    //********EFFECTS********* */
    //effect for calling the endpoint to get the correct data for the table
    //stores the response data in state as 'tableData'
    useEffect(() => {
        getTableData('OrderItems' ).then(({data}) => { 
            setTableData(data);      
        }).catch(function(error){
            setTableData([]);
            console.log(error);
        })
    }, []);

  
    return (<>
        <div>
            <ProductTable
                title="FBA Orders"
                data={tableData}
                rows={25}
                cols={17}
                columns={[
                    {
                        id: 'order_id',
                        header: 'Order ID',
                        type: 'text'
                    },
                    {
                        type: 'date',
                        format: 'ddd, MMM Do @h:mm a',
                        id: 'purchase_date',
                        header: 'Purchase Date',
                    },
                    {
                        anchor: 'https://www.amazon.com/dp/',
                        tag: 'asin',
                        id: 'asin',
                        header: 'ASIN',
                        type: 'anchor'
                    },
                    {
                        id: 'product_name',
                        header: 'Product Name',
                        type: 'text',
                    },
                    {
                        id: 'quantity',
                        header: 'Quantity',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'total_price',
                        header: 'Total Price',
                        type: 'numeric',
                        decimals: 2
                    },
                    {
                        id: 'item_tax',
                        header: 'Item Tax',
                        type: 'numeric',
                        decimals: 2
                    },
                    {
                        id: 'gift_wrap_price',
                        header: 'Gift Wrap Price',
                        type: 'numeric',
                        decimals: 2
                    },
                    {
                        id: 'item_promotion_discount',
                        header: 'Item Promotion Discount',
                        type: 'numeric',
                        decimals: 0
                    },
 
                    {
                        id: 'seller_account',
                        header: 'Seller Account',
                        type: 'text',
                    },
                    {
                        id: 'shipping_price',
                        header: 'Shipping Price',
                        type: 'numeric',
                        decimals: 2
                    },
                    {
                        id: 'ship_city',
                        header: 'Ship City',
                        type: 'text',
                    },
                    {
                        id: 'ship_state',
                        header: 'Ship State',
                        type: 'text',
                    },
                    {
                        id: 'ship_postal_code',
                        header: 'Ship Postal Code',
                        type: 'text',
                    },
                    {
                        id: 'is_business_order',
                        header: 'Business Order',
                        type: 'bool',
                    },
                ]}
            
            />
        </div>
    </>);
}