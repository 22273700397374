/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import BasicSnackBar from "../../../../../app/components/Alerts/BasicSnackBar";
import axios from "axios";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import TextField from "@material-ui/core/TextField";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function CommandDropdown() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [textboxCommand, setTextboxCommand] = useState(null);
  const [isError, setIsError] = useState(false);
  const [selectedScript, setSelectedScript] = useState({name: '', script: ''})
  const [selectedASIN, setSelectedASIN] = useState(null);
  const bgImage =
    "https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/1f80f044327875.580fa41ccce79.jpg";

  //function for closing the snackbar alert
  function closeAlert() {
    setMessage(null);
    setIsError(false);
  }

  //click event function for the Run Script button
  function runScript(){
    setLoading(true)
    //send the script name to the logger-commands endpoint
    axios.post(`${process.env.REACT_APP_API_URL}logger-commands/`,{script: textboxCommand})
    .then(({data}) => {
      setLoading(false)
      setMessage(data.message)
      // setSelectedScript({name: '', script: ''})
    }).catch(function(error){
      setLoading(false)
      setMessage("This tool is for admin only")
      setIsError(true)
      // setSelectedScript({name: '', script: ''})
      console.log(error)
    })
  }



  return (
    <>
      <BasicSnackBar
        open={message !== null}
        variant={isError ? "error" : "success"}
        message={message}
        handleClose={closeAlert}
      />

      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-my-cart-toggle"
        >
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip id="mycart-panel-tooltip">Admin Command</Tooltip>
            }
          >
            <div className="btn btn-icon btn-clean btn-lg mr-1">
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M9,15 L7.5,15 C6.67157288,15 6,15.6715729 6,16.5 C6,17.3284271 6.67157288,18 7.5,18 C8.32842712,18 9,17.3284271 9,16.5 L9,15 Z M9,15 L9,9 L15,9 L15,15 L9,15 Z M15,16.5 C15,17.3284271 15.6715729,18 16.5,18 C17.3284271,18 18,17.3284271 18,16.5 C18,15.6715729 17.3284271,15 16.5,15 L15,15 L15,16.5 Z M16.5,9 C17.3284271,9 18,8.32842712 18,7.5 C18,6.67157288 17.3284271,6 16.5,6 C15.6715729,6 15,6.67157288 15,7.5 L15,9 L16.5,9 Z M9,7.5 C9,6.67157288 8.32842712,6 7.5,6 C6.67157288,6 6,6.67157288 6,7.5 C6,8.32842712 6.67157288,9 7.5,9 L9,9 L9,7.5 Z M11,13 L13,13 L13,11 L11,11 L11,13 Z M13,11 L13,7.5 C13,5.56700338 14.5670034,4 16.5,4 C18.4329966,4 20,5.56700338 20,7.5 C20,9.43299662 18.4329966,11 16.5,11 L13,11 Z M16.5,13 C18.4329966,13 20,14.5670034 20,16.5 C20,18.4329966 18.4329966,20 16.5,20 C14.5670034,20 13,18.4329966 13,16.5 L13,13 L16.5,13 Z M11,16.5 C11,18.4329966 9.43299662,20 7.5,20 C5.56700338,20 4,18.4329966 4,16.5 C4,14.5670034 5.56700338,13 7.5,13 L11,13 L11,16.5 Z M7.5,11 C5.56700338,11 4,9.43299662 4,7.5 C4,5.56700338 5.56700338,4 7.5,4 C9.43299662,4 11,5.56700338 11,7.5 L11,11 L7.5,11 Z" fill="#000000" fill-rule="nonzero"/>
                </g>
                </svg>
              </span>
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
          <div
            className="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top"
            style={{ backgroundImage: `url(${bgImage})` }}
          >
            <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
              <i className="flaticon2-ui text-danger"></i>
            </span>
            <h4 className="text-white m-0 flex-grow-1 mr-3">
              Commands (Admin Only)
            </h4>
          </div>
          {loading ? (
            <div className="w-100 d-flex flex-column flex-center p-5 ">
              <h5>Running Script...</h5>
              <div>
                <CircularProgress color="secondary" />
              </div>
            </div>
          ) : (
            <div>
              <div className="w-100 d-flex flex-column flex-center p-5 ">
                <form
                  noValidate
                  autoComplete="off"
                  onKeyPress={(e) => {
                    // e.key === "Enter" && e.preventDefault();
                    e.key === "Enter" && runScript();
                  }}
                >
                  <TextField
                    id="textboxCommand"
                    label="Command"
                    // className={classes.textField}
                    // value={textboxAsin}
                    // onChange={handleChange("name")}
                    onChange={(e) => setTextboxCommand(e.target.value)}
                    margin="normal"
                    variant="outlined"
                  />{" "}
                  <button
                    type="button"
                    className="btn btn-danger text-weight-bold"
                    disabled={loading || !textboxCommand}
                    onClick={runScript}
                  >
                    Axios
                  </button>
                </form>
              </div>
              
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
