/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BasicModal from '../Modals/BasicModal';
import BrandSettingsForm from '../Forms/BrandSettingsForm';
import BasicSnackBar from '../Alerts/BasicSnackBar';




export function BrandSettingsTile({ className, brand}) {
    const [data, setData] = useState(null)
    const [modal, setModal] = useState(false)
    const [message, setMessage] = useState(null)
    const [isError, setIsError] = useState(false)

    //*********************EFFECTS******************** */
    //effect for getting the data everytime a new brand is selected
    useEffect(() => {
        if(brand){
            axios.get(process.env.REACT_APP_API_URL + 'catalog-vendor-details/?vendor='+brand)
            .then(({data}) => {
                if(data.length > 0){
                    setData(data[0])
                }else{
                    setData([])
                }
                
            }).catch(function(error){
                console.log(error)
                setData([])
            })
        }
    }, [brand]);

    //*********************METHODS******************** */
    //function for handling the submission of changes to the form
    function handleSubmit(value){

        closeModal()

        axios.put(process.env.REACT_APP_API_URL + 'catalog-vendor-details/'+ data.id + '/', value).then(({data})=>{
            setData(data)
            setMessage('Brand settings successfully updated!')
        }).catch(function (error){
            setMessage('Error! Unable to update brand settings.')
            setIsError(true)
            console.log(error)
        })
    }

    //function for getting the icon for the bools to be displayed
    function getBoolIcon(value){
        let icon = ''
        if(value === true ){
            icon = <div className="d-inline-flex flex-center mx-3">
                          <span className="svg-icon svg-icon-md svg-icon-success">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <rect x="0" y="0" width="24" height="24"/>
                                  <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                                  <path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero"/>
                              </g>
                            </svg>
                          </span>
                        </div>
          }else if(value === false){
            icon = <div className="d-inline-flex flex-center mx-3">
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <rect x="0" y="0" width="24" height="24"/>
                                  <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                                  <path d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z" fill="#000000"/>
                              </g>
                            </svg>
                          </span>
                        </div>
          }

          return icon
    }
    
    //function for opening the edit modal
    function showModal(){
        setModal(true)
    }
    //function for closing the edit modal
    function closeModal(){
        setModal(false)
    }
    //function for closing the snackbar alert
    function closeAlert(){
        setMessage(null)
        setIsError(false)
    }

     return (
         <>
             <BasicSnackBar
                open={message !== null} 
                variant={isError? 'error': 'success'} 
                message={message} 
                handleClose={closeAlert}
            />
         <BasicModal
            show={modal}
            size='lg'
            title="Edit Brand Settings"   
            body={
                <BrandSettingsForm
                    data={data}
                    handleSubmit={handleSubmit}
                />
            }

            noConfirm
            handleCancel={closeModal}
            onHide={closeModal}
         />
    <div className={`card card-custom ${className}`}>
      {/* begin::Body */}
      <div className="card-body d-flex flex-column pt-2 pb-2">
        <div className="flex-grow-1 pb-1">
          {/* begin::Info */}
          <div className="d-flex align-items-center  mb-6">
            <span className="flex-grow-1" style={{fontSize: 14}}>
              Brand Settings
            </span>
            <div className="symbol symbol-50">
              <span className="symbol-label bg-light-light">
                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z" fill="#000000"/>
                    </g>
                </svg>
              </span>
            </div>
          </div>
          {/* end::Info */}

        {data && brand? 
            <>
            <div className="text-dark font-weight-bolder font-size-h4" >
                {(brand? 'Brand: '+ brand : '')}
            </div>
            <p className="text-dark-50 font-weight-normal font-size-lg mt-4">
                <div className='d-flex justify-content-between mb-4'>
                    <div>
                        Active: {getBoolIcon(data.active_sourcing)}
                    </div>
                    <div>
                        FBA Ok: {getBoolIcon(data.fba_ok)}
                    </div>
                </div>
                PO Name: {data.po_contact_name}
                <br/>
                <br/>
                PO Email: {data.po_contact_email}
                <br/>
            </p>
            <div className='d-flex w-100 justify-content-end mt-4'>
                <span className="btn btn-transparent-primary btn-sm font-weight-bolder font-size-md" onClick={showModal}> 
                    Edit 
                    <span className="svg-icon svg-icon-md ">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"/>
                                <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                                <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                            </g>
                        </svg>
                    </span>
                </span>
            </div>
            </>
          :
            <p className="text-dark-50 font-weight-normal font-size-lg mt-6">
                No brand currently selected.
            </p>

        }
        </div>
      </div>
      {/* end::Body */}
    </div>
    </>
  );
}
