import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Box, useTheme, IconButton, makeStyles } from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    justifyContent: 'center',
 
    width:30
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export default function ProductTablePagActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const [input, setInput] = useState(null)
  const [isError, setIsError] = useState(false);
  const { count, page, rowsPerPage, onChangePage } = props;

  useEffect(() => {
    setInput(page + 1)
  }, [page]);

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  function handlePageInput(event){
    
    setInput(event.target.value)
  }

  function handleSubmit(event){
    event.preventDefault();
    let newPage  = input -1;
    if(newPage < 1 || newPage > Math.max(0, Math.ceil(count / rowsPerPage) - 1)){
      setIsError(true);
      return;
    }
    setIsError(false);
    onChangePage(event, newPage)
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>

      <span className='d-flex flex-row flex-center mr-3'>
        <form className={classes.container} onSubmit={handleSubmit}>
          <TextField
            id="input-page-number"
            error={isError}
            className={classes.textField}
            value={input}
            onChange={handlePageInput}
            inputProps={{style:{textAlign: 'center', paddingBottom: 5}}}
          />
        </form>
        of {Math.max(0, Math.ceil(count / rowsPerPage))}
      </span>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}


