import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function ASINSKU() {
    const url = `${process.env.REACT_APP_API_URL}catalog-asin-sku/`
    return (<>
        <PageComponent
            tableTitle="ASIN SKU"
            url={url}
            bulkAdd
            bulkEdit
            import
            deleteItems
            addItems
            checkbox
            requiredEditFields={['asin', 'base_sku', 'qty_per_base', 'kit']}
            requiredCSVColumns={
                [
                    'base_sku',
                    'asin',
                    'qty_per_base',
                    'kit'
                ]
            }
            formInputs={[
                {
                    required: true,
                    id: 'asin',
                    label: 'ASIN',
                    helperText: "10-character alphanumeric unique identifier",
                    ip: { maxLength: 20 }
                },
                {
                    required: true,
                    id: 'base_sku',
                    label: 'Base SKU',
                    ip: { maxLength: 100 }
                },

                {
                    required: true,
                    id: 'qty_per_base',
                    label: 'Qantity Per Base',
                    ip: { maxLength: 100 }
                },
                {
                    required: true,
                    id: 'kit',
                    label: 'Kit *',
                    type: 'bool'
                },
                {
                    id: 'fba_inbound_freight_rate',
                    label: 'FBA Inbound Freight Rate',
                    ip: { maxLength: 100 }
                },
                {
                    id: 'customer_return_rate',
                    label: 'Customer Return Rate',
                    ip: { maxLength: 100 }
                },
                {
                    id: 'packing_fee',
                    label: 'Packing Fee',
                    ip: { maxLength: 100 }
                },
                {
                    id: 'fba_ok',
                    label: 'FBA OK',
                    type: 'bool'
                },
            ]}
            tableColumns={[
                {
                    anchor: 'https://www.amazon.com/dp/',
                    tag: 'asin',
                    id: 'asin',
                    header: 'ASIN',
                    type: 'anchor'
                },
                {
                    id: 'base_sku',
                    header: 'Base SKU',
                    type: 'text'
                },
                {
                    id: 'qty_per_base',
                    header: 'Quantity per Base',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'kit',
                    header: 'Kit',
                    type: 'bool',
                },
                {
                    id: 'fba_fulfillment_fee',
                    header: 'FBA Fulfillment Fee',
                    type: 'numeric',
                    decimals: 2
                },
                {
                    id: 'referral_rate',
                    header: 'Referral Rate',
                    type: 'numeric',
                    decimals: 2
                },
                {
                    id: 'fba_inbound_freight_rate',
                    header: 'FBA Freight Rate',
                    type: 'numeric',
                    decimals: 1
                },
                {
                    id: 'customer_return_rate',
                    header: 'Return Rate',
                    type: 'numeric',
                    decimals: 2
                },
                {
                    id: 'packing_fee',
                    header: 'Packing Fee',
                    type: 'numeric',
                    decimals: 2
                },
                {
                    id: 'fba_ok',
                    header: 'FBA OK',
                    type: 'bool',
                },
                {
                    type: 'edit',
                    header: 'Actions'
                },
            ]}
        />
    </>);
}