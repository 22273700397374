import React from "react";
import {Redirect, Switch} from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import VCOrders from "../modules/VCOrders/VCOrders";
import ItemPrep from "../modules/VCOrders/ItemPrep";
import VcCancel from "../modules/VCOrders/VcCancel";
import VCReorder from "../modules/VCOrders/VCReorder";
import VCShipments from "../modules/VCOrders/VCShipments";
import VCFulfillment from "../modules/VCOrders/VCFulfillment";
import VCPriority from "../modules/VCOrders/VCPriority";
import VcCookies from "../modules/VCOrders/VcCookies";

export default function VCOrdersPage() {
    return (
        <Switch>
          <Redirect
            exact={true}
            from="/vc-orders"
            to="/vc-orders/vc-orders"
          />
    
          <ContentRoute
            path="/vc-orders/vc-orders" 
            component={VCOrders} 
          />
          <ContentRoute
            path="/vc-orders/vc-cancel" 
            component={VcCancel} 
          />
        <ContentRoute
            path="/vc-orders/item-prep" 
            component={ItemPrep} 
          />

        <ContentRoute
            path="/vc-orders/vc-shipments" 
            component={VCShipments} 
          />

        <ContentRoute
            path="/vc-orders/vc-open-items" 
            component={VCReorder} 
          />

        <ContentRoute
          path="/vc-orders/vc-fulfillment" 
          component={VCFulfillment} 
        />

        <ContentRoute
          path="/vc-orders/vc-priority" 
          component={VCPriority} 
        />
        <ContentRoute
            path="/vc-orders/cookies" 
            component={VcCookies} 
          />
        </Switch>
    );
}