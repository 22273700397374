import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';
import { useHistory } from 'react-router-dom';

export default function OverInvoices(props) {
    const url = `${process.env.REACT_APP_API_URL}financials-over-invoices/`
    const history = useHistory()

    //method called when user clicks on a PO number
    //redirects user to the purchase order page and passes the input PO as a state parameter
    function sendToPOPage(po){
        history.push({pathname: '/instock/purchase-orders/', state: po})
    }

    return(
        <PageComponent
            tableTitle="Over Invoices"
            url={url}
            tableColumns={[
                {
                    id: 'po_number',
                    header: 'PO Number',
                    type: 'function',
                    function: sendToPOPage
                },
                {
                    id: 'vendor_name',
                    header: 'Vendor Name',
                    type: 'text',
                },
                {
                    id: 'invoice_number',
                    header: 'Invoice Number',
                    type: 'function',
                    function: props.handleInvoice
                },
                {
                    id: 'order_date',
                    header: 'Order Date',
                    type: 'date',
                    format: 'MM/DD/YYYY'
                },
                {
                    id: 'total_received',
                    header: 'Total Received',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'total_invoiced',
                    header: 'Total Invoiced',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'invoices_not_received_value',
                    header: 'Invoices Not Received Value',
                    type: 'numeric',
                    decimals: 2
                },

            ]}
        />
    )
}