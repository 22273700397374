
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Grid, TextField,Tooltip, IconButton } from '@material-ui/core';
import KeywordSelect from '../Buttons/KeywordSelect';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
  typography: {
    margin: theme.spacing(2),
  },
  paper: {
   
    overflowX: 'auto !important'
    
  }
}));


export default function PeriodSelectWidget({
    handleRefresh, handleSubmit
}) {
  const classes = useStyles();
  const initialData = {startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')}
  const [values, setValues] = useState(initialData)
  const [keyword, setKeyword] = useState(null)

  const keywords = [
    {value: 7, label: '7 Days'},
    {value: 30, label: '30 Days'},
    {value: 90, label: '90 Days'},
    {value: 180, label: '180 Days'},
    {value: 365, label: '365 Days'},
    {value: 'lw', label: 'Last Week'},
    {value: 'cw', label: 'Current Week'},
    {value: 'lm', label: 'Last Month'},
    {value: 'cm', label: 'Current Month'},
    {value: 'ly', label: 'Last Year'},
    {value: 'cy', label: 'Current Year'},
    {value: 'q1', label: 'Quarter 1'},
    {value: 'q2', label: 'Quarter 2'},
    {value: 'q3', label: 'Quarter 3'},
    {value: 'q4', label: 'Quarter 4'},
]

  //click handler for the refresh button
  //sets the data back to null 
  function refresh(){
      setValues(initialData)
      setKeyword(null)
      handleRefresh()
  }

  //change event handler for the date selects
  //sets the value
  const handleChange = id => event => {
        let value = event.target.value
        if(value === null){
            value = ""
        }
        setValues({...values,[id] : value})
  }

  //callback function for the keyword dropdown select
  //calculates the new start and end dates based off the keyword selected
  function handleKeyword(option) {
    let value = option.value

    setKeyword(option)

    //if its a number then convert to days and subtract from today
    if(typeof value === 'number'){
      setValues({startDate: moment().subtract(value, 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')})
    }else{
      //else its a keyword and so we calculate the dates accordingly
      switch(value){
        case 'cw':
          setValues({startDate: moment().isoWeekday(1).format('YYYY-MM-DD'), endDate: moment().isoWeekday(7).format('YYYY-MM-DD')})
          break;
        case 'lw':
          let last_week = moment().subtract(7, 'days')
          setValues({startDate: last_week.isoWeekday(1).format('YYYY-MM-DD'), endDate: last_week.isoWeekday(7).format('YYYY-MM-DD')})
          break;
        case 'cm':
          setValues({
            startDate: moment().year(moment().year()).month(moment().month()).date(1).format('YYYY-MM-DD'), 
            endDate: moment().year(moment().year()).month(moment().month()).date(moment().daysInMonth()).format('YYYY-MM-DD')
          })
          break;
        case 'lm':
          let last_month = moment().subtract(moment().daysInMonth(), 'days')
          setValues({
            startDate: moment().year(last_month.year()).month(last_month.month()).date(1).format('YYYY-MM-DD'), 
            endDate: moment().year(last_month.year()).month(last_month.month()).date(last_month.daysInMonth()).format('YYYY-MM-DD')
          })
          break;
        case 'cy':
          setValues({startDate: moment([moment().year()]).format('YYYY-MM-DD'), endDate: moment([moment().year(), 11, 31]).format('YYYY-MM-DD')})
          break;
        case 'ly':
          let last_year = moment().subtract(1, 'year')
          setValues({startDate: moment([last_year.year()]).format('YYYY-MM-DD'), endDate: moment([last_year.year(), 11, 31]).format('YYYY-MM-DD')})
          break;
        case 'q1':
          setValues({startDate: moment([moment().year(), 0]).format('YYYY-MM-DD'), endDate: moment([moment().year(), 2,31]).format('YYYY-MM-DD')})
          break;
        case 'q2':
          setValues({startDate: moment([moment().year(), 3]).format('YYYY-MM-DD'), endDate: moment([moment().year(), 5,30]).format('YYYY-MM-DD')})
          break;
        case 'q3':
          setValues({startDate: moment([moment().year(), 6]).format('YYYY-MM-DD'), endDate: moment([moment().year(), 8,30]).format('YYYY-MM-DD')})
          break;
        case 'q4':
          setValues({startDate: moment([moment().year(), 9]).format('YYYY-MM-DD'), endDate: moment([moment().year(), 11,31]).format('YYYY-MM-DD')})
          break;
      }
    }
  }


  //click handler for the Apply button
  //sends the values entered to the prop component
  const handleApply = state => event =>{
    state.close()
    if(values.startDate !== null && values.endDate){
        handleSubmit(values)
    }
  }

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {popupState => (
        <div>
            <div className='card card-custom card-stretch p-3 d-flex flex-row justify-content-between' style={{minWidth: 389}}>
            <div className='d-flex flex-center border-right font-size-lg font-weight-bold pr-4'>Select Range</div>
            <div className='mx-8 d-flex flex-center'>
                <Button variant="outlined" {...bindTrigger(popupState)}>
                    {`${values.startDate? moment(values.startDate).format("MM/DD/YYYY") : "Select Start"} - ${values.endDate? moment(values.endDate).format("MM/DD/YYYY"): "Select End"}`}
                </Button>
            </div>
            <div className="border-left pl-2 ">
                <Tooltip title="Refresh" >
                    <IconButton aria-label="Refresh" onClick={refresh}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>
            </div>
    </div>
        
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{ paper: "MuiPopover-paper" }}
          >
            <Typography className={classes.typography}>
                <div className='d-flex flex-column flex-center p-5 h-100'>
                  <KeywordSelect
                      id={0} 
                      placeholder={'30 Days'} 
                      keywords={keywords} 
                      handleDropDown={handleKeyword}
                      value={keyword}
                  />   
                  <div className='d-flex flex-row justify-content-between align-items-baseline w-100 h-100 mt-6 border-top pt-5'>
                    <TextField
                        type={"date"}
                        label="Start Date"
                        value={values.startDate}
                        onChange={handleChange("startDate")}
                        InputLabelProps={{shrink: true}}
                      
                    />
                    
                    <span className='mx-4 font-wieght-bold font-size-lg'> - </span>
                    <TextField
                        type={"date"}
                        label="End Date"
                        value={values.endDate}
                        onChange={handleChange("endDate")}
                        InputLabelProps={{shrink: true}}
                    />
                  </div>
                </div>
                <div className='d-flex flex-row flex-center mt-4 w-100'>
                    <Button onClick={handleApply(popupState)} variant="outlined" color='secondary' className='p-1'> Apply </Button>  
                </div>
            </Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
