import React, { useState, useEffect } from "react";
import PageComponent from "../../components/PageComponent";

export default function AsinEconomicsAggregation() {
  const base_url = process.env.REACT_APP_API_URL;

  const fields = {
    image: {
      id: "image_links",
      header: "",
      type: "image",
    },
    asin: {
      anchor: "https://www.amazon.com/dp/",
      tag: "asin",
      id: "asin",
      header: "ASIN",
      type: "anchor",
    },
    sku: {
      id: "sku",
      header: "SKU",
      type: "text",
    },
    brand: {
      id: "brand",
      header: "Brand",
      type: "text",
    },
    quantity: {
      id: "quantity",
      header: "Qty Sold",
      type: "numeric",
      decimals: 0,
    },
    total_profit: {
      id: "total_profit",
      header: "Total Profit",
      type: "numeric",
      decimals: 2,
    },
    total_sales_amount: {
      id: "total_sales_amount",
      header: "Total Sales",
      type: "numeric",
      decimals: 2,
    },
    total_cogs: {
      id: "total_cogs",
      header: "Total Cogs",
      type: "numeric",
      decimals: 2,
    },
    aggregation_date: {
      id: "aggregation_date",
      header: "Aggregation Date",
      type: "date",
      format: "MM/DD/YYYY",
    },
    aggregation_period: {
      id: "aggregation_period",
      header: "Aggregation Period",
      type: "text",
    },
    total_adjustments: {
      id: "total_adjustments",
      header: "Total Adjustments",
      type: "numeric",
      decimals: 2,
    },
    total_fba_inbound: {
      id: "total_fba_inbound",
      header: "Total Inbound Freight ",
      type: "numeric",
      decimals: 2,
    },
    total_sales_tax_charge: {
      id: "total_sales_tax_charge",
      header: "Total Sales Tax",
      type: "numeric",
      decimals: 2,
    },
    total_gift_wrap_charge: {
      id: "total_gift_wrap_charge",
      header: "Total Gift Wrap Charge",
      type: "numeric",
      decimals: 2,
    },
    total_gift_wrap_tax: {
      id: "total_gift_wrap_tax",
      header: "Total Gift Wrap Tax",
      type: "numeric",
      decimals: 2,
    },
    total_gift_wrap_fee: {
      id: "total_gift_wrap_fee",
      header: "Total Gift Wrap Fee",
      type: "numeric",
      decimals: 2,
    },
    total_shipping_charge: {
      id: "total_shipping_charge",
      header: "Total Shipping Charge",
      type: "numeric",
      decimals: 2,
    },
    total_shipping_tax: {
      id: "total_shipping_tax",
      header: "Total Shipping Tax",
      type: "numeric",
      decimals: 2,
    },
    total_shipping_fee: {
      id: "total_shipping_fee",
      header: "Total Shipping Fee",
      type: "numeric",
      decimals: 2,
    },
    total_restocking_fee: {
      id: "total_restocking_fee",
      header: "Total Restocking Fee",
      type: "numeric",
      decimals: 2,
    },
    total_fba_per_order_fee: {
      id: "total_fba_per_order_fee",
      header: "Total FBA per Order Fee",
      type: "numeric",
      decimals: 2,
    },
    total_fba_per_unit_fee: {
      id: "total_fba_per_unit_fee",
      header: "Total FBA per Unit Fee",
      type: "numeric",
      decimals: 2,
    },
    total_fba_weight_fee: {
      id: "total_fba_weight_fee",
      header: "Total FBA Weight Fee",
      type: "numeric",
      decimals: 2,
    },
    total_referral_fee: {
      id: "total_referral_fee",
      header: "Total Referral Fee",
      type: "numeric",
      decimals: 2,
    },
    total_refund_commission_fee: {
      id: "total_refund_commission_fee",
      header: "Total Refund Commission Fee",
      type: "numeric",
      decimals: 2,
    },
    total_fixed_closing_fee: {
      id: "total_fixed_closing_fee",
      header: "Total Fixed Closing Fee",
      type: "numeric",
      decimals: 2,
    },
    total_variable_closing_fee: {
      id: "total_variable_closing_fee",
      header: "Total Variable Closing Fee",
      type: "numeric",
      decimals: 2,
    },
    total_shipping_tax_withheld: {
      id: "total_shipping_tax_withheld",
      header: "Total Shipping Tax Witheld",
      type: "numeric",
      decimals: 2,
    },
    total_sales_tax_withheld: {
      id: "total_sales_tax_withheld",
      header: "Total Sales Tax Witheld",
      type: "numeric",
      decimals: 2,
    },
  };

  return (
    <PageComponent
      tableTitle="ASIN Economics Aggregation (T30D)"
      url={base_url + "financials-item-economics-aggregation/"}
      columnSelect
      tableColumns={Object.values(fields)}
      orderBy={{ id: "total_profit", type: "numeric" }}
    />
  );
}
