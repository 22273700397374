import React, { useState, useEffect } from 'react';
import ProductTable from '../../components/Tables/ProductTable/ProductTable';
import { getTableData } from '../../components/Tables/ProductTable/TableData';
import axios from 'axios';

export default function RepricerLog() {
    const [tableData, setTableData] = useState(null);

    //********EFFECTS********* */
    //effect for calling the endpoint to get the correct data for the table
    //stores the response data in state as 'tableData'
    useEffect(() => {
        const source = axios.CancelToken.source()

        try{
            axios.get(process.env.REACT_APP_API_URL + 'pricing-repricer-run-log/', {
                cancelToken: source.token,
            }).then(({data}) => { 
                setTableData(data);      
            }).catch(function (error){
                setTableData([]);
                console.log(error);
            })
        }catch(error){
            if (axios.isCancel(error)) {
                console.log('User Cancelled Request')
            } 
        }

        return () => {
            source.cancel()
        }
    }, []);

  
    return (<>
        <div>
            <ProductTable
                title="Repricer Log"
                data={tableData}
                rows={25}
                cols={9}
                columns={[
                    {
                        id: 'seller_account',
                        header: 'Seller Account',
                        type: 'text'
                    },
                    {
                        id: 'script',
                        header: 'Script',
                        type: 'text'
                    },
                    {
                        id: 'status',
                        header: 'Status',
                        type: 'text'
                    },
                    {
                        id: 'items_processed',
                        header: 'Items Processed',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        type: 'date',
                        format: 'ddd, MMM Do @h:mm a',
                        id: 'snapshot_date',
                        header: 'Snapshot',
                    },
                ]}
            
            />
        </div>
    </>);
}