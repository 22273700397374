import React, { useState, useEffect } from 'react';
import BasicSnackBar from './Alerts/BasicSnackBar';
import BasicModal from './Modals/BasicModal';
import ProductTable from './Tables/ProductTable/ProductTable';
import DownloadCSVBtn from './Buttons/DownloadCSVBtn';
import { UpdateTable, DeleteTableData, AddTableData, AddSingleItem, doesItemExist } from './Tables/ProductTable/TableData';
import axios from 'axios';
import BasicForm from './Forms/BasicForm';

export default function PageComponent(props) {
    const [tableData, setTableData] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [message, setMessage] = useState(null);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null)
    const [count, setCount] = useState(null)
    const [disableDownload, setDisableDownload] = useState(true)
    const url = props.url
    const source = axios.CancelToken.source();

    //********EFFECTS********* */

    //effect for calling the endpoint to get the correct data for the table
    //stores the response data in state as 'tableData'
    useEffect(() => {
        getData()

        return () => {
            source.cancel()
        }
    }, []);

    

    async function getData(){
        let tableURL = url, newData = []
        if(props.tableURL){ //check if there is a different url to get table data
            tableURL = props.tableURL
        }

        
        let result = await callEndpoint(tableURL, newData, 200, 0)
        newData.push(...result.results)
    
        if(!count){
            setTableData(newData)
            setCount(result.count)
        }
        

        let limit = result.count, offset = 200
        while(result.next !== null){
            result = await callEndpoint(tableURL, newData, limit, offset)
            newData.push(...result.results)
            offset+=100
        }

        setTableData(newData)
        setDisableDownload(false)
    }

    function callEndpoint(url, tableData,limit,  offset){
        return new Promise(resolve => {
            axios.get(url, {cancelToken: source.token, params: {
                limit:limit, offset:offset
            }} )
            .then(({ data}) => {
                resolve(data)
            }).catch(function(error){
                if(axios.isCancel(error)){
                    console.log('REQUEST CANCELLED')
                }else{
                    console.log(error)
                }
                resolve({results:[], next: null})
            })
          })
    }
    //********METHODS********* */

    //function called when the "import csv" button is clicked
    //loops throught the csv data and then passes the product asins to get the product key
    async function handleImport(csvData, mode){
        const headers = csvData[0].meta.fields;
        const columns = [], newArray = [];
        let newErrors = {empty: 0, failed: [], cancelled: false}

        //get the loop throught the header row and get all columns present
         for(let k = 0; k < headers.length; k++)
         {
             if(headers[k] && headers[k] !== ''){
                columns.push(headers[k])
             }
         }

         //loop through the csvData and put each row object in the same array
         csvData.map(row => {
             //remove 'flag_invoiced from the csv
            if(row.data['flag_invoiced'] !== undefined){
                delete row.data['flag_invoiced']
            }
            
            if(props.trimCSVColumns){
                //trim the csv data to only the required fields
                let trimmedRow = {}
                props.requiredCSVColumns.map(header => {
                    trimmedRow[header] = row.data[header]
                })

                //retain the id row if the mode is delete or edit
                if(mode === 'Edit' || mode === 'Delete'){
                    trimmedRow['id'] = row.data['id']
                }
                newArray.push(trimmedRow)
            }else if(props.checkFields){
                //validate certain columns of the imported csv before adding them
                let validated = false
                for (let i = 0; i < props.checkFields.length; i++) {
                   let checkField = props.checkFields[i]
                   
                    //set the options to either the tableData or the inputted options
                    let options = tableData
                    if(checkField.options){
                        options = checkField.options
                    }
                    
                    //check if the optionType is an array or object and handle accordingly
                    if(checkField.optionsType === 'array'){
                        validated = options.includes(row.data[checkField.id])
                        
                    }
                    else if(checkField.optionsType === 'object' ){
                        validated =doesItemExist(row.data, options, checkField.id)
                    }

                    //Check if row was validated
                    if(!validated){
                        let newRow =row.data
                        newRow['reason'] = checkField.id + ' is invalid'
                        newErrors.failed.push(newRow) 
                        break;
                    }
                }

                if(validated){
                    newArray.push(row.data)
                }

            }else{
                newArray.push(row.data)
            }
        })
        

        if(mode === 'Delete'){
            
        }
        else if(mode === 'Edit'){

            let result = await UpdateTable(url, 0,newErrors, newArray.slice(0,20), newArray)
             //set success/error message base off of the result returned from the item
             if(result > 0){
                getData()
                setMessage(result + " changes made successfully with " + newErrors.failed.length  + " errors!")
            }
            else{
                setIsError(true)
                setMessage('Error! Unable to save changes.')
            }
        }
        else{

            let result = await AddTableData(url, 0, newErrors, newArray.slice(0,20), newArray, false)

            //set success/error message base off of the result returned from the item
            if(result > 0){
                getData()

                if(newErrors.cancelled){
                    setIsError(true)
                    setMessage("Upload cancelled after adding " + result + " items.")
                }else{
                    setMessage(result + " Items added successfully with " + newErrors.failed.length + " errors!")
                }
            }
            else{
                setIsError(true)
                setMessage('Error! Unable to add items.')
            }
        }

        //open the error modal if there were errors
        if(newErrors.failed.length > 0){
            setErrors(newErrors.failed)
        }
    }

    //function for handling csv file upload error
    // just sets the error message accordingly
    function handleOnError(){
        setMessage('Error! Unable to load file.')
        setIsError(true)
    }

    //function for handling when the user sumbits an edit for a specific item in the table
    //gets the ID of the item from the table and then makes a PUT call to the endpoint to update the item
    function handleEdit(row, edits){

        if(props.requiredEditFields){
            props.requiredEditFields.map(id => {
                if(typeof edits[id] === 'undefined'){
                    edits[id] = row[id]
                }
            })
        }

        axios.put(url + row.id + '/', edits).then(({data}) => {
            getData()
            setMessage('Changes saved successfully!')
        }).catch(function(error){
            console.log(error)
            setMessage('Unable to save changes.')
            setIsError(true)
        })

    }

    //function for handling deletion of selected items in the table
    async function doDelete(count, selectedItems, items){
        if(items.length > 100){
            setIsError(true)
            setMessage('You do not have permission to delete more than 100 items at a time.')
            return
        }
        let errors ={count: 0, cancelled: false}
        
        //make the call to delete the data and wait for the response
        let result = await DeleteTableData(url, count, errors,  selectedItems, items);
        
        //set success/error message base off of the result returned from the item
        if(result){
            getData()
     
            if(errors.cancelled){
                setIsError(true)
                setMessage("Request cancelled after deleting " + result + " items successfully.")

            }else{
                setMessage(result + " Items deleted successfully!")

            }
        }
        else{
            setIsError(true)
            setMessage('Error! Unable to delete items.')
        }
    }

    //function for adding an item when the user clicks submit
    async function addItem(params){
        closeModals()
        let result = await AddSingleItem(url, params) //make the call and wait for the response

        //set success/error message base off of the result returned from the item
        if(result){
            getData()
            setMessage("Item added successfully!")
        }
        else{
            setIsError(true)
            setMessage('Error! Unable to add item.')
        }
    }

    //function for when user makes changes and clicks submit
    //gets the list of changes made from the table and passes them in a PUT call
    async function handleSubmit(changes){
        let newErrors = {empty: 0, failed: []}
        //convert changes into an array
        let newArray = []
        Object.keys(changes).map(id => {
            newArray.push(changes[id])
        })

        let result = await UpdateTable(url, 0,newErrors, newArray.slice(0,20), newArray)
        //check if there were any errors and set message accordingly
        if(!result){
            setIsError(true)
            setMessage('Error! Cannot update items')
        }
        else{
           
            setMessage(result + " items updated successfully with " + newErrors.failed.length  + " errors!")
            getData()
        }

        //open the error modal if there were errors
        if(newErrors.failed.length > 0){
            setErrors(newErrors.failed)
        }
    }

    //function for handling when user clicks the + button in the toolbar
    function showForm(){
        setOpenForm(true)
    }
    //function for closing the form modal
    function closeModals(){
        setOpenForm(false)
        setErrors(null)
    }
    //function for closing the snackbar alert
    function closeAlert() {
        setMessage(null)
        setIsError(false)
    }  
    //function for displaying messages sent from the product table component
    function displayMessage(note){
        setMessage(note)
        getData()
    }
  
    return (<>
        <div>
            <BasicModal
                title="Add a New Item"
                onHide={closeModals}
                body={
                    <BasicForm 
                        addItem={addItem}
                        inputs={props.formInputs}
                    />
                }
                show={openForm}
                handleCancel={closeModals}
                noConfirm
            />
            <BasicModal
                title="Something Went Wrong..."
                body={
                    <>
                        <div className='text-center'>There were {errors && errors.length} errors in the last import, download the CSV file to view. 
                        Also, be sure to check the <b>'reason'</b> column at the end of each row to get a better understanding of what went wrong.</div>
                        <div className='d-flex w-100 flex-center my-5'><DownloadCSVBtn data={errors} name={props.tableTitle + 'Errors'}/></div>
                    </>
                }
                show={errors !== null}
                handleCancel={closeModals}
                cancelTitle='Close'
                noConfirm
            />
            <BasicSnackBar
                open={message !== null} 
                variant={isError? 'error': 'success'} 
                message={message} 
                handleClose={closeAlert}
            />
            <ProductTable
                title={props.tableTitle}
                data={tableData}
                disableDownload={disableDownload}
                bulkEdits={props.bulkEdits && props.bulkEdits}
                resetSave={props.resetSave}
                checkbox={props.checkbox}
                deleteItems={props.deleteItems}
                search={props.search}
                requiredHeaders={props.requiredCSVColumns}
                handleImport={props.import && handleImport}
                handleOnError={handleOnError}
                handleSelectSubmit={handleSubmit}
                doDelete={doDelete}
                handleEdit={handleEdit}
                handleForm={props.addItems && showForm}
                rows={25}
                small={props.small}
                columns={props.tableColumns}  
                bulkAdd={props.bulkAdd}
                bulkEdit={props.bulkEdit}
                displayMessage={displayMessage}
                orderBy={props.orderBy}
                order={props.order}
                count={count}
            />
        </div>
    </>);
}