import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import ProductTable from '../../components/Tables/ProductTable/ProductTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import BrandSalesOverTime from '../../components/Tiles/BrandSalesOverTIme';
import PieChart from '../../components/Charts/PieChart';
import PeriodSelectWidget from '../../components/Widgets/PeriodSelectWidget';
import moment from 'moment';

export function SalesOverview() {
  const base_url = process.env.REACT_APP_API_URL
  const [brandsOverTime, setBrandsOverTime] = useState(null)
  const [brandSales, setBrandSales] = useState(null)
  const [itemSales, setItemSales] = useState(null)
  const [salesBreakdown, setSalesBreakdown] = useState(null)
  const [selectedDays, setSelectedDays] = useState(null)

  //keyword data for the select dropdowns
  const [brandsKeys, setBrandsKeys] = useState([])


  //***********************EFFECTS************************* */

  //this effect gets and sets the data for all the widgets/tiles in the dashboard
  //makes each call once at startup
  //the data is also set accordingly in case of errors
  useEffect(() => {
    //call for the Chart data in Brand Weekly sales over time
    let newData = []
    axios.get(base_url + 'catalog-base-sku/?type=brands').then(({data}) => {// get the brands first   
      
      data.brand_list.map(brand => {
        newData.push({value: brand, label: brand})
      })
      setBrandsKeys(newData)
    }).catch(function(error){
      console.log(error)
      setBrandsKeys([])
    })

    axios.get(base_url + 'sales-brand-weekly/?type=chart' ).then(({data}) => {// set the data for the tile 
      setBrandsOverTime(data)
    }).catch(function(error){
      console.log(error)
      setBrandsOverTime([])
    })

    //initialize the top two tables with ?days=30 
    handleDaysKey({startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')})
  }, []);


  //***********************METHODS************************* */

  //function for handling the Brands over time tile select
  //take the brand key and passes it as a param in a GET call
  function handleBrandsOverTimeKey(key){
    axios.get(base_url + 'sales-brand-weekly/?type=chart&brand='+ key.value).then(({data}) => { 
      setBrandsOverTime(data)
    }).catch(function(error){
      console.log(error)
      setBrandsOverTime([])
    })
  }

  //function for handling the days select calls other helper functions 
  //that sets the data for their tables
  async function handleDaysKey(days){
    //set data to null to get the loading screen
    setItemSales(null)
    setBrandSales(null)
    

    //then pass the days to each endpoint and store the data
    let brands = await handleBrandKey(days)
    let items = await handleItemKey(days)
    let breakdown = await handleBreakdownKey(days)

    //check if theyre both valid data
    if(brands && items && breakdown ){
      setSalesBreakdown(breakdown)
      setBrandSales(brands)
      setItemSales(items)
      setSelectedDays(days)
    }
  }

  function handleBrandKey(key){
    return new Promise(resolve => {
      axios.get(base_url + `sales-brand-daily/?start_date=${key.startDate}&end_date=${key.endDate}`).then(({data}) => { 
        return resolve(data)
      }).catch(function(error){
        console.log(error)
        return resolve([])
      })
    })
    
  }

  function handleItemKey(key){
    return new Promise(resolve => {
      axios.get(base_url + `sales-item-daily/?start_date=${key.startDate}&end_date=${key.endDate}`).then(({data}) => { 
        return resolve(data)
      }).catch(function(error){
        console.log(error)
        return resolve([])
      })
    })
  }

  function handleBreakdownKey(key){
    return new Promise(resolve => {
      axios.get(base_url + `sales-item-daily/?type=pie&start_date=${key.startDate}&end_date=${key.endDate}`).then(({data}) => {
        return resolve(data)
      }).catch(function(error){
        console.log(error)
        return resolve([])
      })
    })
  }

  return <>
    <Grid container spacing={2} justify='center'>
      <Grid item xs={12}>
          <div className='d-flex flex-center w-100'>
              <PeriodSelectWidget handleSubmit={handleDaysKey} handleRefresh={()=>handleDaysKey({startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')})}/>
          </div>
      </Grid>
      <Grid item xs={12}md={6}>
          <ProductTable
              title={"Sales by Brand"}
              small
              orderBy={{id: 'sold_amount', type: 'numeric'}}
              order={'desc'}
              data={brandSales}
              rows={5}
              columns={[
                {
                  id: 'brand',
                  header: 'Brand',
                  type: 'text'
                },
                {
                  id: 'sold_units',
                  header: 'Units',
                  type: 'numeric',
                  decimals: 0
                },
                {
                  id: 'sold_amount',
                  header: 'Amount',
                  type: 'numeric',
                  decimals: 0
                },
            ]}
          />
      </Grid>
      <Grid item xs={12}md={6}>
        <ProductTable
              title={"Sales by Title "}
              data={itemSales}
              orderBy={{id: 'sold_amount', type: 'numeric'}}
              order={'desc'}
              small
              rows={5}
              columns={[
                  {
                      anchor: 'https://www.amazon.com/dp/',
                      tag: 'asin',
                      id: 'asin',
                      header: 'ASIN',
                      type: 'anchor'
                  },
                  {
                      id: 'sku',
                      header: 'SKU',
                      type: 'text'
                  },
                  {
                      id: 'sold_units',
                      header: 'Units',
                      type: 'numeric',
                      decimals: 0
                  },
                  {
                      id: 'sold_amount',
                      header: 'Amount',
                      type: 'numeric',
                      decimals: 0
                  },
              ]}
          />
      </Grid>
      <Grid item xs={12} md={8}>
        <BrandSalesOverTime data={brandsOverTime} keywords={brandsKeys} handleKey={handleBrandsOverTimeKey}/>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <div className='card card-custom card-stretch '>
          <div className="px-5 mt-4" style={{fontSize: 14}}>
            Sales Breakdown 
          </div>
          {salesBreakdown ? 
            <span className='d-flex flex-center w-100'>
              <PieChart
                name='salesbreakdown'
                data={[parseFloat(salesBreakdown[0].sold_amount.toFixed(0)), parseFloat(salesBreakdown[1].sold_amount.toFixed(0))]}
                labels={['SC', 'VC']}
              />
            </span>
            :
            <div className="w-100 d-flex flex-column flex-center ">
              <h5>Loading Data...</h5>
              <div><CircularProgress color="secondary"/></div>
            </div>
          }
        </div>
      </Grid>
    </Grid>
  </>;
}
