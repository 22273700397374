import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';
import { UpdateTable } from '../../components/Tables/ProductTable/TableData';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function VendorInvoices(props){
    const history = useHistory()
    const base_url = process.env.REACT_APP_API_URL

    const fields={
        vendor_name: {
            id: 'vendor_name',
            header: 'Vendor',
            type: 'text',
        },
        po_number: {
            id: 'po_number',
            header: 'PO Number',
            type: 'function',
            function: sendToPOPage
        },
        invoice_date:{
            id: 'invoice_date',
            header: 'Invoice Date',
            type: 'date',
            format: 'MM/DD/YYYY'
          
        },
        due_date:{
            id: 'due_date',
            header: 'Due Date',
            type: 'date',
            format: 'MM/DD/YYYY'
          
        },
        invoice_number:{
            id: 'invoice_number',
            header: 'Invoice Number',
            type: 'function',
            function: props.handleInvoice
        },
        total_amount:{
            id: 'total_amount',
            header: 'Total Amount',
            type: 'numeric',
            decimals: 2,
        },
        payment_terms:{
 
            id: 'payment_terms',
            header: 'Payment Terms',
            type: 'text',
        },
        invoice_paid:{
            id: 'invoice_paid',
            header: 'Invoice Paid',
            type: 'bool',
        },
    }

      //method called when user clicks on a PO number
    //redirects user to the purchase order page and passes the input PO as a state parameter
    function sendToPOPage(po){
        history.push({pathname: '/instock/purchase-orders/', state: po})
    }

    //function to handle submition on paid invoices
    //takes the changes made to the invoice_paid and applies to the whole invoice number
    async function handleSubmit(changes, data){
     
            //get the items for the invoice numbers changed
            let allChanges = [], duplicates = [], newErrors = {empty: 0, failed: []}

            for (let index = 0; index < changes.length; index++) {
                let change = changes[index]
                //get the row from data
                let row = data[change.id]
                
                if(typeof row.invoice_number !== 'undefined' && !duplicates.includes(row.invoice_number)){
                    let newData = await getInvoiceItems(row.invoice_number)

                    //add the new invoice_paid attribute 
                    newData.map(item =>{
                        item["invoice_paid"] = change.invoice_paid
                    })
  
                    //save all items into an array
                    allChanges.push(...newData)

                    //keep track of duplicates
                    duplicates.push(row.invoice_number)
                }
            }
          
            return UpdateTable(base_url+'financials-vendor-invoice-items/', 0,newErrors, allChanges.slice(0,20), allChanges)
    }

    function getInvoiceItems(invoice_num){
        return new Promise(resolve => {
            //make the api call to get all items within the invoice number
            axios.get(base_url+'financials-vendor-invoice-items/?invoice_num=' + invoice_num)
            .then(({data}) =>{
                return resolve(data)
            }).catch(function(error){
                console.log(error)
                return resolve([])
            })
        })
    }


    return(
      
            <PageComponent
            tableTitle="Vendor Invoices"
            url={base_url+'financials-vendor-invoice-items/?type=grouped'}
            checkbox
            resetSave
            bulkEdits={[
                {
                    type: 'select',
                    id: 'invoice_paid',
                    label: 'Paid',
                    options: [
                        {value: true, label: 'Yes'},
                        {value: false, label: 'No'},
                    ]
                }
            ]}
            handleSubmit={handleSubmit}
            tableColumns={Object.values(fields)}
            addIds
        />
     
        
    )
}