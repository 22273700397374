import React, { useState, useEffect } from "react";
import PageComponent from "../../components/PageComponent";

export default function DistCandidates() {
  let url = process.env.REACT_APP_API_URL + "expand-new-items/";

  return (
    <>
      <PageComponent
        tableTitle="New Opportunities"
        url={url}
        resetSave
        checkbox
        deleteItems
        bulkEdits={[
          {
            type: "select",
            id: "reached_flag",
            label: "Reached",
            options: [
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ],
          },
        ]}
        tableColumns={[
          {
            id: "reached_flag",
            header: "Reached",
            type: "bool-icon",
          },
          {
            id: "image_links",
            header: "",
            type: "image",
          },
          {
            anchor: "https://www.amazon.com/dp/",
            tag: "asin",
            id: "asin",
            header: "ASIN",
            type: "anchor",
          },
          {
            id: "item_name",
            header: "Item Name",
            type: "text",
          },
          {
            id: "brand",
            header: "Brand",
            type: "text",
          },
          {
            id: "buybox_price",
            header: "Buybox Price",
            type: "numeric",
            decimals: 2,
          },
          {
            id: "buybox_seller_name",
            header: "Buybox Seller Name",
            type: "text",
          },
          {
            id: "fulfillment_mode",
            header: "Fulfillment Mode",
            type: "text",
          },
          {
            id: "gl_category",
            header: "GL Category",
            type: "text",
          },
          {
            id: "sales_rank",
            header: "Sales Rank",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "offer_count",
            header: "Offers",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "review_count",
            header: "Reviews",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "star_rating",
            header: "Rating",
            type: "numeric",
            decimals: 1,
          },
          {
            id: "reached_by",
            header: "Actioned By",
            type: "text",
          },
          {
            id: "reached_date",
            header: "Actioned Date",
            type: "date",
            format: "MM/DD/YYYY",
          },
        ]}
      />
    </>
  );
}
