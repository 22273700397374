import React from "react";
import {Redirect, Switch} from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import MessageLog from "../modules/Feedback/MessageLog";
import OrderQueue from "../modules/Feedback/OrderQueue";


export default function FeedbackPage() {
    return (
        <Switch>
          <Redirect
            exact={true}
            from="/feedback"
            to="/feedback/message-log"
          />
    
          <ContentRoute
            path="/feedback/message-log" 
            component={MessageLog} 
          />

            <ContentRoute
            path="/feedback/order-queue" 
            component={OrderQueue} 
          />
        </Switch>
    );
}