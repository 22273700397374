// Basic Table used for display purposes only
// Each table cell is rendered as a String
// See the bottom of PO.js Where we use this to display in a PDF

import React, { useState, useEffect } from 'react';

export default function DisplayTable(props) {
useEffect(() => {
    
}, [props.data]);

    return(
        <>
            <div class="table-responsive ">
                <table class="table" >
                    <thead>
                        <tr>
                            {props.columns.map(col => {
                                if(col.type === 'numeric'){
                                    return <th class="text-right font-weight-bolder ">{col.header}</th>
                                }

                                return(
                                    <th class="text-left font-weight-bolder ">{col.header}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody >
                        { props.data.length > 0 ?
                            
                            props.data.map((row, index) => {

                                return (
                                    <>
                                        <tr class="font-weight-bold ">
                                            {props.columns.map(col => {
                                                return(
                                                    <td className={" border-0 text-" + (col.type === 'text'? 'left': 'right')} style={col.style}>
                                                        {row[col.id] !== null ? row[col.id].toString() : "N/A"}
                                                    </td>

                                                )
                                            })}
                                           
                                        </tr>
                                    </>
                                )
                            })
                        :
                            <tr>
                                <td class="pt-7 ">
                                    <div className='w-100 d-flex flex-center'>
                                    No data available
                                    </div>
                                    
                                </td>       
                            </tr>
                        }
                    </tbody>
                </table>
            </div>



        </>
        )
        
}