// Child component of BulkEdits
// Rendered if an item in the bulkEdits props has type "modal"
// see Inbound for an example of how to pass props to render this

import React, {useEffect, useState}from 'react';
import { Modal } from 'react-bootstrap';
import { Grid, Button } from '@material-ui/core';
import moment from 'moment';
import { TextField} from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import PerfectScrollbar from "react-perfect-scrollbar";

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
    
  };

export default function BulkEditModal(props) {
    const [changes, setChanges] = useState(null)
    const [allChanges, setAllChanges] = useState({})

    //***************EFFECTS*************** */
    //use effect for forcing a rerender based off the following variables
    //aslo initiates all the edit fields to empty
    useEffect(() => {   
    }, [ props.inputs, changes, allChanges]);

    //initiates all the changes fields to empty objects
    useEffect(() => {
        let newChanges = {}
        props.selectedRows.map(row => {
            newChanges[row.id] = {'id': row.id}
        })

        setChanges(newChanges)
    }, [ props.show, props.selectedRows]);
    
    //***************METHODS*************** */
    //function that cleans up and resets the state when the modal is closed
    function handleCancel(){
        setChanges(null)
        setAllChanges({})
        props.handleCancel()
    }
    //handles the edit all fields
    //stores the values entered in state
    const handleEditAll = id => event => {
        setAllChanges({...allChanges,[id]: event.target.value})
    }

    //function called when user enters values in the editAll inputs and clicks apply
    //takes the value from the editAll input and applys it to each change 
    function applyToAll(event){
        event.preventDefault()
        let newChanges={...changes}
        Object.keys(allChanges).map(field => { //loop through all the allChanges keys (should be ids of fields changed)
            Object.values(newChanges).map(change=>{  // loop through all the values in the changes object
                change[field] = allChanges[field]
            })
        })

        setChanges(newChanges)
    }

    //handles the changes of the each textfield
    //stores the changes in state
    const handleChanges = (id, field) => event =>{
        let newEdits = {...changes}, newEntry

        newEntry = newEdits[id]
        newEntry[field] = event.target.value
        newEdits[id] = newEntry
        setChanges(newEdits)
    }
    //function for getting the type of textfield for the input
    function getInput(row, input, index){
        let child, value, edit=changes[row.id]

        //set the value
        if(edit[input.id]){
            value = edit[input.id]
        }
        else{
            value = row[input.id]
        }

        
        if(value === null || typeof value === 'undefined'){
            value = ''
        }

        switch(input.type){
            case 'numeric':
            case 'text':
                child = <TextField
                            key={index}
                            label={input.header}
                            value={value}
                            onChange={handleChanges(row.id, input.id)}
                            className='w-100'               
                        />  
                break;
            case 'date':
                child = <TextField
                            key={index}
                            type='date'
                            label={input.header}
                            value={value !== ''? moment(value).format('YYYY-MM-DD'):  '' }
                            InputLabelProps={{shrink: true}}
                            onChange={handleChanges(row.id, input.id)}
                            className='w-100'               
                        />  
                break;
        }

        return child
    }

    //function for getting the headers of the each row
    const getHeaders = (row) =>{
        let str =''
        //loop through the headerIDs array
        props.headerIDs.map(header => {
            if(header.indexOf('date') > 0){ //check for dates and format correctly
                str += '(' +  moment(row[header]).format('MM/DD/YYYY') + ') '
            }
            else{
                str += row[header] + ' '
            }
        })

        return str;
    }


    return(
    <Modal
        show={props.show} 
        onHide={props.onHide && props.onHide}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Fields
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {!changes || !props.inputs? 
                <div className="w-100 d-flex flex-column flex-center ">
                    <h5>Loading Data...</h5>
                    <div><CircularProgress color="secondary"/></div>
                </div>
            :
            <>
            {/* EDIT ALL START*/}
            <form noValidate autoComplete="off" onSubmit={applyToAll}>

                <Grid container spacing={2} justify='center' className="mt-0 border-bottom pb-5 mb-3">
                    <Grid item xs={3} className='d-flex align-items-end justify-content-center'>
                        <Button type='submit' className="px-3" disabled={Object.keys(allChanges).length ===0} variant="contained" color="secondary" >
                            Apply to All              
                        </Button>
                    </Grid>
                        {props.inputs.map((input, index) => {
                            let child
                            switch(input.type){
                                case 'numeric':
                                case 'text':
                                    child = <TextField
                                                key={index}
                                                label={input.header}
                                                defaultValue=''
                                                onChange={ handleEditAll(input.id)}
                                                className='w-100'               
                                            />  
                                    break;
                                case 'date':
                                    child = <TextField
                                                key={index}
                                                type='date'
                                                label={input.header}
                                                InputLabelProps={{shrink: true}}
                                                onChange={ handleEditAll(input.id)}
                                                className='w-100'               
                                            />  
                                    break;
}
                            return(
                                <Grid item xs={(9/props.inputs.length) }>
                                    {child}
                                </Grid>
                            )
                        })}
              
                </Grid>
            </form>
             {/*EDIT ALL END*/}

             <PerfectScrollbar
                options={perfectScrollbarOptions}
                className="scroll p-5"
                style={{ maxHeight: "36em", position: "relative", overflowX:'hidden'}}
            >
                {/* EDIT SINGLES START*/}
                <form noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        {props.selectedRows.map(row=>{
                            return(
                                    <>
                                        <Grid item xs={3} className='d-flex align-items-end'>
                                            <div  style={{fontSize: 12, fontWeight: 500}}>
                                                {getHeaders(row)}:
                                            </div>
                                        </Grid>
                                        {props.inputs.map((input,index) => {
                
                                            return(
                                                <Grid item xs={(9/props.inputs.length)}>
                                                    {getInput(row, input ,index)}
                                                </Grid>
                                            )                               
                                        })}
                                    </>
                            )
                        })}
                        
                    </Grid>
                </form>
                {/* EDIT SINGLES END*/}
            </PerfectScrollbar>
            </>
        }
        </Modal.Body>
        <Modal.Footer>
            <Grid container spacing={3} alignItems='center' alignContent='center' justify='center'>
                <Grid item xs={6} className="text-center text-hover-primary">
                    <a id="close" onClick={handleCancel}>
                        <span>Cancel</span>
                    </a>
                </Grid>
                <Grid item xs={6} className="text-center text-hover-primary" >
                    <a id="renew" onClick={() => props.handleConfirm(changes)}>
                        <span > Save Changes</span>
                    </a>
                </Grid>     
            </Grid>
        </Modal.Footer>
    </Modal>);
}