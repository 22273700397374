import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import {FormControl, FormControlLabel, Checkbox, Grid } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },

  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export default function BrandSettingsForm(props) {
  const classes = useStyles();
  const [values, setValues] = useState({
    "brand": null,
    "vendor_name": null,
    "vendor_address": null,
    "vendor_city": null,
    "vendor_state": null,
    "vendor_zip": null,
    "vendor_country": null,
    "vendor_phone": null,
    "active_sourcing": false,
    "fba_ok": false,
    "po_contact_name": null,
    "po_contact_email": null,
    "lead_time": null,
  });
  const [changes, setChanges] = useState(0)


  //effect for populating the values based on the brand data
  useEffect(() => {
    if(props.data){
        let newValues = {...values}
        for (const key in newValues) {
            if (newValues.hasOwnProperty( key)) {
                newValues[key] = props.data[key]             
            }
        }
        setValues(newValues)
    }
  }, [props.data]);


  //*****************METHODS************* */
  //function for returning basic input fields
  function getTextField(label, name, type){
      return(
        <TextField
            label={label}
            type={type}
            value={values[name]}
            className={classes.textField}
            onChange={handleChange(name)}
            margin="normal"
        />
      )
  }

  //returns bool checkboxe inputs
  function getCheckbox(label, name){
      return(
        <FormControl>
            <FormControlLabel
                label={<div style={{fontSize: '1rem', color: 'rgba(0, 0, 0, 0.54)'}}>{label}</div>}
                labelPlacement="start"
                control={
                    <Checkbox
                                
                        checked={values[name]}
                        className='p-0 mt-2'
                        onChange={handleBoolChanges(name)}
                        inputProps={{
                        'aria-label': 'primary checkbox',
                        }}
                    />
                }
            />
        </FormControl>
      )
  }
 
  const handleChange = name => event => {
      let newCount = changes
      newCount++ 
    setChanges(newCount)
      setValues({...values, [name] : event.target.value})
  };
  const handleBoolChanges = name => event => {
    let newCount = changes
    newCount++
    setChanges(newCount)
      if(values[name] === true){
        setValues({...values, [name] : false})
      }else{
        setValues({...values, [name] : true})
      }

};
  
  const clearForm = () => {
    let form = document.getElementById('frm');
    setValues({})
    setChanges(0)
    form.reset();
    
  }

  //function for handling the click of the 'Submit' button
  //Uses a POST call to the 'product_queue/add/' endpoint
  function handleSubmit(event)
  {
    event.preventDefault();
    props.handleSubmit(values);
    clearForm(); 
    
  }

  return (
    <div >
      <div className="d-flex flex-row border-0 mt-2 justify-content-between px-5" style={{maxHeight:55}}>
          <div className="mt-3 d-flex flex-end flex-column" style={{fontSize: 14, color: '#000000DE'}}>{props.title}</div>
      </div>

        <form id='frm' onSubmit={handleSubmit} className={classes.container} noValidate autoComplete="off">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <div className='d-flex w-50 justify-content-start'>
                        {getTextField('Brand', 'brand')}
                    </div>
                </Grid>
                <Grid item xs={12} sm={2}>
                    {getCheckbox('Active', 'active_sourcing')}
                </Grid>
                <Grid item xs={12} sm={2}>
                    {getCheckbox('FBA Ok', 'fba_ok')}
                </Grid>
                <Grid item xs={12}>
                    <div className='d-flex w-50 justify-content-start'>
                        {getTextField('Vendor Name', 'vendor_name')}
                    </div>
                </Grid>
                <Grid item xs={12} >
                    {getTextField('Address', 'vendor_address')}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {getTextField('City', 'vendor_city')}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {getTextField('State', 'vendor_state')}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {getTextField('Zip', 'vendor_zip')}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {getTextField('PO Name', 'po_contact_name')}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {getTextField('Phone', 'vendor_phone')}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {getTextField('PO Email', 'po_contact_email')}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {getTextField('Lead Time', 'lead_time', 'number')}
                </Grid>
            </Grid>
         

          <Button disabled={changes===0} variant="contained" color="secondary" className='mt-4' type='submit'>
            <Icon>done</Icon>
                      Save Changes
          </Button>
        </form>

    </div>
  );
}
