// "Bulk Upload" button you see in the table
// Rendered in ProductTableToolbar.js
// Only shown when handleImport props is passed along with bulkAdd or bulkEdit
// See BaseSKU.js table props for an example 



import React, { useState, useEffect } from 'react';
import {CSVReader, CSVDownloader} from 'react-papaparse';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Edit } from '@material-ui/icons';
import { Chip } from '@material-ui/core';
import BasicModal from '../Modals/BasicModal';
import BasicSnackBar from '../Alerts/BasicSnackBar';

export default function UplaodCSVBtn(props) {
    const [headers, setHeaders] = useState(null)
    const [uploadModal, setUploadModal] = useState(false);
    const [message, setMessage] = useState(null)
    const [csvData, setCSVData] = useState(null);
    const [headerCount, setHeaderCount] = useState(0)
    const [idColor, setIDColor] = useState('default')
    const [blankCSVTemplate, setBlankCSVTemplate] = useState([])
    const [populatedTemplate, setPopulatedTemplate] = useState([])

    //called everytime the data in the table changes
    //effect for setting the data for the populated template
    useEffect(() => { 
      if(props.data){
        let newTemplate = []
        //delete all columns that arent required
        props.data.map(row => {
          let newRow = {...row}
          for (const key in newRow) {
            if(props.requiredHeaders.indexOf(key) === -1 && key !== 'id'){
              delete newRow[key]
            }
          }
          newTemplate.push(newRow)
        })

        setPopulatedTemplate(newTemplate)
      }
      
    }, [props.data]);


    useEffect(() => {
      resetHeaders();
    }, [uploadModal]);

    function resetHeaders() {
      if(props.requiredHeaders){
        let newHeaders=[]
        let newTemplate={}
        props.requiredHeaders.map(header =>{
          //assign a label and a color to each header (for display in the chips)
          newHeaders.push({'label': header, 'color': 'default'})

          //make a blank object with each header (for the download template)
          newTemplate[header] = null
        })
        setHeaderCount(0)
        setHeaders(newHeaders)
        setBlankCSVTemplate([...blankCSVTemplate, newTemplate])
      }
    }

    function showModal(){
      setUploadModal(true)
    }
  
    function handleOnDrop(data) {
      //loop through to see the headers
      if(headers){
        let headerRow = data[0].meta.fields
        let newHeaders = headers, count = 0
        let found = {}
        headerRow.map(col =>{
          found[col] = true
        })

        newHeaders.map(header =>{
          if(found[header.label]){
            header.color = 'primary'
            count++
          }
        })

        if(found.id){
          setIDColor('primary')
        }
        setHeaderCount(count);
        setHeaders(newHeaders)
      }
      setCSVData(data.slice(0,10001));
    }
  
    function handleOnRemoveFile(){
      setCSVData(null);
      resetHeaders();
      setIDColor('default')
    }
    
    function closeModals(){
      setUploadModal(false);
      setHeaders(null)
      setCSVData(null)
      setIDColor('default')
      setHeaderCount(0)
    }

    function closeAlert(){
      setMessage(null)
    }

    function handleImport(csvData, mode){
      props.handleImport(csvData, mode)
      closeModals()
    }

    return(
      <>
      <BasicSnackBar
        open={message !== null} 
        variant={'error'} 
        message={message} 
        handleClose={closeAlert}
      />
      <BasicModal
          title="Upload in Bulk"
          onHide={closeModals}
          body={
            <div className="d-flex flex-column flex-center">
              {headers && 
               <div className='d-flex flex-wrap w-100 mb-4 flex-center'>
                 <div className='w-100 mb-2'> Required Column Headers: </div>
                 {props.bulkEdit &&
                  <Chip
                    label='id (edit only)'
                    className='d-flex flex-center flex-wrap m-1'
                    color={idColor}
                  />
                 }
                 
               { headers.map(header =>{
                  return(
                    <Chip
                      label={header.label}
                      className='d-flex flex-center flex-wrap m-1'
                      color={header.color}
                    />
                  )

                })}
                </div>
              }
              <div className='w-100 d-flex flex-row m-5 justify-content-around'>
                <CSVDownloader
                  data={blankCSVTemplate}
                  filename={props.title + ' Template'}
                  bom={true}
                >
                  <a href='#'>Blank Template</a>
                </CSVDownloader>

                <CSVDownloader
                  data={populatedTemplate}
                  filename={props.title + 'Populated Template'}
                  bom={true}
                >
                  <a href='#'>Populated Template</a>
                </CSVDownloader>
              </div>
              

              <div style={{height: 165}}>
                <CSVReader
                  config={{
                    skipEmptyLines: 'greedy',
                    header: true,
                  }}
                  onDrop={handleOnDrop}
                  onError={props.handleOnError}
                  addRemoveButton
                  removeButtonColor='#F64E60'
                  onRemoveFile={handleOnRemoveFile}        
                >
                  <span>Drop CSV file here or click to upload.</span>
                </CSVReader>
              </div>
                
                <span  className='d-flex flex-row justify-content-between mt-10'>
                  {props.bulkDelete && <Button className="mr-5" disabled={!csvData} variant="contained" color='secondary' onClick={() => handleImport(csvData, 'Delete')} >
                    <DeleteIcon/> 
                    {props.deleteTitle? props.deleteTitle : 'Delete Items'}                              
                  </Button>}
                  {props.bulkAdd && <Button className="ml-5" disabled={headerCount !== props.requiredHeaders.length} variant="contained" color="secondary" onClick={() => handleImport(csvData, 'Add')}>
                    <Add/>
                    {props.addTitle? props.addTitle: 'Add Items'}                
                  </Button>}

                  {props.bulkEdit && <Button className="ml-5" disabled={idColor==='default'} variant="contained" color="secondary" onClick={() => handleImport(csvData, 'Edit')}>
                    <Edit/>
                    Edit Items               
                  </Button>}
                </span>
            </div>
          }
          show={uploadModal}
          handleCancel={closeModals}
          noConfirm
        />
        <span className="btn btn-transparent-primary btn-sm font-weight-bolder font-size-md" onClick={showModal} style={{maxWidth: 95, maxHeight: 32}}> Bulk Upload</span>

      </>
    );
    
}