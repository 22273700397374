import React, { useState, useEffect } from 'react';
import ProductTable from '../../components/Tables/ProductTable/ProductTable';
import { getTableData } from '../../components/Tables/ProductTable/TableData';

export default function InventoryHealth() {
    const [tableData, setTableData] = useState(null);

    //********EFFECTS********* */
    //effect for calling the endpoint to get the correct data for the table
    //stores the response data in state as 'tableData'
    useEffect(() => {
        getTableData('InventoryHealth' ).then(({data}) => { 
            setTableData(data);      
        }).catch(function(error){
            setTableData([]);
            console.log(error);
        })
    }, []);

  
    return (<>
        <div>
            <ProductTable
                title="Inventory Health"
                data={tableData}
                rows={25}
                cols={20}
                columns={[
                    {
                        anchor: 'https://www.amazon.com/dp/',
                        tag: 'asin',
                        id: 'asin',
                        header: 'ASIN',
                        type: 'anchor'
                    },
                    {
                        id: 'seller_account',
                        header: 'Seller Account',
                        type: 'text'
                    },
                    {
                        id: 'item_name',
                        header: 'Item Name',
                        type: 'text'
                    },
                    {
                        id: 'sales_rank',
                        header: 'Sales Rank',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'product_group',
                        header: 'Product Group',
                        type: 'text'
                    },
                    {
                        type: 'date',
                        format: 'ddd, MMM Do @h:mm a',
                        id: 'inventory_date',
                        header: 'Inventory Date',
                    },
                    {
                        id: 'total_inventory',
                        header: 'Total Inventory',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'sellable_quantity',
                        header: 'Sellable Quantity',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'inv_age_0_to_90_days',
                        header: 'Inventory Age 0-90 days',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'inv_age_91_to_180_days',
                        header: 'Inventory Age 91-180 days',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'inv_age_181_to_270_days',
                        header: 'Inventory Age 181-270 days',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'inv_age_271_to_365_days',
                        header: 'Inventory Age 271-365 days',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'inv_age_365_plus_days',
                        header: 'Inventory Age 365+ days',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'afn_new_seller_count',
                        header: 'AFN New Seller Count',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'afn_used_sellers_count',
                        header: 'AFN Used Sellers Count',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'our_price',
                        header: 'Our Price',
                        type: 'numeric',
                        decimals: 2
                    },
                    {
                        id: 'lowest_afn_new_price',
                        header: 'Lowest AFN New Price',
                        type: 'numeric',
                        decimals: 2
                    },
                    {
                        id: 'qty_to_be_charged_ltsf_12_mo',
                        header: 'Quantity LTSF 12 months',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'qty_to_be_charged_ltsf_6_mo',
                        header: 'Quantity LTSF 6 months',
                        type: 'numeric',
                        decimals: 0
                    },
                    {
                        id: 'inbound_quantity',
                        header: 'Inbound Quantity',
                        type: 'numeric',
                        decimals: 0
                    },
                ]}
            
            />
        </div>
    </>);
}