import React, { useState, useEffect } from "react";
import PageComponent from "../../components/PageComponent";

export default function DailyCogs() {
  const base_url = process.env.REACT_APP_API_URL;

  const fields = {
    image_links: {
      id: "image_links",
      header: "",
      type: "image",
    },
    asin: {
      anchor: "https://www.amazon.com/dp/",
      tag: "asin",
      id: "asin",
      header: "ASIN",
      type: "anchor",
    },
    item_name: {
      id: "item_name",
      header: "Item Name",
      type: "text",
    },
    brand: {
      id: "brand",
      header: "Brand",
      type: "text",
    },
    channel: {
      id: "channel",
      header: "Channel",
      type: "text",
    },
    purchase_date: {
      id: "purchase_date",
      header: "Purchase Date",
      type: "date",
      format: "MM/DD/YYYY",
    },
    total_sales: {
      id: "total_sales",
      header: "Total Sales",
      type: "numeric",
      decimals: 2,
    },
    total_units: {
      id: "total_units",
      header: "Total Units",
      type: "numeric",
      decimals: 0,
    },
    unit_landed_cogs: {
      id: "unit_landed_cogs",
      header: "Unit Landed Cogs",
      type: "numeric",
      decimals: 2,
    },
    total_landed_cogs: {
      id: "total_landed_cogs",
      header: "Total Landed Cogs",
      type: "numeric",
      decimals: 2,
    },
  };

  return (
    <PageComponent
      tableTitle="Item Cogs"
      url={base_url + "financials-item-cogs/"}
      tableColumns={Object.values(fields)}
    />
  );
}
