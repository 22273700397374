import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function VendorDetails() {
    const url = `${process.env.REACT_APP_API_URL}catalog-vendor-details/`
    const fields = {
        'brand': {
            required: true,
            id: 'brand',
            label: 'Brand',
            header: 'Brand',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'vendor_name': {
            id: 'vendor_name',
            label: 'Vendor Name',
            header: 'Vendor Name',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'vendor_address': {
            id: 'vendor_address',
            label: 'Vendor Address',
            header: 'Vendor Address',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'vendor_city': {
            id: 'vendor_city',
            label: 'Vendor City',
            header: 'Vendor City',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'vendor_state': {
            id: 'vendor_state',
            label: 'Vendor State',
            header: 'Vendor State',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'vendor_zip': {
            id: 'vendor_zip',
            label: 'Vendor Zip',
            header: 'Vendor Zip',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'vendor_country': {
            id: 'vendor_country',
            label: 'Vendor Country',
            header: 'Vendor Country',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'vendor_phone': {
            id: 'vendor_phone',
            label: 'Vendor Phone',
            header: 'Vendor Phone',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'ordering_method': {
            id: 'ordering_method',
            label: 'Ordering Method',
            header: 'Ordering Method',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'order_from': {
            id: 'order_from',
            label: 'Order From',
            header: 'Order From',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'active_sourcing': {
            id: 'active_sourcing',
            label: 'Active Sourcing',
            header: 'Active Sourcing',
            type: 'bool',
        },
        'fba_ok': {
            id: 'fba_ok',
            label: 'FBA OK',
            header: 'FBA OK',
            type: 'bool',
        },
        'market_ok': {
            id: 'market_ok',
            label: 'Market OK',
            header: 'Market OK',
            type: 'bool',
        },
        'po_contact_name': {
            id: 'po_contact_name',
            label: 'PO Contact Name',
            header: 'PO Contact Name',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'po_contact_email': {
            id: 'po_contact_email',
            label: 'PO Contact Email',
            header: 'PO Contact Email',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'lead_time': {
            id: 'lead_time',
            label: 'Lead Time',
            header: 'Lead Time',
            type: 'numeric',
            decimals: 0,
            ip: { maxLength: 255 }
        },
        'payment_terms': {
            id: 'payment_terms',
            label: 'Payment Terms',
            header: 'Payment Terms',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'min_prepaid_freight': {
            id: 'min_prepaid_freight',
            label: 'Min Prepaid Freight',
            header: 'Min Prepaid Freight',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'vendor_tier': {
            id: 'vendor_tier',
            label: 'Vendor Tier',
            header: 'Vendor Tier',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'creation_date': {
            noEdit: true,
            type: 'date',
            format: 'MM/DD/YYYY',
            id: 'creation_date',
            header: 'Creation Date',
            label: 'Creation Date',
        },
        'revision_date': {
            noEdit: true,
            type: 'date',
            format: 'MM/DD/YYYY',
            id: 'revision_date',
            header: 'Revision Date',
            label: 'Revision Date'
        },
        'created_by': {
            noEdit: true,
            id: 'created_by',
            label: 'Created By',
            header: 'Created By',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'revised_by': {
            noEdit: true,
            id: 'revised_by',
            label: 'Revised By',
            header: 'Revised By',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'ordering_portal': {
            id: 'ordering_portal',
            label: 'Ordering Portal',
            header: 'Ordering Portal',
            type: 'anchor',
            anchor: 'https://',
            tag: 'ordering_portal',
            ip: { maxLength: 255 }
        },
        'edit': {
            header: 'Action',
            type: 'edit',
        },
    }

    return (
        <PageComponent
            tableTitle="Vendor Details"
            url={url}
            deleteItems
            addItems
            checkbox
            import
            bulkAdd
            bulkEdit
            requiredCSVColumns={[
                'brand'
            ]}
            formInputs={[fields.brand, fields.vendor_name, fields.vendor_address, fields.vendor_city, fields.vendor_state, fields.vendor_zip,
            fields.vendor_country, fields.vendor_phone, , fields.vendor_tier, fields.ordering_method, fields.ordering_portal, fields.order_from, fields.active_sourcing,
            fields.fba_ok, fields.market_ok, fields.po_contact_name, fields.po_contact_email, fields.lead_time, fields.payment_terms,
            fields.min_prepaid_freight]}

            tableColumns={[fields.brand, fields.vendor_name, fields.vendor_address, fields.vendor_city, fields.vendor_state, fields.vendor_zip,
            fields.vendor_country, fields.vendor_phone, fields.vendor_tier, fields.ordering_method, fields.ordering_portal, fields.order_from, fields.active_sourcing,
            fields.fba_ok, fields.market_ok, fields.po_contact_name, fields.po_contact_email,
            fields.lead_time, fields.payment_terms,
            fields.min_prepaid_freight, fields.created_by, fields.revised_by, fields.edit]}
        />
    )
}