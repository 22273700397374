import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function Status(){
    const base_url = process.env.REACT_APP_API_URL
    
    const fields={
        base_sku:{
            id: 'base_sku',
            header: 'Base Sku',
            type: 'text'
        },
        inv_group: {
            id: 'inv_group',
            header: 'Inventory Group',
            type: 'text',
        },
        stranded_reason: {
            id: 'stranded_reason',
            header: 'Stranded Reason',
            type: 'text',
        },
        onhand_qty: {
            id: 'onhand_qty',
            header: 'Onhand Qty',
            type: 'numeric',
            decimals: 0
        },
        total_pcogs_base: {
            id: 'total_pcogs_base',
            header: 'Total Pcogs',
            type: 'numeric',
            decimals: 2
        },
    }

 

    return(
        <PageComponent
            tableTitle="Inventory Status"
            url={base_url+ "inventory-status/"}
            tableColumns={Object.values(fields)}
        />
    )
}