import React, { useState, useEffect } from 'react';
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler,  } from "../../../_metronic/_partials/dropdowns";
export default function BasicDropdown(props) {
    

    return(
        <Dropdown className="dropdown-inline" alignRight>
            <Dropdown.Toggle
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
            >
                <i className="ki ki-bold-more-hor" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <ul className="navi navi-hover">
                    {props.items.map(item => {


                        return(
                            <li className="navi-item">
                                <a href="#" className="navi-link" onClick={item.onClick}>
                                    <span className="navi-text">{item.label}</span>
                                </a>
                            </li>
                        )
                    })}
                    
                </ul>
            </Dropdown.Menu>
        </Dropdown>
    )
}