// This is the barebone parent component where the AppBar with tabs reside 
// All the top tabs on App Bar are called from this component

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import VendorInvoiceItems from './VendorInvoiceItems';
import VendorInvoices from './VendorInvoices';
import InvoiceAlerts from './InvoiceAlerts';
import MissingInvoices from './MissingInvoices';
import InvoiceModal from '../../components/Modals/InvoiceModal';
import OverInvoices from './OverInvoices';
import PcogsDiscrepancy from './PcogsDiscrepancy';
import axios from 'axios'
import BasicSnackBar from '../../components/Alerts/BasicSnackBar';

function TabContainer({ children, dir, className }) {
  return (
    <Typography className={className}component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    
  },
}));

export default function Invoices() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [invoiceData, setInvoiceData] = useState(null)
  const [inboundData, setInboundData] = useState(null)
  const [vendorDetails, setVendorDetails] = useState(null)
  const [vendorSkus, setVendorSkus] = useState([])
  const [showInvoice, setShowInvoice] = useState(false)
  const [message, setMessage] = useState(null)
  const [isError, setIsError] = useState(false)
  const [selectedRow, setSelectedRow] = useState({
    po_number: null,
    order_date: null,
    vendor_name: null
  })


  //change event for the tabs
  function handleChange(event, newValue) {
    setValue(newValue);
  }

  //click event for an invoice number in each table
  function handleInvoiceSelect(invoice_num, row){
    //save the selected row
    setSelectedRow(row)

    setShowInvoice(true)

    //make the object to loop through
    let endpoints = [
      {url: `catalog-base-sku/?brand=${row.vendor_name}`, set: setVendorSkus},
      {url: `catalog-vendor-details/?vendor=${row.vendor_name}`, set: setVendorDetails},
      {url: `inventory-loc-inbound/?po=${row.po_number}`, set: setInboundData},
      {url: `financials-vendor-invoice-items/?invoice_num=${invoice_num}`, set: setInvoiceData},
    ]

    //loop through the object, call the endpoints, and set the data
    endpoints.map(ep => {
      axios.get(`${process.env.REACT_APP_API_URL}${ep.url}`).then(({data}) =>{
          ep.set(data)
      }).catch(function(error){
          ep.set([])
          console.log(error)
      })
    })
  }

  //callback function when modal is closed
  function closeModal(){
      setInvoiceData(null)
      setShowInvoice(false)
  }

    //callback function for the inputTable
    //sets submitted to true so that the form renders the errors if any
    function handleSubmit(result, newErrors){
      //check if there were any errors and set message accordingly
      if(!result){
          setIsError(true)
          setMessage('Error! Cannot update items')
      }
      else{
      
          setMessage(result + " items updated successfully with " + newErrors.failed.length  + " errors!")
          closeModal()
          
      }

  }

  //handles submition errors of the modal
  function handleErrors(){

  }
  
  //closes the snackbar
  function closeAlert(){
    setMessage(null)
    setIsError(null)
  }


  return (
    <div className={classes.root}>
      <BasicSnackBar
          open={message !== null}
          variant={isError ? 'error' : 'success'}
          message={message}
          handleClose={closeAlert}
      />
      <InvoiceModal
          show={showInvoice}
          view="invoices"
          handleCancel={closeModal}
          po_number={selectedRow.po_number}
          order_date={inboundData && inboundData[0].order_date}
          vendor_name={selectedRow.vendor_name}
          orderDetails={inboundData}
          vendorDetails={vendorDetails}
          topRowData={selectedRow}
          invoiceData={invoiceData}
          handleSubmit={handleSubmit}
          handleErrors={handleErrors}
          vendorSkus={vendorSkus}
      />
        
      <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Vendor Invoices" />
            <Tab label="Missing Invoices" />
            <Tab label="Over Invoices" />
            <Tab label="Invoice Alerts" />
            <Tab label="Invoice Items" />
            <Tab label="Pcog Discrepancies" />
          </Tabs>
      </AppBar>
      <TabContainer className={value !== 0 && 'd-none'}><VendorInvoices handleInvoice={handleInvoiceSelect}/></TabContainer>
      <TabContainer className={value !== 1 && 'd-none'}><MissingInvoices handleInvoice={handleInvoiceSelect}/></TabContainer>
      <TabContainer className={value !== 2 && 'd-none'}><OverInvoices handleInvoice={handleInvoiceSelect}/></TabContainer>
      <TabContainer className={value !== 3 && 'd-none'}><InvoiceAlerts handleInvoice={handleInvoiceSelect}/></TabContainer>
      <TabContainer className={value !== 4 && 'd-none'}><VendorInvoiceItems handleInvoice={handleInvoiceSelect}/></TabContainer>
      <TabContainer className={value !== 5 && 'd-none'}><PcogsDiscrepancy handleInvoice={handleInvoiceSelect}/></TabContainer>
    </div>
  );
}
