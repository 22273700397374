import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function BaseSKU() {
    const url = `${process.env.REACT_APP_API_URL}catalog-base-sku/`

    return (<>
        <PageComponent
            tableTitle="Base SKU"
            url={url}
            bulkAdd
            bulkEdit
            import
            deleteItems
            addItems
            checkbox
            requiredEditFields={['base_sku','pcogs']}
            requiredCSVColumns={
                [
                    'base_sku',
                    'active_source',
                    'pcogs',
                ]
            }
            formInputs={[
                {
                    required: true,
                    id: 'base_sku',
                    label: 'Base SKU',
                    ip: {maxLength: 100}
                },
                {
                    required: true,
                    id: 'active_source',
                    label: 'Active Source *',
                    type: 'bool'
                },
                {
                    id: 'brand',
                    label: 'Brand',
                    ip: {maxLength: 100}
                },
                {
                    required: true,
                    id: 'pcogs',
                    label: 'Product Cost',
                    helperText: 'Must be greater than 0',
                    ip: {inputMode: 'numeric', pattern: '[0-9]*'}
                },
                {
                    id: 'freight_rate',
                    label: 'Freight Rate',
                    ip: {maxLength: 100}
                },
                {
                    id: 'order_cpq',
                    label: 'Order CPQ',
                    ip: {maxLength: 100}
                },
                {
                    id: 'map_price',
                    label: 'Map Price',
                    ip: {maxLength: 100}
                },
                {
                    id: 'lead_time_days',
                    label: 'Lead Time Days',
                    ip: {maxLength: 100}
                },
                
            ]}
            tableColumns={[
                {
                    id: 'base_sku',
                    header: 'Base SKU',
                    type: 'text'
                },
                {
                    id: 'brand',
                    header: 'Brand',
                    type: 'text'
                },
                {
                    id: 'pcogs',
                    header: 'Product Cost ',
                    type: 'numeric',
                    decimals: 2
                },
                {
                    id: 'landed_pcogs',
                    header: 'Landed Product Cost',
                    type: 'numeric',
                    decimals: 2
                },
                {
                    id: 'freight_rate',
                    header: 'Inbound Freight Rate',
                    type: 'numeric',
                    decimals: 2
                },
                {
                    id: 'order_cpq',
                    header: 'Order CPQ',
                    type: 'numeric',
                    decimals: 1
                },
                {
                    id: 'map_price',
                    header: 'MAP Price',
                    type: 'numeric',
                    decimals: 2
                },
                {
                    id: 'lead_time_days',
                    header: 'Lead Time Days',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'active_source',
                    header: 'Active Source',
                    type: 'bool',
                },
                {
                    type: 'edit',
                    header: 'Actions'
                },
            ]}
        />
    </>);
}