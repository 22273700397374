import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink } from "react-router-dom";
import Rating from '@material-ui/lab/Rating';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const useStyles = makeStyles(theme => ({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflow: 'hidden',
      width: '100%'
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 100
    },
    actions: {
      marginLeft: '35%'
  }
  }));

export default function ProductTableBody(
    {
        loading,
        colNum,
        tableItems,
        itemCount,
        order,
        orderBy,
        page,
        rowsPerPage,
        edits,
        keyword,
        selected,
        handleClick,
        handleSelectChange,
        handleDeleteIcon,
        getRowStyle,
        showColumnModal,
        showEditModal,
        showVCEditModal,
        columns,
        field,
        datePicker,
        checkbox,
        small
    },
) {
    const classes = useStyles();

    //*******************METHODS************************* */
    function desc(a, b, orderBy) {
      
        if(orderBy.type === 'numeric'){
            if (parseFloat(b[orderBy.id]) < parseFloat(a[orderBy.id]) || b[orderBy.id] === null) {
              return -1;
            }
            if (parseFloat(b[orderBy.id]) > parseFloat(a[orderBy.id]) || a[orderBy.id] === null) {
              return 1;
            }
            return 0;

        }else if(orderBy.type === 'bool' || orderBy.type === 'bool-icon'){
          if ((b[orderBy.id] && (b[orderBy.id] !== a[orderBy.id])) || (!b[orderBy.id] && (b[orderBy.id] === a[orderBy.id]))) {
            return 1;
          }
          if ((b[orderBy.id] && (b[orderBy.id] === a[orderBy.id])) || (!b[orderBy.id] && (b[orderBy.id] !== a[orderBy.id]))) {
            return -1;
          }
          return 0;
        }else{

          if (b[orderBy.id] < a[orderBy.id] || !b[orderBy.id]) {
            return -1;
          }
          if (b[orderBy.id] > a[orderBy.id] || !a[orderBy.id]) {
            return 1;
          }
          return 0;
        }
    }

    function stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = cmp(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
      }
      
      function getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
      }

    const isSelected = name => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, itemCount - page * rowsPerPage);

    return(
        <TableBody>
              {loading? 
                <TableRow>
                  <TableCell colSpan={colNum} align="center" className="pt-10 border-0">
                    <div className="w-100 d-flex flex-column flex-center ">
                      <h5>Loading Data...</h5>
                      <div><CircularProgress color="secondary"/></div>
                    </div>
                  </TableCell>
                </TableRow>
              :
              <>
              {(itemCount > 0 ? 
                stableSort(tableItems, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    if((datePicker) && !keyword){
                      if(index > 0){
                        return null;
                      }
                      return (<TableRow><TableCell colSpan={colNum} align='center' className="border-0 font-size-h6 font-weight-bold"> Please select a {field} in the table toolbar</TableCell></TableRow>)
                    }
                    return (
                      <TableRow
                        hover
                        onClick={event => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                        style={getRowStyle && getRowStyle(row)}
                        id="row"
                      >

                        {checkbox &&
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell> 
                        }
                        

                        {columns.map((col, index) => {
                          let cellChild = null;
                          

                          switch(col.type){
                            case 'image':
                              cellChild = <div className="d-flex  flex-center" style={small?{height:25, width:25}:{height:50, width:50}}>
                                            {row[col.id]?<img src={row[col.id]} alt={"Item Image"} style={small? {maxHeight:25, maxWidth:25} :{maxHeight:50, maxWidth:50}}></img>
                                            :<span className="symbol symbol-35 symbol-light-primary" style={small?{height:25, width:25}:{height:50, width:50}}>
                                                <span className="symbol-label font-size-h5 font-weight-bold w-100 h-100">
                                                  <svg className="h-75 w-75" viewBox="0 0 24 24" version="1.1">
                                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                          <rect x="0" y="0" width="24" height="24"/>
                                                          <path d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z" fill="#000000" opacity="0.3"/>
                                                          <polygon fill="#000000" points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912"/>
                                                      </g>
                                                  </svg>
                                                </span>
                                              </span>}
                                          </div>

                              break;
                            case 'modal':
                              cellChild = <a className='text-primary' onClick={()=> showColumnModal(row[col.id])}> {row[col.id]} </a>
                              break;
                            
                              
                            case 'text':
                              cellChild = row[col.id]
                              //limit the character length
                              let lim = 30

                              if(col.lim){
                                lim=col.lim
                              }
                              if(cellChild && cellChild.length > lim){
                                cellChild = <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                disabled 
                                                overlay={<Tooltip> {row[col.id]} </Tooltip>}
                                                >
                                                <span>{row[col.id].slice(0,lim) + '...'}</span>
                                            </OverlayTrigger>
                                
                              }
                              break;
                            case 'subtract':
                              let num = parseFloat(row[col.id1] - row[col.id2]).toFixed(col.decimals)
                              let str = num.toString().split(".");                    //turn back to a string and split by decimal point
                              str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");  //add commas to the string 
                              cellChild =  str.join("."); 
                              break;
                            case 'numeric':
                              if(row[col.id]){
                                let num = parseFloat(row[col.id]).toFixed(col.decimals) //turn it into a number with decimals
                                let str = num.toString().split(".");                    //turn back to a string and split by decimal point
                                str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");  //add commas to the string 
                                cellChild =  str.join(".");                             //join the decimal to the string
                              }else{
                                cellChild = 0;
                              }                       
                              break;
                            case 'calculate': 
                              cellChild = col.function(row)
                              break;
                            case 'bool':
                            case 'bool-icon':
                              if(row[col.id] === true || row[col.id] === 'True'){
                                cellChild = <div className="d-inline-flex flex-center mx-3">
                                              <span className="svg-icon svg-icon-md svg-icon-success">
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                      <rect x="0" y="0" width="24" height="24"/>
                                                      <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                                                      <path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero"/>
                                                  </g>
                                                </svg>
                                              </span>
                                            </div>
                              }else{
                                cellChild = <div className="d-inline-flex flex-center mx-3">
                                              <span className="svg-icon svg-icon-md svg-icon-danger">
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                      <rect x="0" y="0" width="24" height="24"/>
                                                      <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                                                      <path d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z" fill="#000000"/>
                                                  </g>
                                                </svg>
                                              </span>
                                            </div>
                              }
                              break;
                            case 'link':
                              cellChild = <NavLink to={col.link + row[col.id]}>{row[col.id]}</NavLink>
                              break;
                            case 'redirect':
                              cellChild = <NavLink to={{pathname :col.path, state:row}}>{row[col.id]}</NavLink>
                              break;
                            case 'function':
                              cellChild= <a className='text-primary' onClick={() => col.function(row[col.id], row)}> {row[col.id]} </a>
                              break;
                            case 'button':
                              cellChild= <a className={col.className} onClick={() => col.function(row, col.header)}> {col.label} </a>
                              break;
                            case 'anchor':
                              cellChild = <a href={col.anchor + row[col.tag]} target="_blank" rel="noopener noreferrer">
                                              {row[col.id]}
                                          </a>
                              break;
                            case 'rating':
                              cellChild = <Rating name="size-small" value={row[col.id]? parseFloat(row[col.id]): 0} size="small" precision={0.1}  readOnly />
                              break;
                            case 'date':
                              let date = row[col.id]
                              if( date === null){
                                cellChild = ''
                              }else{
                                cellChild = moment(date).format(col.format)
                              }
                              break;
                            case 'edit':
                              cellChild = <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3" onClick={showEditModal(row)}>
                                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"/>
                                                        <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                                                        <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                                                    </g>
                                                </svg>
                                              </span>
                                            </a>
                              break;
                            case 'vcedit':
                                cellChild = <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3" onClick={showVCEditModal(row)}>
                                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                                  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                          <rect x="0" y="0" width="24" height="24"/>
                                                          <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                                                          <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                                                      </g>
                                                  </svg>
                                                </span>
                                              </a>
                                  break;
                            case 'delete':
                              cellChild = <a className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3" onClick={() => handleDeleteIcon(row.id)}>
                                              <span className="svg-icon svg-icon-md svg-icon-danger">
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                      <rect x="0" y="0" width="24" height="24"/>
                                                      <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                                                      <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000"/>
                                                  </g>
                                                </svg>
                                              </span>
                                            </a>
                              break;
                            case 'select':
                              let value = edits[row.id]
                              if(value === null || typeof value === 'undefined'){
                                value = row[col.id]
                                if(value === null ){
                                  value = ''
                                }
                              }
                              cellChild =  <form  autoComplete='off'>
                                            <FormControl className={classes.formControl}>     
                                              <Select
                                                name='select'
                                                value={value}
                                                onChange={handleSelectChange(row, col.id)}
                                                displayEmpty               
                                              >
                                                
                                                { col.options.map((key) =>{
                                                  return(
                                                    <MenuItem 
                                                    value={key.value}
                                                    disabled={key.value === value}
                                                    >                                                  
                                                      {key.label}
                                                    </MenuItem>
                                                  );
                                                })}
                                              </Select>
                                            </FormControl>
                                            </form>
                          }

                        
                          return(
                            <TableCell onClick={col.onClick? () => col.onClick(row, col.id) : null} className='p-2' key={index} align={col.type === 'numeric' || col.type==='subtract'? 'right': 'left'}>
                              {cellChild}
                            </TableCell>
                          );
                        })}                  
                      </TableRow>
                    );
                  }) : <TableRow ><TableCell colSpan={colNum} align='center' className='border-0'>No Data Available</TableCell></TableRow>)} </>}
              {emptyRows > 0 && (
                <TableRow style={small? { height: 30 * emptyRows }: { height: 49 * emptyRows }}>
                  <TableCell colSpan={colNum} />
                </TableRow>
              )}
            </TableBody>
    )
}