import React, { useState, useEffect } from 'react';
import ProductTable from '../../components/Tables/ProductTable/ProductTable';
import axios from 'axios';
import moment from 'moment';

export default function RepricedItems() {
    const [tableData, setTableData] = useState(null);
    const [datesPicked, setDatesPicked] = useState({}); //stores the previous selected dates and the data

    //******************EFFECTS********************* */
    //effect for initializing the data for the table
    //initialized it with todays date
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}pricing-repricer-item-log/?year=${moment().year()}&month=${moment().month() + 1}&day=${moment().date()}`)
        .then(({data}) => {
            setTableData(data)
            
            let date = moment().year() + '-'+( moment().month() +1 )+'-'+ moment().date()
            let newDate = datesPicked
            newDate[date] = data
            setDatesPicked(newDate)
        }).catch(function(error){
            setTableData([])
            console.log(error)
        })
    }, []);
 
    //******************METHODS********************* */
    //function for handling when a user selects a date from the date picker
    //takes the date value and passes it in to the GET call as a parameter
    //then stores the response in state and displays in table
    function handlePicker(value){
        //first check to see if the data for the date picked is already stored
        if(datesPicked[value]){
            setTableData(datesPicked[value])
        }
        else{
            //if not already picked then call the enpoint and store the data
            //but first seperate the date value into year, month, and day params 
            let dateArray = value.split('-')
            let year = dateArray[0], month = dateArray[1], day =dateArray[2]
            axios.get(`${process.env.REACT_APP_API_URL}pricing-repricer-item-log/?year=${year}&month=${month}&day=${day}`)
            .then(({data}) => {
                //set tabledata to response so that data will display in table
                setTableData(data);
                
                //store the date value with its response so that the endpoint doesnt get called again for this date
                let newDate = datesPicked
                newDate[value] = data
                setDatesPicked(newDate);

            }).catch(function(error){
                //if theres an error calling the endpoint then set everything to empty
                setTableData([])
                console.log(error)
            })
        }
    }


  
    return (
        <>
        <div>
            <ProductTable
                title="Repriced Items"
                datePicker
                field="Date"
                handlePicker={handlePicker}
                data={tableData}
                rows={25}
                cols={11}
                columns={[
                    {
                        type: 'date',
                        format: 'ddd, MMM Do @h:mm a',
                        id: 'batch_date',
                        header: 'Batch Date',
                    },
                    {
                        id: 'seller_account',
                        header: 'Seller Account',
                        type: 'text'
                    },
                    {
                        id: 'script',
                        header: 'Script',
                        type: 'text'
                    },
                    {
                        anchor: 'https://www.amazon.com/dp/',
                        tag: 'asin',
                        id: 'asin',
                        header: 'ASIN',
                        type: 'anchor'
                    },
                    {
                        id: 'brand',
                        header: 'Brand',
                        type: 'text'
                    },
                    {
                        id: 'we_win',
                        header: 'We Win',
                        type: 'bool'
                    },
                    {
                        id: 'buybox_landed_price',
                        header: 'Buybox Landed Price',
                        type: 'numeric',
                        decimals: 2
                    },
                    {
                        id: 'rule',
                        header: 'Rule',
                        type: 'text'
                    },
                    {
                        id: 'new_price',
                        header: 'New Price',
                        type: 'numeric',
                        decimals: 2
                    },

                ]}
            
            />
        </div>
    </>);
}