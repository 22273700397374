import axios from "axios";
import React from "react";
import ReactDOM from 'react-dom'
import moment from "moment";
import { LinearProgress, Button } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import BasicSnackBar from "../../Alerts/BasicSnackBar";

const excludedDates=['batch_date', 'snapshot_date', 'creation_date', 'revision_date', 'run_date']
let source = axios.CancelToken.source();
const RoundedProgress = withStyles({
  root: {
    height: 8,
    borderRadius: 20,
  },
  bar:{
    backgroundColor: '#015d87 !important',
}
})(LinearProgress);

//function for rendering the alert message when a user adds/deletes/uploads a csv
function renderAlert(alert,progress, message){
  return(
    ReactDOM.render(
      <BasicSnackBar
        noClose
        open={alert} 
        variant={'success'} 
        message={
          <div className='d-flex flex-column flex-center' style={{minWidth: 200}}>
            <div className='font-size-lg'>Please Wait...{message}</div>
            <div className='w-100 mt-3'>
              <RoundedProgress
                variant='determinate'
                value={progress}      
                style={{root:{
                  borderRadius: 20,
                }}}
              />
            </div>
          </div>
        } 
        handleClose={() => renderAlert(false, progress, message)}
      />,
      document.getElementById('message')
    )
  )
}

//cancels uploading 
function handlCancel(){
  source.cancel()
  
}

//returns data that would go in the table 
const getTableData = (mode, lim) => {
    let api = process.env.REACT_APP_API_URL
    let limit = lim;
    if(!limit){
      limit=100;
    }
    let url = '';
    //get the enpoint url based on which mode the app is in
    switch(mode)
    {
      case 'Adjustments': url = `${api}inventory-loc-adjustments/`;   
      break;
      case 'ASINSKU': url = `${api}catalog-asin-sku/`;   
      break;
      case 'ASINMargin': url = `${api}catalog-asin-margin/`;   
      break;
      case 'ASINInventory': url = `${api}pricing-rule-asin-inventory/?type=instock`;   
      break;
      case 'BaseSKU': url = `${api}catalog-base-sku/`;   
      break;
      case 'DirectShip': url = `${api}inventory-loc-direct-ship/`;   
      break;
      case 'DistCandidates': url = `${api}expand-new-items/`;   
      break;
      case 'DPScraper': url = `${api}scraper-asin-scrape/`;   
      break;
      case 'ExistingBrands': url = `${api}expand-existing-brands/`;   
      break;
      case 'FBALatest': url = `${api}inventory-fba-hourly/`;   
      break;
      case 'FBATransfer': url = `${api}inventory-loc-fba-transfer/`;   
      break;
      case 'Inbound': url = `${api}inventory-loc-inbound/`;   
      break;
      case 'InventoryHealth': url = `${api}inventory-health/`;   
      break;
      case 'MessageLog': url = `${api}feedback-message-log/`;   
      break;
      case 'OrderItems': url = `${api}sales-order-items/`;   
      break;
      case 'OrderQueue': url = `${api}feedback-order-queue/`;   
      break;
      case 'PricingRules': url = `${api}pricing-rules-list/`;   
      break;
      case 'RepricerLog': url = `${api}pricing-repricer-run-log/`;   
      break;
      case 'StagnantItems': url = `${api}inventory-stagnant-items/`;   
      break;
      case 'WOCAnalysis': url = `${api}inventory-woc-analysis/`;   
      break;
      
    }
    return axios.get(url);
}


//recursive function that deletes items
//makes 20 DELETE calls at a time 
//after every 20 calls theres a 1sec delay
function DeleteTableData(url,count, errors,  selectedItems, items){
  return new Promise(resolve => {
    renderAlert(true, (count/items.length * 100), 'Deleting Items')
    if(count < items.length){
      //get the product key of each item
      selectedItems.map((item, index) =>{        
        //call the enpoint and set the items to the response of the call
        axios.delete(url + item + '/', {cancelToken: source.token})
        .then(({ data}) => {
        }).catch(function(error){
          if(axios.isCancel(error)){
            console.log('REQUEST CANCELLED')
            source = axios.CancelToken.source()
            errors.cancelled = true
            renderAlert(false, (count/items.length * 100), 'Request Cancelled')
            return resolve(count + index)
          }else{
            errors.count++
            console.log(error);
          }        
        })
      })

      setTimeout(() => {
        let newCount = count;
        newCount += selectedItems.length;
        return resolve(DeleteTableData(url, newCount, errors,  items.slice(newCount, newCount+20), items));
      }, 1000);
    }
    else{
      renderAlert(false, 100, 'Deleting Items')
      return resolve(count- errors.count)
    }
  })

}

//similar to the delete function except we have to pass in the column headers as well
const AddTableData =(url, count, errors,  itemChunk, items)=>{
  return new Promise(resolve => {
    renderAlert(true, (count/items.length * 100), 'Adding Items')
    if(count < items.length){
      itemChunk.map((item) =>{

        if(item['pcogs'] && parseFloat(item['pcogs']) <= 0){
          let newItem = {...item}
          newItem['reason']= 'pcogs value must be a number greater than 0'
          errors.failed.push(newItem)
          return
        }
        
        //make sure the reason column doesnt get sent
        if(item['reason']){
          delete item['reason']
        }
        if(item['check']){
          delete item['check']
        }
        if(item['error']){
          delete item['error']
        }

        //format dates correctly
        Object.keys(item).map(col => {
          //check if key is a date and not in the excluded dates list
          if((col.indexOf('_date') > -1 || col === 'window_start' || col === 'window_end') && excludedDates.indexOf(col) === -1){
            if(!item[col]){
              item[col] = null
            }
            else{
              item[col] = moment(item[col]).format('YYYY-MM-DD') //format dates to fit YYYY-MM-DD
            }
          }
        })

        //make the post call to add the item
        axios.post(url, item, {cancelToken: source.token})
        .then(({ data}) => {

        }).catch(function(error){
          if(axios.isCancel(error)){
            console.log('REQUEST CANCELLED')
            source = axios.CancelToken.source();
            errors.cancelled = true
            renderAlert(false, (count/items.length * 100), 'Request Cancelled')
            return resolve(count)
          }else{
            //if call fails then add the error message as a reason
            let newItem = {...item}
            newItem['reason']= JSON.stringify(error.response.data)
            errors.failed.push(newItem)
            console.log(error.response.data);
          }     
        })        

      })

      setTimeout(() => {
        let newCount = count;
        newCount += itemChunk.length;
        return resolve(AddTableData(url, newCount, errors, items.slice(newCount, newCount+20), items))
      }, 1000);
    }
    else{
      renderAlert(false, (100), 'Adding Items')
      return resolve(count -(errors.failed.length + errors.empty));  
    }
  })
}

 function AddSingleItem (url, body){
  return new Promise(resolve => {
    axios.post(url, body)
    .then(({ data}) => {
      resolve(true)
    }).catch(function(error){
      console.log(error)
       resolve(false)
    })
  })

}

//function for updating the table items
//recursively makes 20 PUT calls at a time then 700ms delay before the next batch of calls
function UpdateTable(url, count, errors, itemChunk, items){
  return new Promise(resolve => {
    renderAlert(true, (count/items.length * 100), 'Updating Items')
      if(count < items.length){
          itemChunk.map(item =>{

            //make sure the reason column doesnt get sent
            if(item['reason']){
              delete item['reason']
            }

            //format dates correctly
            Object.keys(item).map(col => {
              //check if key is a date and not in the excluded dates list
              if((col.indexOf('date') > -1 || col === 'window_start' || col === 'window_end') && excludedDates.indexOf(col) === -1){
                if(!item[col]){
                  item[col] = null
                }
                else{
                  item[col] = moment(item[col]).format('YYYY-MM-DD') //format dates to fit YYYY-MM-DD
                }
              }
            })
 
            axios.put(url + item.id + '/', item, {cancelToken: source.token})
            .then(({data}) => {

            }).catch(function(error){
                let newItem = {...item}
                newItem['reason']= JSON.stringify(error.response.data)
                errors.failed.push(newItem)
            })
          })

          count+= itemChunk.length

          setTimeout(() => {
            return resolve(UpdateTable(url, count, errors, items.slice(count, count+20), items))
          }, 1000);

      }else{
          renderAlert(false, 100, 'Updating Items')
          return resolve(count -(errors.failed.length + errors.empty));  
      }
  })
}

//similar to the delete function except we have to pass in the column headers as well
const AddTableDataWithCheck=(url, count, errors,  itemChunk, items)=>{
  return new Promise(resolve => {
    renderAlert(true, (count/items.length * 100), 'Adding Items')
    if(count < items.length){
      itemChunk.map((item) =>{

        //format dates correctly
        Object.keys(item).map(col => {
          //check if key is a date and not in the excluded dates list
          if((col.indexOf('_date') > -1 || col === 'window_start' || col === 'window_end') && excludedDates.indexOf(col) === -1){
            if(!item[col]){
              item[col] = null
            }
            else{
              item[col] = moment(item[col]).format('YYYY-MM-DD') //format dates to fit YYYY-MM-DD
            }
          }
        })

        //make the post call to add the item
        axios.post(url, item)
        .then(({ data}) => {

        }).catch(function(error){
          //if call fails then add the error message as a reason
            let newItem = {...item}
            newItem['reason']= JSON.stringify(error.response.data)
            errors.failed.push(newItem)
            console.log(error.response.data);
        })        

      })

      setTimeout(() => {
        let newCount = count;
        newCount += itemChunk.length;
        return resolve(AddTableData(url, newCount, errors, items.slice(newCount, newCount+20), items))
      }, 1000);
    }
    else{
      renderAlert(false, (100), 'Adding Items')
      return resolve(count -(errors.failed.length + errors.empty));  
    }
  })
}

//checks if the target item is in the inputted list
//return true if so
function doesItemExist(target, list, field, match){
  //loop through the input list and match the target value to each item's field in the list
  for (let i = 0; i < list.length; i++) {
    //if the target item was found then return true
    if(target[field] === list[i][field]){
      //if theres another field to match
      if(match){
        if(target[match] === list[i][match]){
          return true
        }
      }
      else{
        return true
      }
      
    }
  }

  //return false only after we looped through the entire list without a match
  return false
}

  function desc(a, b, orderBy) {
    
    if(orderBy.type === 'numeric'){
        if (parseFloat(b[orderBy.id]) < parseFloat(a[orderBy.id]) || b[orderBy.id] === null) {
          return -1;
        }
        if (parseFloat(b[orderBy.id]) > parseFloat(a[orderBy.id]) || a[orderBy.id] === null) {
          return 1;
        }
        return 0;

    }else if(orderBy.type === 'bool' || orderBy.type === 'bool-icon'){
      if ((b[orderBy.id] && (b[orderBy.id] !== a[orderBy.id])) || (!b[orderBy.id] && (b[orderBy.id] === a[orderBy.id]))) {
        return 1;
      }
      if ((b[orderBy.id] && (b[orderBy.id] === a[orderBy.id])) || (!b[orderBy.id] && (b[orderBy.id] !== a[orderBy.id]))) {
        return -1;
      }
      return 0;
    }else{

      if (b[orderBy.id] < a[orderBy.id] || !b[orderBy.id]) {
        return -1;
      }
      if (b[orderBy.id] > a[orderBy.id] || !a[orderBy.id]) {
        return 1;
      }
      return 0;
    }
  }

  function stableSort(array, cmp) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map(el => el[0]);
    }
    
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

export {DeleteTableData}
export {AddTableData}
export {AddSingleItem}
export {getTableData}
export {UpdateTable}
export {doesItemExist}
export {stableSort}
export {getSorting}