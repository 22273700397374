import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function Inbound() {
    const history = useHistory()
    const url = `${process.env.REACT_APP_API_URL}inventory-loc-inbound/`
    const [vendors, setVendors] = useState(null)
    const [paymentTerms, setPaymentTerms] = useState(null)
    const [baseSkus, setBaseSkus] = useState(null)

//effect for getting the list of vendor names from the vendor details table
  //cancels ongoing request when componenet is unmounted
  useEffect(() => {
    const source = axios.CancelToken.source();

    axios.get(process.env.REACT_APP_API_URL + 'catalog-vendor-details/?list=vendor_name', {cancelToken: source.token}).then(({data}) => {
      setVendors(data)
    }).catch(function(error){
      if(axios.isCancel(error)){
        console.log('REQUEST CANCELLED')
      }else{
        setVendors([])
        console.log(error)
      }
    })

    
    //get the list of payment terms
    axios.get(process.env.REACT_APP_API_URL + 'financials-payment-terms/', {cancelToken: source.token}).then(({data}) => {
        let newData = []
        data.map(term => {
            newData.push(term.payment_terms)
        })
        setPaymentTerms(newData)
    }).catch(function(error){
        if(axios.isCancel(error)){
            console.log('REQUEST CANCELLED')
        }else{
            setPaymentTerms([])
            console.log(error)
        }
    })

    //get the list of base skus
    axios.get(process.env.REACT_APP_API_URL + 'catalog-base-sku/', {cancelToken: source.token}).then(({data}) => {

        setBaseSkus(data)
    }).catch(function(error){
        if(axios.isCancel(error)){
            console.log('REQUEST CANCELLED')
        }else{
            setBaseSkus([])
            console.log(error)
        }
    })

    return () => {
      source.cancel()
    }
  }, []);

      //method called when user clicks on a PO number
    //redirects user to the purchase order page and passes the input PO as a state parameter
    function sendToPOPage(po){
        history.push({pathname: '/instock/purchase-orders/', state: po})
    }

    return(
        <PageComponent
            tableTitle="Inbound"
            url={url}
            bulkAdd
            bulkEdit
            import
            deleteItems
            addItems
            checkbox
            checkFields={[
                {
                    id: 'vendor_name',
                    options: vendors && vendors,
                    optionsType: 'array'
                },
                {
                    id: 'base_sku',
                    options: baseSkus,
                    optionsType: 'object'
                },
                {
                    id: 'payment_terms',
                    options: paymentTerms && paymentTerms,
                    optionsType: 'array'
                }
            ]}
            bulkEdits={[
                {
                    type: 'modal',
                    headerIDs: ['base_sku', 'order_date'],
                    btnTitle: 'Receive',
                    fields:[
                        {
                            id: 'qty_received',
                            header: 'Quantity Received',
                            type: 'numeric'
                        },
                        {
                            id: 'qty_cancelled',
                            header: 'Quantity Cancelled',
                            type: 'numeric',
                            decimals: 0
                        },
                        {
                            id: 'received_date',
                            header: 'Received Date',
                            type: 'date'
                        }
                    ]
                }
            ]}
            requiredCSVColumns={
                [
                    'order_date',
                    'po_number',
                    'vendor_name',
                    'base_sku',
                    'qty_ordered',
                    'payment_terms'
                ]
            }
            formInputs={[
                {
                    required: true,
                    id: 'order_date',
                    label: 'Order Date',
                    type: 'date'
                },
                {
                    required: true,
                    id: 'po_number',
                    label: 'PO Number',
                    ip: {maxLength: 100}
                },
                {
                    required: true,
                    id: 'vendor_name',
                    label: 'Vendor',
                    ip: {maxLength: 100},
                    type: 'select',
                    options: vendors && vendors
                },
                {
                    required: true,
                    id: 'payment_terms',
                    label: 'Payment Terms',
                    ip: {maxLength: 100},
                    type: 'select',
                    options: paymentTerms && paymentTerms
                },
                {
                    required: true,
                    id: 'base_sku',
                    label: 'Base SKU',
                    ip: {maxLength: 100}
                },
                {
                    required: true,
                    id: 'qty_ordered',
                    label: 'Quantity Ordered',
                    ip: {maxLength: 50}
                },
                {
                    id: 'qty_received',
                    label: 'Quantity Received',
                    ip: {maxLength: 50}
                },
                {
                    id: 'qty_cancelled',
                    label: 'Quantity Cancelled',
                    ip: {maxLength: 50}
                },
                {
                    id: 'intended_channel',
                    label: 'Channel',
                    ip: {maxLength: 100}
                },
            ]}
            tableColumns={[
                {
                    type: 'date',
                    format: 'MM/DD/YYYY',
                    id: 'order_date',
                    header: 'Order Date',
                },
                {
                    id: 'po_number',
                    header: 'PO Number',
                    type: 'function',
                    function: sendToPOPage
                },
                {
                    id: 'vendor_name',
                    header: 'Vendor Name',
                    type: 'text',
                },
                {
                    id: 'base_sku',
                    header: 'Base SKU',
                    type: 'text',
                },
                {
                    id: 'qty_ordered',
                    header: 'Quantity Ordered',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'qty_received',
                    header: 'Quantity Received',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'qty_cancelled',
                    header: 'Quantity Cancelled',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'intended_channel',
                    header: 'Channel',
                    type: 'text',
                },
                {
                    header: 'Actions',
                    type: 'edit',
                },
            ]}
        />
    )
}