import React from "react";
import {Redirect, Switch} from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import BrandOverview from "../modules/Dashboard/BrandOverview";
import CatalogOverview from "../modules/Dashboard/CatalogOverview";
import InventoryOverview from "../modules/Dashboard/InventoryOverview";
import WeeklyOverview from "../modules/Dashboard/WeeklyOverview";
import { SalesOverview } from "../modules/Dashboard/SalesOverview";


export default function DashboardPage() {
    return (
        <Switch>
          <Redirect
            exact={true}
            from="/dashboard"
            to="/dashboard/sales-overview"
          />  
          <ContentRoute
            path="/dashboard/sales-overview"
            component={SalesOverview} 
          />
          <ContentRoute
            path="/dashboard/brand-overview" 
            component={BrandOverview} 
          /> 
          <ContentRoute
            path="/dashboard/inventory-overview" 
            component={InventoryOverview} 
          /> 
          <ContentRoute
            path="/dashboard/catalog-overview" 
            component={CatalogOverview} 
          />   
          <ContentRoute
            path="/dashboard/weekly-overview" 
            component={WeeklyOverview} 
          />      
        </Switch>
    );
}