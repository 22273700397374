import React, { useState, useEffect } from 'react';
import PageComponent2 from '../../components/PageComponent2';

export default function InventoryValuation() {
    const url = `${process.env.REACT_APP_API_URL}inventory-loc-valuation/`

    return(
        <PageComponent2
            tableTitle="Inventory Valuation"
            url={url}
            tableColumns={[
                {
                    id: 'base_sku',
                    header: 'Base SKU',
                    type: 'text',
                },
                {
                    id: 'brand',
                    header: 'Brand',
                    type: 'text',
                },
                {
                    id: 'onhand_units',
                    header: 'Onhand Units',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'onhand_value',
                    header: 'Onhand Value',
                    type: 'numeric',
                    decimals: 2
                },
                {
                    id: 'onhand_age_days',
                    header: 'Onhand Age (days)',
                    type: 'numeric',
                    decimals: 0
                },
            ]}
        />
    )
}