import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function Adjustments() {
    const url = `${process.env.REACT_APP_API_URL}inventory-loc-adjustments/`

    return(
        <PageComponent
            tableTitle="Adjustments"
            url={url}
            resetSave
            import
            deleteItems
            addItems
            checkbox
            bulkAdd={true}
            bulkEdit
            bulkEditField='reviewed_flag'
            bulkEdits={[
                {
                    type: 'select',
                    id: 'reviewed_flag',
                    label: 'Reviewed',
                    options: [
                        {value: true, label: 'Yes'},
                        {value: false, label: 'No'},
                    ]
                }
            ]}
            requiredCSVColumns={
                [
                    'adjustment_date',
                    'reason_code',
                    'base_sku',
                    'qty_adjusted',
                ]
            }
            formInputs={[
                {
                    required: true,
                    id: 'adjustment_date',
                    label: 'Adjustment Date',
                    type:'date'
                },
                {
                    required: true,
                    id: 'base_sku',
                    label: 'Base SKU',
                    ip: {maxLength: 100}
                },
                {
                    required: true,
                    id: 'qty_adjusted',
                    label: 'Quantity Adjusted',
                    ip: {maxLength: 50}
                },
                {
                    required: true,
                    id: 'reason_code',
                    label: 'Reason',
                    ip: {maxLength: 100}
                },
                {
                    id: 'reviewed_flag',
                    label: 'Reviewed',
                    ip: {maxLength: 50}
                },
            ]}
            tableColumns={[
                {
                    type: 'date',
                    format: 'MM/DD/YYYY',
                    id: 'adjustment_date',
                    header: 'Adjustment Date',
                },
                {
                    id: 'base_sku',
                    header: 'Base SKU',
                    type: 'text',
                },
                {
                    id: 'qty_adjusted',
                    header: 'Quantity Adjusted',
                    type: 'numeric',
                    decimals: 0
                },
                {
                    id: 'reason_code',
                    header: 'Reason',
                    type: 'text',
                },
                {
                    id: 'reviewed_flag',
                    header: 'Reviewed',
                    type: 'bool',
                },
                {
                    header: 'Action',
                    type: 'edit',
                },
            ]}
        />
    )
}