// Modal Component that combines a few tables, mainly Inbound, VendorInvoiceItems, and VendorDetails.
// See PO and Invoices for how to pass in each prop
// Uses InvoiceTable to display the data

import React, { useState, useEffect } from 'react';
import BasicModal from './BasicModal';
import InvoicesTable from '../Tables/InvoicesTable';
import axios from 'axios'
import moment from 'moment'


function InvoiceModal({
    handleCancel, show, po_number, vendor_name, order_date, vendorSkus, view,
    orderDetails, vendorDetails, handleSubmit, handleErrors, invoiceData, topRowData
}) {
    const base_url = process.env.REACT_APP_API_URL
    const [mode, setMode] = useState(null)
    const [vendorBills, setVendorBills] = useState(null)
    const [selectedInvoiceData, setSelecetedInvoiceData] = useState({})
    const [paymentTerms, setPaymentTerms] = useState(null)
    const [allData, setAllData] = useState(null)
    const [invoiceVals, setInvoiceVals] = useState(
        {
            invoice_date: null,
            invoice_number: "",
            payment_terms: "",
            invoice_paid: null,
            due_date: null,
            total_amount: 0
        }
    )

    const [topColumns, setTopColumns] = useState(
        [
            {
                id:'invoice_date',
                type: 'date',
                header: 'Invoice Date',
            },
            {
                id:'invoice_number',
                type: 'select',
                header: 'Invoice Number',
                options: []
            },
            {
                id:'payment_terms',
                type: 'select',
                header: 'Payment Terms',
                options: [] 
            },
            {
                id:'invoice_paid',
                type: 'bool',
                header: 'Invoice Paid',
            },
            {
                id:'due_date',
                type: 'date',
                header: 'Due Date',
            },
            {
                id:'total_amount',
                type: 'display-num',
                header: 'Total Amount',
                disabled: true
            },
        ]
    )

    const columns = [
        {
            id:'sku',
            type: 'select',
            header: 'SKU',
            disabled: true,
            options: vendorSkus
        },
        {
            id:'qty_ordered',
            type: 'numeric',
            header: 'Qty. Ordered',
            decimals: 0,
            disabled: true
        },
        {
            id:'qty_received',
            type: 'numeric',
            header: 'Qty. Received',
            decimals: 0,
            disabled:true
        },
        {
            id:'qty',
            type: 'numeric',
            header: 'Qty. Invoiced',
            decimals: 0
        },
        {
            id:'unit_line_price',
            type: 'numeric',
            header: 'Unit Price',
            decimals: 2,
        },
        {
            id:'total_line_price',
            type: 'display-num',
            header: 'Total Price',
            decimals: 2,
            disabled: true
        },
    ]

    //gets and sets the payment terms options for the select
    //only needs to run once
    useEffect(() => {
        //get the list of payment terms
        axios.get(base_url + 'financials-payment-terms/').then(({data}) => {
            let newOptions = []
            data.map(item =>{
                newOptions.push({value:item.payment_terms, header: item.payment_terms})
            })
            let newColumns = topColumns
            newColumns[2]["options"] = newOptions
            setTopColumns(newColumns)
            setPaymentTerms(data)
        }).catch(function(error){
            console.log(error)
        })
    }, []);

    //effect for initializing the data for the table
    useEffect(() => {
        if(po_number && orderDetails && vendorDetails){

            //check if data has been inputted
            if(view === 'invoices'){
                if(invoiceData){
                    axios.get(base_url + 'financials-vendor-invoice-items/?po=' +   po_number).then(({data})=> {
                        switchTopOptions(data)
                        MergeInvoiceData()
                    }).catch(function(error){
                        console.log(error)
                        MergeInvoiceData()
                    })
                }
                
                let newData = {}
                topColumns.map(col => {
                    newData[col.id] = topRowData[col.id]
                })
                
                setInvoiceVals(newData)
                setMode("PUT")
            }else{
                getInvoiceData()
            }
        }
    }, [orderDetails, po_number, vendorDetails, invoiceData, topRowData]);

    //function for setting the top column options
    function switchTopOptions(data){
        //loop through the response data and collect all of the invoice_numbers in an array
        let duplicates= [], newOptions =[]
        data.map(item =>{
            if(!duplicates.includes(item.invoice_number)){
                newOptions.push({value:item.invoice_number, header: item.invoice_number})
            }
            duplicates.push(item.invoice_number)
        })
        let newColumns = topColumns
        newColumns[1]["options"] = newOptions
        setTopColumns(newColumns)
    }

    //sets the vendorBills data with invoice data that is already inputted invoice data
    function MergeInvoiceData(){
        
            //add qty ordered and received from the order details
            let newData = invoiceData
            invoiceData.map(invoice => {
                orderDetails.map(detail => {
                    if( invoice.sku === detail.base_sku){
                        invoice['qty_ordered'] = detail.qty_ordered
                        invoice['qty_received'] = detail.qty_received
                    }
                })
            })
            setVendorBills([...newData])
        
    }

    //function for getting/setting all of the invoice data 
    //merges the inbound table with the vendor invoices table 
    function getInvoiceData(){
        let total_amount = 0
        let newVals = invoiceVals
        setVendorBills(null)
        //gets Bills data for PO
        axios.get(base_url + 'financials-vendor-invoice-items/?po=' +   po_number).then(({data})=> {
            switchTopOptions(data)
            
            let newData = data
            //add the skus to the invoices if there is none
            if(data.length === 0 && orderDetails.length > 0){
                newData = addInvoice()
                //switch the invoice input type from a select to a text to allow user to manually enter
                let newColumns = topColumns
                newColumns[1]["type"] = 'select'
                setTopColumns(newColumns)
            }
            else{
                
                //set the common invoice vals
                let first = data[0] // get the first element
                for (const key in newVals) {
                    newVals[key] = first[key]
                }
                total_amount += newVals.total_amount
                //keep invoice number as null
                newVals.invoice_number=""

                //loop through the data to check if all the skus from the inbound table are in the vendor invoices
                //add the ones that aren't
                //also add up the amount invoiced of all the duplicate skus
                let processedSKUs = {}
                orderDetails.map(detail => {
                    let found = false
                    newData.map((invoice,index) => {
                         if(detail.base_sku === invoice.sku){ //check if the base sku matches a sku in the vendor invoice table
                            found =true
                            //add the display only fields to the object
                            invoice['qty_ordered'] = detail.qty_ordered
                            invoice['qty_received'] = detail.qty_received

                            //check to see if this invoice is a duplicate
                            if(typeof processedSKUs[invoice.sku] !== 'undefined'){
                                
                                //add up the invoiced qty of the skus and delete the old one
                                let oldSKU = newData[processedSKUs[invoice.sku]]
                                invoice['qty'] = invoice.qty + oldSKU.qty
                                invoice['qty_received'] = detail.qty_received + oldSKU.qty_received
                                delete newData[processedSKUs[invoice.sku]]
                            }
                            //store the index that the sku is at
                            processedSKUs[invoice.sku] = index
                            
                        }
                    })

                    //if the base_sku from orderDetails isnt in the invoice then add it
                    if(!found){
                        newData.push({
                            "vendor_name": detail.vendor_name,
                            "po_number": detail.po_number,
                            "order_date": detail.order_date,
                            "due_date": null,
                            "payment_terms": vendorDetails.payment_terms,
                            "invoice_paid": null,
                            "invoice_date": null,
                            "invoice_number": "",
                            "sku": detail.base_sku,
                            'qty_received': detail.qty_received,
                            "qty": 0,
                            "qty_ordered": detail.qty_ordered,
                            "unit_line_price": detail.unit_price,
                            "total_line_price": 0,
                            "total_amount": 0,
                            "payment_status": null,
                            "invoice_link": null,
                        })
                        total_amount += (detail.unit_price * detail.qty_ordered)
                    }
                })
            }
            //set the top row values
            setMode('ALL')
            newVals.invoice_date = moment().format('YYYY-MM-DD')
            newVals.total_amount = parseFloat(total_amount.toFixed(2))
            newVals.payment_terms = vendorDetails.payment_terms
            setInvoiceVals(newVals)
            setVendorBills(newData)
            setAllData(newData)
        }).catch(function (error){
            console.log(error)
            setVendorBills([])
        })
    }

    //function to close the modal and reset the data
    function closeModal(){
        setVendorBills(null)
        setInvoiceVals({
            invoice_date: null,
            invoice_number: "",
            payment_terms: "",
            invoice_paid: null,
            due_date: null,
            total_amount: 0
        })

        //switch the invoice input type to a select
        let newColumns = topColumns
        newColumns[1]["type"] = 'select'
        setTopColumns(newColumns)


        handleCancel()
    }

    //event handler for when the "Add Invoice" button is clicked
    //adds blank data to the table and switches the mode to POST
    function addInvoice(){
        let newData=[]
        //loop through the inbound data and convert to invoice rows
        orderDetails.map(item => {
            newData.push({
                "vendor_name": item.vendor_name,
                "po_number": item.po_number,
                "order_date": item.order_date,
                "due_date": null,
                "payment_terms": vendorDetails.payment_terms,
                "invoice_paid": null,
                "invoice_date": null,
                "invoice_number": null,
                "sku": item.base_sku,
                'qty_received': item.qty_received,
                "qty": 0,
                'invoiced_qty' : 0,
                "qty_ordered": item.qty_ordered,
                "unit_line_price": item.unit_price,
                "total_line_price": 0,
                "total_amount": 0,
                "payment_status": null,
                "invoice_link": null,
            })
        })

        setVendorBills(newData)

        //switch the invoice input type from a select to a text to allow user to manually enter
        let newColumns = topColumns
        newColumns[1]["type"] = 'text'
        setTopColumns(newColumns)

        //switch the mode to POST
        setMode('POST')

        return newData
    }

    //event handler for when an invoice is selected
    function handleInvoiceSelect(invoice_number){
        //check to see if the invoice has already been selected before
        //if so, get the stored data associated with the invoice
        if(typeof selectedInvoiceData[invoice_number] !== 'undefined'){
            setMode('PUT')
            setVendorBills(selectedInvoiceData[invoice_number])
            
            let newTopRow = selectedInvoiceData[invoice_number][0]
            //set toprow data
            let top = {...invoiceVals}
            for (const key in top) {
                top[key] = newTopRow[key]
            }
            setInvoiceVals(top)
        }else{
            setVendorBills(null)
            axios.get(base_url + 'financials-vendor-invoice-items/?invoice_num=' + invoice_number).then(({data}) => {
                setMode('PUT')
                let newData = data, newTopRow=data[0]
                //loop through the data and get the qty received and qty ordered
                newData.map(invoice => {                   
                    orderDetails.map(inbound => {
                        if(invoice.sku === inbound.base_sku){
                            //add qty_received and qty_ordered
                            invoice['qty_received'] = inbound.qty_received
                            invoice['qty_ordered'] = inbound.qty_ordered
                        }
                    })
                })
                setVendorBills(newData)
                setSelecetedInvoiceData({...selectedInvoiceData, [invoice_number]: newData})
    
                //set toprow data
                let top = {...invoiceVals}
                for (const key in top) {
                    top[key] = newTopRow[key]
                }
                setInvoiceVals(top)
    
            }).catch(function(error){
                console.log(error)
                setVendorBills(allData)
            })
        }
    }

    function handleRefresh(){
        
        if(view==='invoices'){
            setVendorBills([...invoiceData])
        }else{
            getInvoiceData()
        }
    }


    return (  
        <BasicModal
                title={`${vendor_name? `${vendor_name}, ${po_number}(${moment(order_date).format('MMM Do, YYYY')})` : 'Loading '} Invoices`}
                size='xl'
                body={
                    <>
                        <div style={{height: '60vh'}}>
                            <InvoicesTable
                                mode={mode}
                                vendor_name={vendor_name}
                                po_number={po_number}
                                invoiceVals={invoiceVals}
                                vendorBills={vendorBills}
                                paymentTerms={paymentTerms}
                                disabled={true}
                                addInvoice={addInvoice}
                                handleRefresh={handleRefresh}
                                handleInvoiceSelect={handleInvoiceSelect}
                                handleSubmit={handleSubmit}
                                handleErrors={handleErrors}
                                url = {base_url + 'financials-vendor-invoice-items/'}
                                topColumns={topColumns}
                                columns={columns}
                            />
                        </div>
                    </>
                }
                show={show}
                onHide={()=>{}}
                handleCancel={closeModal}
                noConfirm
            />
    );
}

export default InvoiceModal;