/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect, useState} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import axios from "axios";
import { useHtmlClassService } from "../../../_metronic/layout";
import { CSVDownloader } from 'react-papaparse';

export function CatalogActionsTile({ className }) {
    const base_url = process.env.REACT_APP_API_URL

  const uiService = useHtmlClassService();
  const [duplicateBaseSKUs, setDuplicateBaseSKUs] = useState(null)
  const [missingBaseSKUs, setMissingBaseSKUs] = useState(null)
  const [missingPCOGs, setMissingPCOGs] = useState(null)

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);



  //effect for getting the data for each tile
  useEffect(() => {
      axios.get(base_url + 'catalog-base-sku/?type=dupe').then(({data}) => {
        setDuplicateBaseSKUs(data)
      }).catch(function(error){
        setDuplicateBaseSKUs([])
        console.log(error)
      })

      axios.get(base_url + 'catalog-base-sku/?type=nobase').then(({data}) => {
        setMissingBaseSKUs(data)
      }).catch(function(error){
        setMissingBaseSKUs([])
        console.log(error)
      })

      axios.get(base_url + 'catalog-base-sku/?type=pcogs').then(({data}) => {
        setMissingPCOGs(data)
      }).catch(function(error){
        setMissingPCOGs([])
        console.log(error)
      })
  }, []);

  return (
    <div className={`card card-custom ${className}`}>
      {/* Header */}
      <div className="card-header border-0 bg-white py-5">
        <h3 className="card-title font-weight-bolder ">Catalog Actions</h3>
      </div>
      {/* Body */}
      <div className="card-body position-relative overflow-hidden">

        <div class="mt-5">					
            <div class="d-flex align-items-center justify-content-between mb-5">
                <div class="d-flex align-items-center mr-2">
                    <div class="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                        <div class="symbol-label">
                            <i class="flaticon2-layers-2 icon-lg text-primary"></i>
                        </div>
                    </div>
                    <div>
                        <CSVDownloader
                            data={duplicateBaseSKUs}
                            filename={'Duplicate Base SKUs'}
                            bom={true}
                        >
                            <a href="#" class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Duplicate Base SKUs</a>
                        </CSVDownloader>  
                    </div>
                </div>
                <div class="label label-light label-inline font-weight-bold text-dark-50 py-4 px-3 font-size-base">{duplicateBaseSKUs? duplicateBaseSKUs.length: 'Loading...'}</div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-5">
                <div class="d-flex align-items-center mr-2">
                    <div class="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                        <div class="symbol-label">
                            <i class="flaticon2-warning icon-lg text-warning"></i>
                        </div>
                    </div>
                    <div>
                        <CSVDownloader
                                data={missingBaseSKUs}
                                filename={'Missing Base SKUs'}
                                bom={true}
                            >
                                <a href="#" class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Missing Base SKUs</a>
                        </CSVDownloader>  
                    </div>

                </div>

                <div class="label label-light label-inline font-weight-bold text-dark-50 py-4 px-3 font-size-base">{missingBaseSKUs? missingBaseSKUs.length: 'Loading...'}</div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center mr-2">
                    <div class="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                        <div class="symbol-label">
                            <i class="flaticon2-warning icon-lg text-warning"></i>
                        </div>
                    </div>
                    
                    <div>
                        <CSVDownloader
                                data={missingPCOGs}
                                filename={'Missing PCOGS'}
                                bom={true}
                            >
                            <a href="#" class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Missing PCOGs</a>
                        </CSVDownloader> 
                    </div>
                </div>
                <div class="label label-light label-inline font-weight-bold text-dark-50 py-4 px-3 font-size-base">{missingPCOGs? missingPCOGs.length: 'Loading...'}</div>
            </div>
        </div>

        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}

function getChartOptions(layoutProps) {
  const strokeColor = "#D13647";

  const options = {
    series: [
      {
        name: "Net Profit",
        data: [30, 45, 32, 70, 40, 40, 40]
      }
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 0
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [strokeColor]
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3
        }
      }
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function(val) {
          return "$" + val + " thousands";
        }
      },
      marker: {
        show: false
      }
    },
    colors: ["transparent"],
    markers: {
      colors: layoutProps.colorsThemeBaseDanger,
      strokeColor: [strokeColor],
      strokeWidth: 3
    }
  };
  return options;
}
