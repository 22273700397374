import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';

export default function VCShipments() {
    const url = `${process.env.REACT_APP_API_URL}sales-vc-shipments/`
    const fields = {
        'po_number': {
            required: true,
            id: 'po_number',
            label: 'PO Number',
            header: 'PO Number',
            type: 'modal',
            ip: { maxLength: 255 }
        },
        'arn_number': {
            required: true,
            id: 'arn_number',
            label: 'ARN Number',
            header: 'ARN Number',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'arn_creation_date': {
            required: true,
            id: 'arn_creation_date',
            label: 'ARN Date',
            header: 'ARN Date',
            type: 'date',
            format: 'MM/DD/YYYY',
            ip: { maxLength: 255 }
        },
        'shipment_mode': {
            id: 'shipment_mode',
            label: 'Shipment Mode',
            header: 'Shipment Mode',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'total_cartons': {
            id: 'total_cartons',
            label: 'Total Cartons',
            header: 'Total Cartons',
            type: "numeric",
            decimals: 0,
            ip: { maxLength: 5 }
        },
        'carrier_name': {
            id: 'carrier_name',
            label: 'Carrier Name',
            header: 'Carrier Name',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'edit': {
            header: 'Action',
            type: 'edit',
        },
    }

    return (
        <PageComponent
            tableTitle="VC Shipments"
            url={url}
            deleteItems
            addItems
            checkbox
            import
            resetSave
            bulkAdd
            bulkEdit
            bulkEdits
            requiredCSVColumns={[
                'arn_number','po_number'
            ]}
            formInputs={[fields['po_number'], fields.arn_creation_date, fields['arn_number'], fields.shipment_mode,
                    fields.carrier_name, fields.total_cartons ]}

            tableColumns={[fields['arn_number'], fields.arn_creation_date, fields['po_number'], fields.shipment_mode,
                    fields.carrier_name,fields.total_cartons, fields.edit]}
        />
    )
}