/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { useLocation } from "react-router";



export function AuthPage() {
  const location = useLocation();

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white bgi-size-cover bgi-no-repeat"
          id="kt_login"
          style={{
            backgroundImage: `url(	https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/bg/bg-3.jpg)`,
          }}
        >
          

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/*begin::Content header*/ }
            {location.pathname === '/auth/registration'? null: 
            <div className="d-flex flex-wrap justify-content-end">
            </div>}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
              <div className="opacity-70 font-weight-bold text-dark">
              &copy; 2021 <span style={{color: '#ffa800'}}>Etailist, LLC  </span>All Rights Reserved  
              </div>
              <div className="d-flex">
                <a href="https://www.etailist.com/about.html" target="_blank" rel="noopener noreferrer" className="text-dark ml-10">
                  About
                </a>
                <a href="https://etailist.com/contact.html" target="_blank" rel="noopener noreferrer" className="text-dark ml-10">
                  Contact
                </a>
              </div>
            </div>

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2021 <span style={{color: '#ffa800'}}>Etailist, LLC  </span>All Rights Reserved  
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <a href="https://www.etailist.com/about.html" target="_blank" rel="noopener noreferrer" className="text-dark ml-10">
                  About
                </a>
                <a
                  href="https://etailist.com/contact.html" target="_blank" rel="noopener noreferrer"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </a>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
