import React, { useState, useEffect } from 'react';
import PageComponent from '../../components/PageComponent';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function VendorInvoiceItems(props) {
    const history = useHistory()
    const url = `${process.env.REACT_APP_API_URL}financials-vendor-invoice-items/`
    const [vendors, setVendors] = useState(null)

    //effect for getting the list of vendor names from the vendor details table
      //cancels ongoing request when componenet is unmounted
      useEffect(() => {
        const source = axios.CancelToken.source();
    
        axios.get(process.env.REACT_APP_API_URL + 'catalog-vendor-details/?list=vendor_name', {cancelToken: source.token}).then(({data}) => {
          setVendors(data)
        }).catch(function(error){
          if(axios.isCancel(error)){
            console.log('REQUEST CANCELLED')
          }else{
            setVendors([])
            console.log(error)
          }
        })
    
        return () => {
          source.cancel()
        }
      }, []);

    const fields = {
        'po_number': {
            required: true,
            id: 'po_number',
            label: 'PO Number',
            header: 'PO Number',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'order_date': {
            required: true,
            id: 'order_date',
            label: 'Order Date',
            header: 'Order Date',
            type: 'date',
            format: 'MM/DD/YYYY',
            ip: { maxLength: 255 }
        },
        'due_date': {
            id: 'due_date',
            label: 'Due Date',
            header: 'Due Date',
            type: 'date',
            format: 'MM/DD/YYYY',
            ip: { maxLength: 255 }
        },
        'payment_terms': {
            id: 'payment_terms',
            label: 'Payment Terms',
            header: 'Payment Terms',
            type: 'text',
            ip: { maxLength: 255 },
            noEdit: true,
        },
        'invoice_paid': {
            id: 'invoice_paid',
            label: 'Invoice Paid',
            header: 'Invoice Paid',
            type: 'bool',
            noEdit: true,
        },
        'invoice_date': {
            required: true,
            id: 'invoice_date',
            label: 'Invoice Date',
            header: 'Invoice Date',
            type: 'date',
            format: 'MM/DD/YYYY',
            ip: { maxLength: 255 }
        },
        'invoice_number': {
            required: true,
            id: 'invoice_number',
            label: 'Invoice Number',
            header: 'Invoice Number',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'sku': {
            id: 'sku',
            label: 'SKU',
            header: 'SKU',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'qty': {
            id: 'qty',
            label: 'QTY',
            header: 'QTY',
            type: 'numeric',
            decimals: 0,
            ip: { maxLength: 255 }
        },
        'unit_line_price': {
            id: 'unit_line_price',
            label: 'Unit Price',
            header: 'Unit Price',
            type: 'numeric',
            decimals: 2,
            ip: { maxLength: 255 }
        },
        'total_line_price': {
            id: 'total_line_price',
            label: 'Total Price',
            header: 'Total Price',
            type: 'numeric',
            decimals: 2,
            ip: { maxLength: 255 }
        },
        'total_amount': {
            id: 'total_amount',
            label: 'Invoice Amount',
            header: 'Invoice Amount',
            type: 'numeric',
            decimals: 2,
            ip: { maxLength: 255 }
        },
        'payment_status': {
            id: 'payment_status',
            label: 'Payment Status',
            header: 'Payment Status',
            type: 'text',
            ip: { maxLength: 255 }
        },
        'invoice_link': {
            id: 'invoice_link',
            label: 'Invoice Link',
            header: 'Invoice Link',
            type: 'text',
            ip: { maxLength: 900 }
        },
        'edit': {
            header: 'Action',
            type: 'edit',
        },
    }

      //method called when user clicks on a PO number
    //redirects user to the purchase order page and passes the input PO as a state parameter
    function sendToPOPage(po){
        history.push({pathname: '/instock/purchase-orders/', state: po})
    }


    return (
        <PageComponent
            tableTitle="Invoice Items"
            url={url}
            deleteItems
            addItems
            checkbox
            import
            bulkAdd
            bulkEdit
            requiredEditFields={[
                'vendor_name'
            ]}
            requiredCSVColumns={[
                'po_number','vendor_name', 'invoice_date', 'invoice_number', 'total_amount'
            ]}
            formInputs={[                {
                required: true,
                id: 'vendor_name',
                label: 'Vendor',
                ip: {maxLength: 100},
                type: 'select',
                options: vendors && vendors
            },,fields['po_number'], fields.order_date, fields.due_date, fields.payment_terms, fields.invoice_paid,
                    fields.invoice_date, fields.invoice_number, fields.sku, fields.qty, fields.unit_line_price, fields.total_line_price, fields.total_amount, fields.invoice_link]}

            tableColumns={  [     
                {
                    id: 'vendor_name',
                    header: 'Vendor Name',
                    type: 'text',
                }

                ,{id: 'po_number',header: 'PO Number', type: 'function', function: sendToPOPage}, fields.order_date, fields.due_date, fields.payment_terms, fields.invoice_paid,
            fields.invoice_date, 
            { 
                id: 'invoice_number',
                header: 'Invoice Number',
                type: 'function',
                function: props.handleInvoice
            },
            fields.sku, fields.qty, fields.unit_line_price, fields.total_line_price, fields.total_amount, fields.invoice_link, fields.edit]}
        />
    )
}