import React, { useState, useEffect } from 'react';
import KeywordSelect from '../Buttons/KeywordSelect';
import ColumnChart from '../Charts/ColumnChart';
import moment from 'moment';

export default function BrandSalesOverTime(props){
    const [value, setValue] = useState(null)
    const [categories, setCategories] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [last12Weeks, setLast12Weeks] = useState(null)

    //***********************EFFECTS****************** */
    //effect for getting the categories
    //the categories are just the last 12 weeks, only need to calculate once
    useEffect(() => {
        let newCategories = [], newWeeks = {}
        for(let i =0; i < 12; i++){
            newCategories.unshift(moment().subtract(i, 'weeks').format('W/YYYY')) // store last 12 weeks to display on chart axes
            newWeeks[moment().subtract(i, 'week').format('WYYYY')] = 11- i
        }
        setLast12Weeks(newWeeks)
        setCategories(newCategories)
    }, []);

    //effect for formatting the input data to display on the chart correctly
    useEffect(() => {
        if(props.data && last12Weeks){
            let newData = [0,0,0,0,0,0,0,0,0,0,0,0] // create an empty array of length 12 (last 12 weeks)
            props.data.map(brand => {
                let dateStr =brand.purchase_week.toString() +brand.purchase_year.toString() // convert to match 'WYYYY' format
                if( typeof last12Weeks[dateStr]!== 'undefined'){
                    newData.slice(last12Weeks[dateStr], 0, newData[last12Weeks[dateStr]]+= brand.sold_amount) // add it to the correct index in the array(index = week)
                }
            })

            setChartData(newData)
        }
    }, [props.data]);

    useEffect(() => {
        
    }, [chartData]);

    //***********************METHODS****************** */
    
    //function that sets stores the dropdown value when an option is selected
    function handleDropDown(value, index){
        setValue(value);
        props.handleKey(value)
    }

    if(!categories || !chartData){
        return(
            <div>Loading</div>
        )
    }

    return(
        <div className="card card-stretch card-custom">
            <div className="px-5 border-0 mt-4">
                <div className=" d-flex justify-content-between w-100">
                    <span style={{fontSize: 14}}>
                        Brand Sales Last 12 Weeks
                    </span>
                    <span className="text-muted  font-weight-bold font-size-sm">
                        <KeywordSelect
                            id={0} 
                            placeholder={'Select Brand'} 
                            keywords={props.keywords} 
                            value={value && value}
                            handleDropDown={handleDropDown}
                        />
                    </span>
                </div>
            </div>
            <div className='card-body p-3'>
                <ColumnChart
                    data={chartData} 
                    name={""} 
                    categories={categories}
                    xtitle="Week of the Year" 
                    ytitle="Sold Amount"
                />
            </div>
        </div>
    );
}