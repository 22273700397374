import React, { useState, useEffect } from "react";
import PageComponent2 from "../../components/PageComponent2";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Download from "@material-ui/icons/GetApp";
import { CSVDownloader } from "react-papaparse";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    position: "absolute",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#EFEFEF",
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export default function VCOrders() {
  const url = `${process.env.REACT_APP_API_URL}sales-vc-po/`;
  const classes = useStyles();
  const [allData, setAllData] = useState(null);
  const source = axios.CancelToken.source();

  //load all of the data for the floating button
  useEffect(() => {
    axios
      .get(url + "?type=all", { cancelToken: source.token })
      .then(({ data }) => {
        axios
          .get(url + `?limit=${data.count}&type=all`, {
            cancelToken: source.token,
          })
          .then(({ data }) => {
            setAllData(data.results);
          })
          .catch(function(error) {
            if (axios.isCancel(error)) {
              console.log("REQUEST CANCELLED");
            } else {
              console.log(error);
              setAllData([]);
            }
          });
      })
      .catch(function(error) {
        if (axios.isCancel(error)) {
          console.log("REQUEST CANCELLED");
        } else {
          console.log(error);
          setAllData([]);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <>
      <PageComponent2
        tableTitle={
          <div className="d-flex flex-row align-items-center">
            VC Orders{" "}
            {allData === null ? (
              <CircularProgress className="ml-2" size={20} color="info" />
            ) : (
              <CSVDownloader
                data={allData}
                filename={"SalesVCPOAllData"}
                bom={true}
              >
                <Tooltip title="Download All Data" className="ml-1">
                  <IconButton aria-label="Download CSV">
                    <Download />
                  </IconButton>
                </Tooltip>
              </CSVDownloader>
            )}
          </div>
        }
        url={url}
        bulkAdd
        bulkEdit
        import
        deleteItems
        addItems
        checkbox
        resetSave
        bulkEdits={[
          {
            type: "bool",
            label: "Select",
            show: [1, 2],
            options: [
              { value: "flag_routed", label: "Routed" },
              { value: "flag_packed", label: "Packed" },
              { value: "flag_shipped", label: "Shipped" },
              { value: "flag_asn", label: "ASN" },
              { value: "flag_cancelled", label: "Cancelled" },
            ],
          },
          {
            type: "date",
            id: "date_shipped",
            label: "Date Shipped",
            show: "flag_shipped",
            hidden: true,
          },
          {
            type: "checkbox",
            id: "match",
            matchIDs: ["shipped_qty", "accepted_qty"],
            label: "Ship All",
            show: "flag_shipped",
            hidden: true,
          },
          {
            type: "select",
            id: "shipment_active",
            label: "Active",
            options: [
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ],
          },
        ]}
        requiredCSVColumns={[
          "accepted_qty",
          "po_number",
          "asin",
          "vendor_account",
          "window_start",
          "window_end",
          "unit_price",
          "total_price",
          "fc",
          "external_id",
        ]}
        formInputs={[
          {
            required: true,
            id: "fc",
            label: "FC",
            ip: { maxLength: 100 },
          },
          {
            required: true,
            id: "vendor_account",
            label: "Vendor Account",
            ip: { maxLength: 100 },
          },
          {
            required: true,
            id: "accepted_qty",
            label: "Accepted Qty",
            ip: { maxLength: 50 },
          },
          {
            required: true,
            id: "po_number",
            label: "PO",
            ip: { minLength: 8 },
          },
          {
            required: true,
            ip: { maxLength: 15 },
            id: "asin",
            label: "ASIN",
          },
          {
            id: "external_id",
            label: "External ID",
            ip: { maxLength: 100 },
          },
          {
            id: "vc_title",
            label: "Title",
            ip: { maxLength: 100 },
          },
          {
            ip: { maxLength: 100 },
            id: "order_date",
            label: "Order Date",
            type: "date",
          },
          {
            required: true,
            ip: { maxLength: 100 },
            id: "window_start",
            label: "Window Start",
            type: "date",
          },
          {
            required: true,
            ip: { maxLength: 100 },
            id: "window_end",
            label: "Window End",
            type: "date",
          },
          {
            required: true,
            id: "unit_price",
            label: "Unit Price",
            ip: { maxLength: 50 },
          },
          {
            required: true,
            id: "total_price",
            label: "Total Price",
            ip: { maxLength: 50 },
          },
        ]}
        tableColumns={[
          {
            id: "po_state",
            header: "PO State",
            type: "text",
          },
          {
            id: "po_number",
            header: "PO",
            type: "modal",
          },
          {
            anchor: "https://www.amazon.com/dp/",
            tag: "asin",
            id: "asin",
            header: "ASIN",
            type: "anchor",
          },
          {
            id: "accepted_qty",
            header: "Accepted Qty",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "shipped_qty",
            header: "Shipped Qty",
            type: "numeric",
            decimals: 0,
          },
          {
            id: "vc_sku",
            header: "SKU",
            type: "text",
          },
          {
            id: "flag_routed",
            header: "Routed",
            type: "bool-icon",
          },
          {
            id: "flag_packed",
            header: "Packed",
            type: "bool-icon",
          },
          {
            id: "flag_shipped",
            header: "Shipped",
            type: "bool-icon",
          },
          {
            id: "flag_asn",
            header: "ASN",
            type: "bool-icon",
          },
          {
            id: "flag_invoiced",
            header: "Invoiced",
            type: "bool-icon",
            noEdit: true,
          },
          {
            id: "flag_cancelled",
            header: "Cancelled",
            type: "bool-icon",
          },
          {
            type: "date",
            format: "MM/DD/YYYY",
            id: "date_shipped",
            header: "Date Shipped",
          },

          // {
          //   id: "packing_remarks",
          //   header: "Packing Remarks",
          //   type: "text",
          // },
          // {
          //   id: "shipment_mode",
          //   header: "Shipment Mode",
          //   type: "text",
          // },
          // {
          //   id: "shipment_identifier",
          //   header: "Shipment Identifier",
          //   type: "text",
          // },
          // {
          //   id: "shipment_active",
          //   header: "Active Shipment",
          //   type: "bool",
          // },
          {
            id: "vc_prep",
            header: "VC Prep",
            type: "text",
            noEdit: true,
            noFilter: true,
          },
          {
            id: "external_id",
            header: "External ID",
            type: "text",
            noEdit: true,
            noFilter: true,
          },
          {
            id: "vc_title",
            header: "Title",
            type: "text",
            noEdit: true,
            noFilter: true,
          },
          {
            type: "date",
            format: "MM/DD/YYYY",
            id: "order_date",
            header: "Order Date",
          },
          {
            type: "date",
            format: "MM/DD/YYYY",
            id: "window_start",
            header: "Window Start",
            noEdit: true,
          },
          {
            type: "date",
            format: "MM/DD/YYYY",
            id: "window_end",
            header: "Window End",
            noEdit: true,
          },
          {
            header: "Action",
            type: "vcedit",
          },
        ]}
      />
    </>
  );
}
