import React, {useEffect}from 'react';
import { Modal } from 'react-bootstrap';
import { Grid } from '@material-ui/core';




export default function BasicModal(props) {
    useEffect(() => {
    }, [props.show]);
    return(
    <Modal
        show={props.show} 
        onHide={props.onHide && props.onHide}
        size={props.size? props.size: "md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

            {props.body}
          
        </Modal.Body>
        <Modal.Footer>
            <Grid container spacing={3} alignItems='center' alignContent='center' justify='center'>
                <Grid item xs={6} className="text-center text-hover-primary">
                    <a id="close" onClick={props.handleCancel}>
                        <span>{props.cancelTitle? props.cancelTitle : 'Cancel'}</span>
                    </a>
                </Grid>
                {!props.noConfirm && <Grid item xs={6} className="text-center text-hover-primary" >
                    <a id="renew" onClick={props.handleConfirm}>
                        <span >{props.confirmTitle? props.confirmTitle : 'Confirm'}</span>
                    </a>
                </Grid>}        
            </Grid>
        </Modal.Footer>
    </Modal>);
}